import MenuItem from './MenuItem';
import InsightsIcon from '@mui/icons-material/Insights';
import { ReactComponent as PlaylistIcon } from '../../icons/playlist.svg';

export default function RightsholderViewOfPublisherMenu({ open }) {
  return (
    <>
      <MenuItem open={open} path="/dashboard/playlist" label="Playlist">
        <PlaylistIcon />
      </MenuItem>
      <MenuItem open={open} path="/dashboard/gap-analysis" label="Gap Analysis">
        <InsightsIcon />
      </MenuItem>
    </>
  );
}

