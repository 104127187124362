import * as React from 'react';
import { useContext, useEffect, useMemo } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import { Heading } from '../common/Heading';
import { styled } from '@mui/material/styles';
import { CalculatorContext } from '../../context/calculator/CalculatorProvider';
import { formatNumberWithComma, numberWithCommas, numberWithoutComma, updateState } from './utils';
import { getApiBaseUrl } from '../../utils/env';
import { CalculatorState } from '../../context/calculator/reducer';
import ToolBarRoot from '../toolbar/ToolBarRoot';

const Root = styled('div')`
  position: relative;

  .linear-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }

  .linear-top {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
  }
`;

const LostRevenueLabel = styled('p')`
  margin: 0
`

const LostRevenue = styled('h1')`
  font-family: ProximaNovaBold;
  font-size: 4em;
  margin: 0;
`;

const Label = styled('strong')`
  font-family: ProximaNovaBold;
`;

const Results = styled('div')`
  display: flex;
  gap: 1em;
`;

function CalcTextField({ label, value, setValue }) {
  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <TextField
          fullWidth
          type={'text'}
          autoComplete="off"
          value={value}
          label={label}
          variant="outlined"
          onChange={({ target: { value } }) => setValue(value)}
        />
      </FormControl>
    </Grid>
  );
}

const mapCalculatorKeys = {
  dailyPageviews: 'dailyPageviews',
  percentagePagesMissingVideo: 'potentialVideoMatches',
  matchPercentage: 'matchPercentage',
  fillPercentage: 'fillRatePercentage',
  isAutoplay: 'isCtp',
  cpmAutoplay: 'autoCpm',
  cpmCtp: 'ctpCpm',
  isProgrammatic: 'isProgrammatic',
  includeHeaderbidding: 'includeHeaderbidding'
};

const syncCalculatorToRemote = (selectedPublisher, calc: CalculatorState) => {
  const data = Object.entries(mapCalculatorKeys).map(([k, v]) => {
    switch (k) {
      case 'dailyPageviews':
        return [k, numberWithoutComma(calc[v])];
      case 'includeHeaderbidding':
      case 'isAutoplay':
      case 'isProgrammatic':
        return [k, String(calc[v])];
      default:
        return [k, calc[v]];
    }
  });
  axios.put(
    `${getApiBaseUrl()}/demo/${selectedPublisher}/revenue-loss-calculator`,
    Object.fromEntries(data)
  ).catch((error) => {
    alert('Sorry, we are unable to process this request. Please check the console log for more' +
      ' info')
    console.error(error)
  });
};

const UserCalculator = ({
  formatCurrency,
  selectedPublisher,
  publishers,
  setPublisher
}) => {
  const {
    calc, dispatch
  } = useContext(CalculatorContext);
  const {
    dailyPageviews,
    lostRevenue,
    imageToVideoRatioPercentage,
    matchPercentage,
    fillRatePercentage,
    d2p,
    d2e,
    ctpCpm,
    autoCpm,
    upliftProg,
    upliftDirect,
    currency,
    isCtp,
    includeHeaderbidding,
    potentialVideoMatches,
    adReqs,
    upliftedCpm,
    isProgrammatic
  } = calc;
  const update = updateState(dispatch);

  useEffect(() => {
    if (!selectedPublisher) return;
    axios.get(
      `${getApiBaseUrl()}/demo/${selectedPublisher}/revenue-loss-calculator`,
    ).then(({ data }) => {
      Object.entries(data).forEach(([k, v]) => {
        switch (k) {
          case 'dailyPageviews':
            return update(k, numberWithCommas(v));
          case 'includeHeaderbidding':
          case 'isAutoplay':
          case 'isProgrammatic':
            return update(k, v === 'true');
          default:
            return update(k, v);
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPublisher]);

  const debouncedEventHandler = useMemo(
    () => debounce(syncCalculatorToRemote, 2000)
    , []);

  useEffect(() => {
    debouncedEventHandler(selectedPublisher, calc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calc]);

  return (<React.Fragment>
    <Root>
      <ToolBarRoot title={'Video Revenue Calculator'}></ToolBarRoot>
    </Root>
    <Root>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LostRevenueLabel>Monthly video revenue uplift:</LostRevenueLabel>
          <LostRevenue>{formatCurrency(lostRevenue * 30)}</LostRevenue>
          <p>Pixels cost (p/month): <Label>${Math.round(adReqs / 1000) * 30}</Label></p>
          <FormControl fullWidth>
            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group"
                        row>
              <FormControlLabel
                value="dollar"
                control={<Radio checked={currency === 'dollar'}
                                onChange={e => update('currency', 'dollar')}/>}
                label="$"
              />
              <FormControlLabel
                value="pound"
                control={<Radio checked={currency === 'pound'}
                                onChange={e => update('currency', 'pound')}/>}
                label="£"
              />
              <FormControlLabel
                value="euro"
                control={<Radio checked={currency === 'euro'}
                                onChange={e => update('currency', 'euro')}/>}
                label="€"
              />
            </RadioGroup>
          </FormControl>
          <Results>
            <p>
              Additional video embeds: <Label>{formatNumberWithComma(potentialVideoMatches)}</Label>
            </p>
            <p>
              Ad impressions: <Label>{formatNumberWithComma(Math.round(adReqs))}</Label>
            </p>
            <p>
              Uplifted CPM: <Label>{formatCurrency(upliftedCpm)}</Label>
            </p>
            <p>
              Lost revenue (p/day): <Label>{formatCurrency(lostRevenue)}</Label>
            </p>
          </Results>
        </Grid>
      </Grid>
    </Root>
    <Root>
      <Grid container style={{ paddingBottom: '15px', paddingLeft: '15px' }}>
        <Grid container alignItems="center" justifyContent={'space-between'}>
          <Grid item>
            <Heading>Calculator inputs</Heading>
          </Grid>
        </Grid>
      </Grid>
    </Root>
    <Root>
      <Grid container spacing={2}>
        <CalcTextField label="Daily pageviews" value={dailyPageviews}
                       setValue={(val) => update('dailyPageviews', formatNumberWithComma(val))}/>
        <CalcTextField
          label="Image to video ratio as percentage (%)"
          value={imageToVideoRatioPercentage}
          setValue={update('imageToVideoRatioPercentage')}
        />
        <CalcTextField label="Match percentage (%)" value={matchPercentage}
                       setValue={update('matchPercentage')}/>
        <CalcTextField
          label="Fill rate as percentage (%)"
          value={fillRatePercentage}
          setValue={update('fillRatePercentage')}
        />
        <CalcTextField label="$/£ exchange rate" value={d2p} setValue={update('d2p')}/>
        <CalcTextField label="$/€ exchange rate" value={d2e} setValue={update('d2e')}/>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group"
                        row>
              <FormControlLabel
                value="autoplay"
                control={<Radio checked={!isCtp} onChange={e => update('isCtp', false)}/>}
                label="Autoplay"
              />
              <FormControlLabel
                value="clicktoplay"
                control={<Radio checked={isCtp} onChange={e => update('isCtp', true)}/>}
                label="Click-to-play"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {isCtp && <CalcTextField label="CPM ($)" value={ctpCpm} setValue={update('ctpCpm')}/>}
        {!isCtp && <CalcTextField label="CPM ($)" value={autoCpm} setValue={update('autoCpm')}/>}

        <Grid item xs={12}>
          <FormControl fullWidth>
            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group"
                        row>
              <FormControlLabel
                value="programmatic"
                control={<Radio checked={isProgrammatic}
                                onChange={e => update('isProgrammatic', true)}/>}
                label="Programmatic"
              />
              <FormControlLabel
                value="directsales"
                control={<Radio checked={!isProgrammatic}
                                onChange={e => update('isProgrammatic', false)}/>}
                label="Direct sales"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {isProgrammatic && (
          <CalcTextField label="Pixels relevancy uplift" value={upliftProg}
                         setValue={update('upliftProg')}/>
        )}
        {!isProgrammatic && (
          <CalcTextField label="Pixels relevancy uplift" value={upliftDirect}
                         setValue={update('upliftDirect')}/>
        )}

        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox checked={includeHeaderbidding}
                          onChange={e => update('includeHeaderbidding', e.target.checked)}/>
              }
              label="Include header bidding"
            />
          </FormControl>
        </Grid>
      </Grid>
    </Root>
  </React.Fragment>);
};

UserCalculator.propTypes = {
  formatCurrency: PropTypes.func,
  selectedPublisher: PropTypes.string,
  publishers: PropTypes.array,
  setPublisher: PropTypes.func
};

export default UserCalculator;
