import TabContext from '@mui/lab/TabContext';
import { TabList } from '@mui/lab';
import { Tab } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router';

export const PlaylistVideoTabs = () => {
  const { playlist } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    <TabContext value={pathname}>
      <TabList
        onChange={(_, nextVal) => navigate(nextVal)}>
        <Tab value={`/dashboard/playlist/${playlist}`} label="Playlist"/>
        <Tab value={`/dashboard/playlist/popular-article/${playlist}`} label="Popular stories"/>
        <Tab value={`/dashboard/playlist/campaign-delivery/${playlist}`} label="Campaign delivery"/>
        <Tab value={`/dashboard/playlist/properties/${playlist}`} label="Properties"/>
        <Tab value={`/dashboard/playlist/preview/${playlist}`} label="Preview"/>
      </TabList>
    </TabContext>
  </Box>;
};