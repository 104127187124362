import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const CarouselRoot = styled(Box)`
  position: relative;
  align-items: center;
  background-color: #0e1f5d;
  display: flex;
`;

const CarouselWrapper = styled(Box)`
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-250px * 10));
    }
  }
  height: 100px;
  display: flex;
  align-items: center;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${props => props.theme.breakpoints.up('md')} {
    max-width: 1440px;
    height: 88px;
  }

  &::before,
  &::after {
    background: linear-gradient(to right, rgba(17, 38, 113, 1) 0%, rgba(17, 38, 113, 0) 100%);
    content: '';
    height: 100px;
    position: absolute;
    width: 80px;
    z-index: 2;

    ${props => props.theme.breakpoints.up('md')} {
      display: none;
    }
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }
`;

const CarouselTrack = styled(Box)`
  animation: scroll 42s linear infinite;
  display: flex;
  width: calc(250px * 10);
  ${props => props.theme.breakpoints.up('md')} {
    justify-content: space-between;
    margin: 0 20px;
    animation: none;
  }
`;

const CarouselItem = styled(Box)`
  height: 120px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.theme.breakpoints.up('md')} {
    width: auto;
    &.mobile-carousel {
      display: none;
    }
  }
`;

const BrandedCarousel2 = () => {
  const brands: [string, number][] = [
    ['independent', 195],
    ['evening-standard', 145],
    ['national-world', 105],
    ['guild', 75],
    ['UEFA', 57],
  ];
  return (
    <CarouselRoot>
      <CarouselWrapper>
        <CarouselTrack>
          {brands.map(([key, width]) => (
            <CarouselItem key={'r1' + key}>
              <img width={width} src={`/v2/client-logo/${key}.webp`} alt={key} />
            </CarouselItem>
          ))}
          {brands.map(([key, width]) => (
            <CarouselItem className={'mobile-carousel'} key={'r2' + key}>
              <img width={width} src={`/v2/client-logo/${key}.webp`} alt={key} />
            </CarouselItem>
          ))}
        </CarouselTrack>
      </CarouselWrapper>
    </CarouselRoot>
  );
};

export default BrandedCarousel2;
