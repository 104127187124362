import ToolBarPlaylist from '../ToolBarPlaylist';
import { PlaylistVideoTabs } from './PlaylistVideoTabs';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../index';
import { PlaylistState } from '../../store/playlistSlice';
import { useContext, useEffect } from 'react';
import { OrganisationContext } from '../../App';
import { useNavigate } from 'react-router-dom';

export default function PlaylistRoot({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedOrganisation } = useContext(OrganisationContext);
    const {
    currentPlaylist,
  } = useSelector<RootState, PlaylistState>((state) => state.playlist);

  useEffect(() => {
    if (currentPlaylist && (currentPlaylist.organisation !== selectedOrganisation)) {
      dispatch({
        type: 'RESET_PLAYLIST_VIDEO'
      })
      navigate('/dashboard/playlist');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisation, currentPlaylist, navigate]);

  return (<>
    <ToolBarPlaylist currentPlaylist={currentPlaylist}/>
    <PlaylistVideoTabs/>
    {children}
  </>)
}