import React from "react";

interface FaqsData {
    question: string;
    answer: React.ReactNode;
}

export const faqsData: FaqsData[] = [
  {
    question: 'What is an Impact Action?',
    answer: <>An Impact Action refers to a specific environmental or sustainability action. This is funded by the advertiser and designed to have a tangible positive effect on the environment. The exact nature of the impact action can vary and might include initiatives like removing plastic from a beach, planting trees, or supporting other environmentally friendly projects. The goal is to translate user engagement with advertising content into measurable, real-world benefits for the environment.</>
  },
  {
    question: 'What are the different Impact Actions that can be supported?',
    answer: <>We support a large range of Impact Actions across various categories: carbon offset programmes, humanitarian initiatives, social impact, biodiversity conservation, and many more.</>
  },
  {
    question: 'What does the Impact Certificate mean?',
    answer: <>The Impact Certificate is a content-enriched container of irrefutable data pertaining to project outcomes; acting as the source of truth, consumer touchpoint and reward gateway all-in-one. This connect advertisers, brands and consumers together, delivering engagement, accountability and impact.</>
  },
  {
    question: 'What is the process for advertisers/media buyers to choose an Impact Action?',
    answer: <>Advertisers or media buyers can choose from a diverse range of Impact Actions by completing the onboarding process with Everclime. This involves meeting Know Your Customer (KYC) requirements and signing an agreement to ensure the delivery of desired outcomes. We remain neutral on specific causes, allowing our partners the flexibility to align their advertising efforts with impactful initiatives.</>
  },
  {
    question: 'Who owns the ad creative?',
    answer: <>While the media buyer has overarching ownership, Pixels AI specifically owns the ad creative unit. This collaborative structure ensures a cohesive partnership in crafting and delivering impactful ad content.</>
  },
  {
    question: 'Who hosts the landing page?',
    answer: <>The hosting of the landing page is managed by Everclime, and it is co-branded with the advertiser for a cohesive and tailored presentation.</>
  },
];