import * as React from 'react';
import TabContext from '@mui/lab/TabContext';
import { TabList } from '@mui/lab';
import { Tab } from '@mui/material';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';


const DemoPublisherTabs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const paths = pathname.split('/');
  const slug = paths[paths.length - 1];

  return (<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    <TabContext value={slug}>
      <TabList
        onChange={(_, nextVal) => {
          navigate([...paths.slice(0, paths.length - 1), nextVal].join('/'));
        }}>
        <Tab value="articles" label="Articles"/>
        <Tab value="videos" label="Videos"/>
        <Tab value="fast-match-result" label="Fast Match Result"/>
      </TabList>
    </TabContext>
  </Box>);
}

export default DemoPublisherTabs;