import { useState } from 'react';
import { TableCellBody, TableRow } from './common/Table';
import { ReactComponent as ToggleIcon } from '../icons/chevron_toggle.svg';
import { VideoThumbnail } from './common/VideoThumbnail';
import { CircularProgress, Grid, SvgIcon } from '@mui/material';
import { BoldTypographyLarge, BoldTypographyMedium } from './common/TextLabel';
import moment from 'moment/moment';
import { ReactComponent as GainingIcon } from '../icons/gaining.svg';
import { ReactComponent as LostRankingIcon } from '../icons/lost-ranking.svg';
import { MatchType } from './common/MatchType';
import { ReactComponent as PencilIcon } from '../icons/pencil.svg';
import plusButton from '../icons/plus.png';
import { ReactComponent as PlayIcon } from '../icons/play.svg';
import ImageIcon from '@mui/icons-material/Image';
import * as React from 'react';
import { Capitalize, GreyTypography, TableRowPrimary, VideoRowRoot } from './SmartLibrary';
import { styled } from '@mui/material/styles';

const RowIconRoot = styled('span')`
  position: absolute;
  left: -28px;
  top: calc(50% + 1px);
  display: flex;
  z-index: 2;
`;

const ToggleRow = styled('span')`
  svg {
    transition:0.2s;
  }
  &.active svg {
    transform:rotate(90deg)
  }
  transform: translateY(-6px);
  cursor: pointer
`;


const TableCellBodyVTop = styled(TableCellBody)`
  vertical-align: top;
`;

const TableCellHeadWithLine = styled(TableCellBody)`
  &:not(.row-collapsed)&:after {
    content: ' ';
    position: absolute;
    width: 1px;
    height: calc(50% + 1px);
    background-color: #3355D1;
    top: 50%;
    left: 17px;
  }
`;

const TableCellBodyWithLine = styled(TableCellBody)`
  > svg {
    position: absolute;
    width: 20px;
    left: 8px;
    top: 50%;
    transform: translateY(-14px);
    z-index: 2;
    > svg {
      margin-top: -50%;
      display: inline-block
    }
  }
  &:after {
    content: ' ';
    position: absolute;
    width: 1px;
    height: 50%;
    background-color: #3355D1;
    top: 0;
    left: 17px;
  }
`;

const LinkArticle = styled('a')`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const LinkKeyword = styled('a')`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const CapitalizeThin = styled('span')`
  font-size: 16px;
  text-transform: capitalize;
`

export const Rows = ({
  setArticleVideoPreview,
  addVideo,
  loadingId,
  item,
  previewIndex,
  setPreviewIndex,
  index,
  showType
}) => {
  const [toggleRow, setToggleRow] = useState(false);
  return (
    <>
      <TableRowPrimary sx={{ height: '60px', position: 'relative' }}>
        <TableCellBody onClick={() => setToggleRow(!toggleRow)} padding={'none'}
                       sx={{ minWidth: '20px' }}>
        </TableCellBody>
        <TableCellHeadWithLine className={toggleRow ? 'row-expanded' : 'row-collapsed'}>
          <VideoRowRoot>
            <RowIconRoot>
              <ToggleRow
                className={toggleRow ? 'active' : ''}
                onClick={() => setToggleRow(!toggleRow)}>
                <ToggleIcon/></ToggleRow>
            </RowIconRoot>
            { item.has_video && <span
              onMouseEnter={() => setPreviewIndex(index)}
              onClick={() => {
                setArticleVideoPreview({ ...item.article, videos: [item] })
                setPreviewIndex(null);
              }}>
              <VideoThumbnail previewVideoUrl={item.video_preview} preview={previewIndex === index} width={141} imageUrl={item.video_thumbnail} videoCount={null}/>
            </span> }
            { !item.has_video && <VideoThumbnail width={141} imageUrl={'/bg-grey.png'} videoCount={null}/> }
          </VideoRowRoot>
        </TableCellHeadWithLine>
        <TableCellBodyVTop>
          { item.has_video && <Grid container flexDirection={'column'}>
            <Grid item>
              {item.video_title}
            </Grid>
            <Grid item>
              <BoldTypographyMedium>{
                moment(item.video_published_date).calendar({
                  sameDay: '[Today]  hh:mm',
                  lastDay: '[Yesterday]  hh:mm',
                  lastWeek: 'DD MMM  hh:mm',
                  sameElse: 'DD MMM hh:mm'
                })
              }</BoldTypographyMedium>
            </Grid>
            <Grid item>
              <GreyTypography component={'span'}>{item.video_category}</GreyTypography>
            </Grid>
          </Grid> }
          { !item.has_video && <BoldTypographyLarge><GreyTypography
        component={'span'}>No video</GreyTypography></BoldTypographyLarge> }
        </TableCellBodyVTop>
        <TableCellBodyVTop>
          <Grid container flexDirection={'column'}>
            <Grid item>
              <LinkKeyword href={`https://www.google.co.uk/search?q=${item.keyword}`} target={'_blank'}>
                <Capitalize>
                  <BoldTypographyLarge>{item.keyword}</BoldTypographyLarge>
                </Capitalize>
              </LinkKeyword>
            </Grid>
            { item.has_video &&
              <Grid item>
                {item.ranking_type === 'Ranking' && <GainingIcon/>}
                {item.ranking_type === 'Lost ranking' && <LostRankingIcon/>}
              </Grid> }
              { !item.has_video && <Grid item><BoldTypographyLarge><GreyTypography
                component={'span'}>No video</GreyTypography></BoldTypographyLarge></Grid> }

            { item.has_video && showType && <Grid item><CapitalizeThin>{item.type}</CapitalizeThin>
            </Grid> }
          </Grid>
        </TableCellBodyVTop>
        <TableCellBodyVTop>
          { item.has_video && <BoldTypographyLarge>{item.producer ? `BY ${item.producer}` : ''}</BoldTypographyLarge> }
        </TableCellBodyVTop>
        <TableCellBodyVTop>
          { item.has_video && <MatchType data={item}/> }
        </TableCellBodyVTop>
        <TableCellBodyVTop>
          { item.has_video && <BoldTypographyLarge>{item.plays_count.toLocaleString()}</BoldTypographyLarge> }
        </TableCellBodyVTop>
        <TableCellBodyVTop>
          { item.has_video && item.video_completes > 0 && <BoldTypographyLarge>{item.video_completes}%</BoldTypographyLarge> }
        </TableCellBodyVTop>
        <TableCellBodyVTop>
          { item.has_video && <BoldTypographyLarge>{item.video_cost}</BoldTypographyLarge>}
        </TableCellBodyVTop>
        <TableCellBodyVTop sx={{ width: 90 }}>
          <Grid container alignItems={'center'} spacing={1}>
            <Grid item>
              {item.article?.id === loadingId && <CircularProgress/>}
              {item.article?.cms_edit_url && item.article.id !== loadingId &&
                <PencilIcon style={{ cursor: 'pointer' }}
                            onClick={() => window.open(item.article?.cms_edit_url, '_blank')}/>}
            </Grid>
            { item.has_video && <Grid item>
              {item.article.id !== loadingId && <span
                onClick={() => addVideo(item.article.id, item.video_id)}
                style={{ cursor: 'pointer' }}
              >
            <img width="28" src={plusButton} alt="plus button"/>
          </span>}
            </Grid>}
          </Grid>
        </TableCellBodyVTop>
      </TableRowPrimary>
      {item.article &&
        <TableRow style={{ display: toggleRow ? 'table-row' : 'none' }}
                  sx={{ height: '60px', position: 'relative' }}>
          <TableCellBody padding={'none'}/>
          <TableCellBodyWithLine colSpan={2}>
            { item.article.article_type === 'video' && <SvgIcon><PlayIcon/></SvgIcon>}
            { item.article.article_type === 'image' && <SvgIcon width={20}><ImageIcon fontSize={'large'} sx={{ width: '100%', fill: '#3355D1' }}/></SvgIcon>}
            <Grid container spacing={1} flexDirection={'row'}>
              <Grid item>
                <img alt={item.article.title}
                     src={`${item.article.thumbnail.replace(/s3.eu-west-1.amazonaws.com\//, '')}?width=150`}
                     width={60}/>
              </Grid>
              <Grid item>
                <BoldTypographyMedium>{
                  moment(item.article.publish_date).calendar({
                    sameDay: '[Today]  hh:mm',
                    lastDay: '[Yesterday]  hh:mm',
                    lastWeek: 'DD MMM  hh:mm',
                    sameElse: 'DD MMM hh:mm'
                  })
                }</BoldTypographyMedium>
              </Grid>
              <Grid item sx={{ flex: 1 }}>
                <LinkArticle href={item.article.article_url} target={'_blank'}>{item.article.article_title}</LinkArticle>
              </Grid>
            </Grid>
          </TableCellBodyWithLine>
          <TableCellBodyWithLine className={'last'} colSpan={7}>
            <BoldTypographyLarge><GreyTypography
              component={'span'}>Section:</GreyTypography> {item.article.section}
            </BoldTypographyLarge>
          </TableCellBodyWithLine>
        </TableRow>
      }
    </>
  );
};