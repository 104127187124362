import { useContext, useState } from 'react';
import axios from 'axios';
import { FormControl, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { BaseTypography } from '../common/PublicTypography';
import { InputLabel, InputText, BaseButton } from '../common/Form';
import { getApiBaseUrl } from '../../utils/env';
import { CalculatorContext } from '../../context/calculator/CalculatorProvider';
import { numberWithoutComma } from '../calculator/utils';
import Box from '@mui/material/Box';
import { FormInputOptionalLabel } from '../common/TextLabel';

const FormLegacy = styled('form')`
  background: #ebf1ff;
  margin-top: ${({ theme }) => theme.spacing(3.5)};
  padding: ${({ theme }) => theme.spacing(2.5)};
  color: #2e434e;
  text-align: left;
  border-radius: 4px;

  ${props => props.theme.breakpoints.up('md')} {
    max-width: 840px;
    margin-left: auto;
    margin-right: auto;
    padding: ${({ theme }) => theme.spacing(2.5)} ${({ theme }) => theme.spacing(10)};
  }
`;

const Form = styled('form')`
  padding: ${({ theme }) => theme.spacing(2)};
  border-radius: 0 0 9px 9px;
  color: #2e434e;
  background: #ebf1ff;
  text-align: left;
`;

const ContactFormHead = styled(Box)`
  background: #214bde;
  border-radius: 4px;
  padding: 45px 16px;
`;

export const Headline = styled(BaseTypography)`
  font-size: 38px;
  line-height: 1.14;
  max-width: 280px;
  ${props => props.theme.breakpoints.up('md')} {
    font-size: 64px;
    text-align: center;
    max-width: none;
  }
`;

export const Paragraph = styled(Typography)`
  font-size: 20px;
  line-height: 1.3;
  max-width: 280px;
  margin-top: 20px;
  ${props => props.theme.breakpoints.up('md')} {
    text-align: center;
    max-width: none;
  }
`;

const FormGridRoot = styled(Grid)`
  ${props => props.theme.breakpoints.up('md')} {
    max-width: 840px;
    margin: 0 auto;
  }
`;

const ContactForm = ({ className, contactFormHead, legacy }) => {
  const { calc } = useContext(CalculatorContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [isFormSent, setIsFormSent] = useState(false);

  const submitForm = payload => {
    axios.post(`${getApiBaseUrl()}/enquiry`, payload).then(response => {
      if (response.status === 201) {
        setIsFormSent(true);
        setName('');
        setEmail('');
        setPhone('');
        setWebsiteUrl('');
      }
    });
  };

  const FormRoot = legacy ? FormLegacy : Form;
  const FormRowRoot = legacy ? Grid : FormGridRoot;

  return (
    <Grid container className={className}>
      <Grid item xs={12} justifyContent={'center'}>
        {!legacy && (
          <ContactFormHead>
            {!contactFormHead && (
              <>
                <Headline variant={'h1'}>Contact us now</Headline>
                <Paragraph variant={'body1'}>Enter your details below and we'll be back in touch soon</Paragraph>
              </>
            )}
            {contactFormHead}
          </ContactFormHead>
        )}
        {legacy && (
          <>
            <Headline variant={'h1'}>Contact us</Headline>
            <Paragraph variant={'body1'}>We’ll let you know how to start generating revenue with vRoll&#8482;</Paragraph>
          </>
        )}
        <FormRoot
          onSubmit={e => {
            e.preventDefault();
            submitForm({
              name,
              email,
              phone,
              websiteUrl,
              dailyPageviews: numberWithoutComma(calc?.dailyPageviews || '') || null,
              matchPercentage: calc?.matchPercentage || null,
              currentAverageCpm: calc?.autoCpm || null,
              isAutoplay: calc?.isCtp !== undefined ? String(!calc.isCtp) : null,
              fillPercentage: calc?.fillRatePercentage || null,
              includeHeaderbidding: calc?.includeHeaderbidding !== undefined ? String(calc.includeHeaderbidding) : null,
              isProgrammatic: calc?.isProgrammatic ? String(calc.isProgrammatic) : null,
              percentagePagesMissingVideo: calc?.imageToVideoRatioPercentage || null,
            });
          }}
        >
          <FormRowRoot container spacing={2}>
            <Grid item md={6} xs={12}>
              <FormControl margin="dense" fullWidth>
                <InputLabel htmlFor="name">Name*</InputLabel>
                <InputText
                  id={'name'}
                  onChange={({ target }) => setName(target.value)}
                  required
                  value={name}
                  placeholder={'Your Name'}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl margin="dense" fullWidth>
                <InputLabel htmlFor="email">Email*</InputLabel>
                <InputText
                  id={'email'}
                  onChange={({ target }) => setEmail(target.value)}
                  required
                  value={email}
                  placeholder={'Your Email'}
                  type={'email'}
                />
              </FormControl>
            </Grid>

            <Grid item md={6} xs={12}>
              <FormControl margin="dense" fullWidth>
                <InputLabel htmlFor="phone">
                  Phone <FormInputOptionalLabel>(optional)</FormInputOptionalLabel>
                </InputLabel>
                <InputText
                  id={'phone'}
                  onChange={({ target }) => setPhone(target.value)}
                  placeholder={'Your Phone Number'}
                  value={phone}
                  type={'number'}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl margin="dense" fullWidth>
                <InputLabel htmlFor="websiteInQuestion">Website in Question*</InputLabel>
                <InputText
                  id={'websiteInQuestion'}
                  required
                  placeholder={'your-website.com'}
                  value={websiteUrl}
                  onChange={({ target }) => setWebsiteUrl(target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent={'center'}>
                <Grid item>
                  <BaseButton type={'submit'} disabled={isFormSent} variant={'contained-x'} fullWidth>
                    {isFormSent ? 'We’ll be in touch within 24 hours' : 'Send'}
                  </BaseButton>
                </Grid>
              </Grid>
            </Grid>
          </FormRowRoot>
        </FormRoot>
      </Grid>
    </Grid>
  );
};

ContactForm.propTypes = {
  className: PropTypes.string,
  contactFormHead: PropTypes.element,
  legacy: PropTypes.bool,
};

export default styled(ContactForm)``;
