import { Checkbox, FormControl, FormControlLabel, Grid, TextField } from '@mui/material';
import * as React from 'react';

export const PublisherDetailTab1 = ({ data, onChageData, orgSlug }) => {
  return (<Grid container spacing={2}>
    <Grid item xs={12}>
      <FormControl fullWidth>
        <TextField
          fullWidth
          name="name"
          value={data.name}
          label="Publisher Name"
          variant="outlined"
          placeholder={'eg. Example News'}
          onChange={onChageData('name')}
        />
      </FormControl>
    </Grid>

    <Grid item xs={12}>
      <FormControl fullWidth>
        <TextField
          fullWidth
          name="domain"
          value={data.domain}
          label="Publisher Slug"
          variant="outlined"
          placeholder={'eg. examplenews'}
          onChange={onChageData('domain')}
        />
      </FormControl>
    </Grid>

    <Grid item xs={12}>
      <FormControl fullWidth>
        <TextField
          fullWidth
          name="hostname"
          value={data.hostname}
          label="Publisher hostname"
          variant="outlined"
          placeholder={'eg. https://www.examplenews.co.uk'}
          onChange={onChageData('hostname')}
        />
      </FormControl>
    </Grid>

    <Grid item xs={12}>
      <FormControl fullWidth>
        <TextField
          type={'number'}
          fullWidth
          name="matched_threshold"
          value={data.matched_score_threshold}
          label="Matched threshold"
          variant="outlined"
          placeholder={'Number only, from 0 to 100'}
          onChange={onChageData('matched_score_threshold')}
        />
      </FormControl>
    </Grid>
    {orgSlug && <Grid item xs={12}>
      <FormControlLabel
        control={<Checkbox onChange={({ target: { checked } }) => {
          onChageData({
            ...data,
            is_active: checked
          });
        }}
                           checked={data.is_active}/>}
        label="Active"/>
    </Grid>}
  </Grid>);
};