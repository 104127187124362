import { Grid } from '@mui/material';
import { LogoAnchor, LogoHeader, MenuItem, TopMenu } from './common';
import MenuIcon from '../../../icons/Menu2';
import { styled } from '@mui/material/styles';

const HeaderGrid = styled(Grid)`
  height: 100px;

  ${props => props.theme.breakpoints.up('md')} {
    background-color: inherit;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Header = ({ setDrawerState }) => (
  <header>
    <HeaderGrid
      pb={2}
      pt={2}
      pl={2.5}
      pr={2.5}
      container
      spacing={{ xs: 0 }}
      justifyContent={'space-between'}
      maxWidth={1440}
      alignItems={'center'}
    >
      <Grid item>
        <LogoAnchor href={'/'}>
          <LogoHeader>Pixels AI</LogoHeader>
        </LogoAnchor>
      </Grid>
      <Grid item>
        <MenuIcon onClick={() => setDrawerState(true)} />
        <TopMenu>
          <MenuItem>
            <a href={'/instream-revenue-uplift-calculator/'}>Get Started</a>
          </MenuItem>
          <MenuItem>
            <a
              href={'https://pixelsai.notion.site/b65d2273ad9e41c9a66d2236a3e34fca?v=c648b2ab3d7346f581954fb04b1b5003'}
            >
              Product Centre
            </a>
          </MenuItem>
          <MenuItem>
            <a href={'/sustainability/'}>Sustainability</a>
          </MenuItem>
          <MenuItem>
            <a href={'/contact/'}>Contact</a>
          </MenuItem>
          <MenuItem>
            <a href={'/login/'}>
              <strong>Login</strong>
            </a>
          </MenuItem>
        </TopMenu>
      </Grid>
    </HeaderGrid>
  </header>
);

export default Header;
