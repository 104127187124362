import { styled } from '@mui/material/styles';
import { Button, Grid } from '@mui/material';
import { Headline as BaseHeadline, TextHighlighted } from '../common/PublicTypography';
import { ReactComponent as ArrowIcon } from '../../icons/button-arrow.svg';

const MarketingVideoGrid = styled(Grid)`
  background-color: #fff;
  padding: 10px 20px 20px;
  color: #fff;

  ${props => props.theme.breakpoints.up('md')} {
    padding: 30px 60px 60px;
  }
`;

const MarketingVideoContainer = styled(Grid)`
  display: flex;
  flex-direction: column-reverse;
  background-color: #2f53db;
  width: 100%;
  padding: 10px 20px 20px;

  ${props => props.theme.breakpoints.up('md')} {
    flex-direction: row-reverse;
    padding: 50px 0 30px;
  }
`;

const MarketingVideoTextsColumn = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;

  ${props => props.theme.breakpoints.up('md')} {
    padding: 0 60px;
  }
`;

const MarketingVideoColumn = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TextBody = styled('p')`
  font-size: 20px;
  line-height: 1.485;
  margin-top: 0;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: 22px;
    line-height: 1.5;
    margin-bottom: 0;
  }
`;

const SubmitBtn = styled(Button)`
  font-size: 18px;
  text-transform: initial;
  padding: 8px 24px;
  line-height: 1.2;

  ${props => props.theme.breakpoints.up('md')} {
    margin: 40px 0;
    padding: 10px 28px;
    width: 300px;
  }

  & .MuiButton-endIcon {
    margin-left: 24px;
  }
`;

const VideoContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;

  ${props => props.theme.breakpoints.up('md')} {
    padding: 0 60px;
  }
`;

const Video = styled('video')`
  width: 100%;
  height: 100%;
`;

const DesktopHeadline = styled(BaseHeadline)`
  display: none;

  ${props => props.theme.breakpoints.up('md')} {
    display: block;
    font-size: 42px;
    line-height: 1.1;
  }
`;

const MobileHeadline = styled(BaseHeadline)`
  display: none;
  ${props => props.theme.breakpoints.down('md')} {
    font-size: 28px;
    line-height: 1.2;
    display: block;
    margin-bottom: 30px;
    padding: 0 8px;
  }
`;

const MarketingVideo = () => {
  return (
    <MarketingVideoGrid container id="MarketingVideo-Grid">
      <MarketingVideoContainer id="MarketingVideoContainer" container>
        <MarketingVideoTextsColumn>
          <DesktopHeadline>
            <TextHighlighted>Reuse</TextHighlighted> your existing
            <br />
            social videos
          </DesktopHeadline>
          <TextBody>
            Give us your existing videos, including from your socials, and we'll create engaging vertical video
            experiences for every article page. vRoll&#8482; included.
          </TextBody>
          <SubmitBtn
            className={'submit-btn-mobile'}
            href={'/instream-revenue-uplift-calculator/'}
            variant={'outlined-x'}
            endIcon={<ArrowIcon />}
          >
            Get Started
          </SubmitBtn>
        </MarketingVideoTextsColumn>
        <MarketingVideoColumn>
          <MobileHeadline>
            <TextHighlighted>Reuse</TextHighlighted> your existing
            <br />
            social videos
          </MobileHeadline>
          <VideoContainer>
            <Video autoPlay loop muted playsInline>
              <source src="/v3/marketing-video.mp4" type="video/mp4" />
            </Video>
          </VideoContainer>
        </MarketingVideoColumn>
      </MarketingVideoContainer>
    </MarketingVideoGrid>
  );
};

export default MarketingVideo;
