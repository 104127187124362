import React, { createContext, useEffect, useReducer } from "react";
import { calculatorReducer, CalculatorState, initializer, initialState } from './reducer';

type CalculatorCtx = {
  calc: CalculatorState,
  dispatch: React.Dispatch<any>
}

export const CalculatorContext = createContext<CalculatorCtx>({
  calc: initialState,
  dispatch: () => {}
});

export const CalculatorProvider = ({ children }) => {
  const [calc, dispatch] = useReducer(calculatorReducer, initialState, initializer);

  useEffect(() => {
    window.localStorage.setItem("localCalculator", JSON.stringify(calc));
  }, [calc]);

  return (
    <CalculatorContext.Provider
      value={{
        calc,
        dispatch
      }}
    >
      {children}
    </CalculatorContext.Provider>
  );
};