import * as React from 'react';
import {
  Grid, LinearProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Heading } from './common/Heading';
import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import DemoPublisherTabs from './admin/DemoPublisherTabs';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import { TableRowPrimary } from './SmartLibrary';
import { TableCellBody, TableCellHead } from './common/Table';
import TableBody from '@mui/material/TableBody';
import PaginationTable from './Pagination';

const ActionRoot = styled(Grid)`
  margin-bottom: 20px;`;

const Root = styled('div')`
  position: relative;

  .linear-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }

  .linear-top {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
  }
`;

type Article = {
  articleid: number;
  headline: string;
  url: string;
  thumbnail: string;
  video_id: string;
  is_video: boolean;
  is_visible_in_dashboard: boolean
  video_score: number;
}


const VideoRow = ({
  data: video
}) => {

  return (<TableRowPrimary sx={{ height: '60px', position: 'relative' }}>
    <TableCellBody>{video.thumbnail && <img alt={video.title} style={{ maxWidth: 80 }}
                                               src={video.thumbnail}/>}</TableCellBody>
    <TableCellBody>{video.title_translated}</TableCellBody>
    <TableCellBody>{new Date(video.publishdate * 1000).toLocaleString()}</TableCellBody>
  </TableRowPrimary>);
};

export default function PublishersDemoVideos() {
  const { orgSlug } = useParams();

  const isAdmin = localStorage.getItem('is_admin') === 'true';
  if (!isAdmin) {
    window.location.href = '/';
  }

  const [videos, setVideos] = useState<Article[]>([]);
  const [orgName, setOrgName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [limit, setLimit] = useState(20);
  const [busy, setBusy] = useState(false);


  const getArticles = () => {
    setBusy(true)
    axios.get(
      `${getApiBaseUrl()}/article-demo/${orgSlug}/videos`,
      {
        params: {
          page: currentPage,
          limit: limit
        }
      }
    ).then(({ data }) => {
      setVideos(data.items);
      setTotalItems(data.meta.totalItems);
      setCurrentPage(data.meta.currentPage);
      setBusy(false);
    }).catch(ex => {
      setBusy(false)
      if (ex.message) {
        alert(ex.message);
      }
      console.error(ex);
    });
  };

  useEffect(() => {
    if (orgSlug) {
      getArticles();
      axios.get(
        `${getApiBaseUrl()}/organisation/demo/${orgSlug}`,
      ).then(({ data }) => {
        setOrgName(data.name);
      }).catch(ex => {
        if (ex.message) {
          alert(ex.message);
        }
        console.error(ex);
      });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgSlug, currentPage, limit]);


  return (
    <React.Fragment>
      <Root>
        <Grid container
              style={{ paddingBottom: '15px', paddingLeft: '15px', alignItems: 'stretch' }}>
          <Grid container alignItems="stretch" justifyContent={'space-between'}>
            <Grid item>
              {orgName && <Heading>{orgName} demo</Heading>}
            </Grid>
            <Grid item>
            </Grid>
          </Grid>
        </Grid>
      </Root>
      <Root>
        <ActionRoot container spacing={2} justifyContent={'space-between'}>
          <Grid item>
            <Button href={'/publishers'} variant={'outlined'}>Back</Button>
          </Grid>

        </ActionRoot>
        <DemoPublisherTabs />
        {busy && <LinearProgress color="success" />}
        {videos.length > 0 && <Table size="small">
          <TableHead>
            <TableRowPrimary>
              <TableCellHead>Thumbnail</TableCellHead>
              <TableCellHead>Title</TableCellHead>
              <TableCellHead>Publish date</TableCellHead>
            </TableRowPrimary>
          </TableHead>
          <TableBody>
            {videos.map((article) => <VideoRow key={article.articleid} data={article}/>)}
          </TableBody>
        </Table>}
        {busy && <LinearProgress color="success" />}
        {videos.length > 0 && <PaginationTable
          defaultSize={20}
          busy={false}
          startPage={currentPage - 1}
          count={totalItems}
          onChange={page => {
            setCurrentPage(page+1)
          }}
          updateRowPerPage={totalPerPage => {
            setLimit(totalPerPage)
          }}
        />}
      </Root>
    </React.Fragment>
  );
}
