import { Grid, LinearProgress } from '@mui/material';
import * as React from 'react';
import { PublicationSelector } from './PublicationSelector';
import { useContext, useEffect } from 'react';

import PromotedContentTabs from './promoted-content/PromotedContentTabs';
import { apiReportUrlProvider } from '../store/reportSlice';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { OrganisationContext } from '../App';

const ReportFrame = styled('iframe')`
  width: 100%;
  height: 85vh;
  border: none;
  background-color: transparent;
`;

export default function PromotedContentReport1() {
  const dispatch = useDispatch();
  const { selectedOrganisation } = useContext(OrganisationContext);

  const { url, busy } = useSelector((state: any) => state.report);

  useEffect(() => {
    if (!selectedOrganisation) return;
    dispatch<any>(
      apiReportUrlProvider({
        publisher: selectedOrganisation,
        params: {
          dashboardId: 49
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisation]);

  return (
    <React.Fragment>
      <Grid container style={{ paddingBottom: '15px', paddingLeft: '15px' }}>
        <Grid container alignItems="center"  justifyContent={'flex-end'}>
          <Grid item >
            <PublicationSelector />
          </Grid>
        </Grid>
        <PromotedContentTabs/>
        {busy && <LinearProgress sx={{ position: 'absolute', top: 0, width: '100%' }} color="success" />}
        <ReportFrame src={url}></ReportFrame>
      </Grid>
    </React.Fragment>
  );
}
