import * as React from 'react';
import {
  Grid, Tab,
} from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { styled } from '@mui/material/styles';
import { Heading } from './common/Heading';
import Button from '@mui/material/Button';
import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PublisherDetailTab1 } from './admin/PublisherDetailTab1';
import { TabList } from '@mui/lab';
import { PublisherDetailTab2 } from './admin/PublisherDetailTab2';
import { PublisherDetailTab3 } from './admin/PublisherDetailTab3';
import Box from '@mui/material/Box';

const Root = styled('div')`
  position: relative;

  .linear-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }

  .linear-top {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
  }
`;

export default function PublisherDetail() {
  const { orgSlug } = useParams();
  const navigate = useNavigate();

  const isAdmin = localStorage.getItem('is_admin') === 'true';
  if (!isAdmin) {
    window.location.href = '/';
  }

  const [tabIndex, setTabIndex] = useState('0');

  const [data, setData] = useState({
    is_demo: true,
    name: '',
    domain: '',
    hostname: '',
    rss_feed_url: '',
    video_tag_patterns: '',
    image_tag_pattern: '',
    crawl_article_size_per_minute: 1,
    crawl_article_total: 50,
    crawl_current_article_count: 0,
    is_active: true,
    scrape_type: 'sitemap',
    rightsholders: [],
    video_provider_type: 'jwplayer',
    video_provider_config: {},
    matched_score_threshold: 80,
    initiate_scrape: false,
    scrape_sections: ''
  });

  const changeData = (key) => ({ target: { value } }) => setData({
    ...data,
    [key]: value
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const action = orgSlug ? 'patch' : 'post';
    const payload: any = data;
    if (orgSlug) {
      payload.domain = orgSlug;
    }
    axios[action](
      `${getApiBaseUrl()}/organisation/demo`,
      payload,
    ).then(() => {
      if (!orgSlug) {
        alert('Publisher is added successfully');
        navigate(`/publishers/edit/${data.domain}`)
      } else {
        alert('Data saved successfully');
        if (data.initiate_scrape) {
          setData({
            ...data,
            initiate_scrape: false
          })
        }
      }
    }).catch((ex) => {
      const { message } = ex.response?.data;
      if (Array.isArray(message) && message.length > 0) {
        alert(message.join('\n'));
      }
    });
  };

  const updatePublisher = () => {
    axios.get(
      `${getApiBaseUrl()}/organisation/demo/${orgSlug}`,
    ).then(({ data: orgDemo }) => {
      if (orgDemo) {
        setData({
          ...data,
          ...orgDemo
        });
      }
    }).catch(ex => {
      if (ex.message) {
        alert(ex.message);
      }
      console.error(ex);
    });
  };

  useEffect(() => {
    if (orgSlug) {
      updatePublisher();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgSlug]);


  return (
    <React.Fragment>
      <Root>
        <Grid container style={{ paddingBottom: '15px', paddingLeft: '15px' }}>
          <Grid container alignItems="center" justifyContent={'space-between'}>
            <Grid item>
              <Heading>{orgSlug ? 'Edit demo publisher' : 'Add demo publisher'}</Heading>
            </Grid>
          </Grid>
        </Grid>
      </Root>
      <Root>

        <form onSubmit={handleSubmit} style={{ width: 980 }}>
          <TabContext value={tabIndex}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={(_, nextVal) => setTabIndex(nextVal)}>
                <Tab value="0" label="Organisation Detail"/>
                <Tab value="1" disabled={!orgSlug} label="Videos"/>
                <Tab value="2" disabled={!orgSlug} label="Articles"/>
              </TabList>
            </Box>
            <Box sx={{ paddingBottom: 4, paddingTop: 2 }}>
              <TabPanel value="0">
                <PublisherDetailTab1 data={data} orgSlug={orgSlug} onChageData={changeData}/>
              </TabPanel>
              <TabPanel value="1">
                <PublisherDetailTab2 data={data} onChageData={changeData} setData={setData}/>
              </TabPanel>
              <TabPanel value="2">
                <PublisherDetailTab3 data={data} orgSlug={orgSlug} onChageData={changeData} setData={setData}/>
              </TabPanel>
            </Box>
          </TabContext>
          <Grid container justifyContent={'space-between'} sx ={{ padding: 3 }}>
              <Grid item >
                <Button href="/publishers" variant="outlined">Cancel</Button>
              </Grid>
              <Grid item>
                { tabIndex === '2' &&<Button disabled={
                  data.scrape_type === 'section' && data.scrape_sections === ''
                } color="primary" variant="contained" type={'submit'} onClick={() => {
                  setData({
                    ...data,
                    initiate_scrape: true,
                  })
                }}>Scrape</Button>}
                { tabIndex !== '2' && <Button color="primary" variant="contained" type={'submit'} onClick={() => {
                  setData({
                    ...data,
                    initiate_scrape: false,
                  })
                }}>Submit</Button>}
              </Grid>
          </Grid>
        </form>
      </Root>
    </React.Fragment>
  );
};
