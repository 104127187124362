import Calculator from '../Calculator';

export default function DemoSections({ section }) {
  return (
    <>
      {section === 'calculator' && (
        <Calculator />
      )}
    </>
  );
}
