import { Box, GlobalStyles } from '@mui/material';
import Helmet from 'react-helmet';

import '../../App.css';
import './PublicLayout.css';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { useState } from 'react';
import Header from './component/Header';
import Footer2 from './component/Footer2';
import MobileDrawer from './component/Drawer';

const ContentRoot = styled(Box)`
  flex: 1;
  ${props => props.theme.breakpoints.up('md')} {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
`;

const ContentInner = styled(Box)`
  padding-left: ${({ theme }) => theme.spacing(2.6) };
  padding-right: ${({ theme }) => theme.spacing(2.6) };
  ${props => props.theme.breakpoints.up('md')} {
    max-width: 800px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
`;

const Root = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: initial;
  height: 100%;
  margin: 0 auto;
  min-height: 100vh;
`;

function PublicLayout({ children, className }) {
  const [drawerState, setDrawerState] = useState(false);

  return (
    <Root className={className}>
      <GlobalStyles styles={{ body: { backgroundColor: '#274bde', color: '#fff' } }} />
      <Helmet>
        <meta charSet="UTF-8"/>
      </Helmet>
      <Header setDrawerState={setDrawerState} />
      <ContentRoot className={'content-root'}>
        <ContentInner className={'content-inner'}>
          {children}
        </ContentInner>
      </ContentRoot>
      <Footer2/>
      <MobileDrawer setDrawerState={setDrawerState} drawerState={drawerState} />
    </Root>
  );
}

PublicLayout.propTypes = {
  className: PropTypes.string,
};

export default styled(PublicLayout)`
`;
