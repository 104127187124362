import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import { getApiBaseUrl } from '../../utils/env';
import { useParams } from 'react-router-dom';

const Provider = {
  JWPLAYER: 'jwplayer',
  DAILYMOTION: 'dailymotion',
  YOUTUBE: 'youtube'
};

export const PublisherDetailTab2 = ({ data, onChageData, setData }) => {
  const { orgSlug } = useParams();
  const { video_provider_type: videoProvider } = data;

  useEffect(() => {
    switch (data.video_provider_type) {
      case 'jwplayer':
        setData({
          ...data,
          video_provider_config: {
            key: '',
            secret: '',
            initialSizeVideoToImport: 10,
            ...data.video_provider_config
          }
        });
        break;
      default:
        setData({
          ...data,
          video_provider_config: {
            channelId: '',
            initialSizeVideoToImport: 10,
            ...data.video_provider_config
          }
        });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.video_provider_type]);

  const scrapeYoutube = async () => {
    await axios.get(`${getApiBaseUrl()}/youtube-importer/${orgSlug}`);
  }


  return (<Grid container spacing={2}>
    <Grid item xs={12}>
      <FormControl fullWidth>
        <InputLabel id="video-provider-selector">Video Provider</InputLabel>
        <Select
          labelId="video-provider-selector"
          id="video-provider"
          value={data.video_provider_type}
          label={"Video Provider"}
          onChange={onChageData('video_provider_type')}
        >
          <MenuItem value={Provider.JWPLAYER}>JW Player</MenuItem>
          <MenuItem value={Provider.DAILYMOTION}>Daily Motion</MenuItem>
          <MenuItem value={Provider.YOUTUBE}>YouTube</MenuItem>
        </Select>
      </FormControl>
    </Grid>
    {videoProvider === Provider.JWPLAYER && <><Grid item xs={12}>
      <TextField
        fullWidth
        value={data.video_provider_config.key}
        name="jwplayer_key"
        label="Key"
        variant="outlined"
        onChange={({ target: { value } }) => {
          setData({
            ...data,
            video_provider_config: {
              ...data.video_provider_config,
              key: value
            }
          });
        }}
      />
    </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={data.video_provider_config.secret}
          name="jwplayer_secret"
          label="Secret"
          variant="outlined"
          onChange={({ target: { value } }) => {
            setData({
              ...data,
              video_provider_config: {
                ...data.video_provider_config,
                secret: value
              }
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          type={'number'}
          fullWidth
          value={data.video_provider_config.initialSizeVideoToImport}
          name="jwplayer_initial_size"
          label="Initial size video to import"
          variant="outlined"
          onChange={({ target: { value } }) => {
            setData({
              ...data,
              video_provider_config: {
                ...data.video_provider_config,
                initialSizeVideoToImport: value
              }
            });
          }}
        />
      </Grid></>}
    {videoProvider === Provider.DAILYMOTION && <Grid item xs={12}>
      <TextField
        fullWidth
        value={data.video_provider_config.channelId}
        name="dailymotion_channel_id"
        label="Channel Id"
        variant="outlined"
        onChange={({ target: { value }}) => {
           setData({
              ...data,
              video_provider_config: {
                ...data.video_provider_config,
                channelId: value
              }
            });
        }}
      />
    </Grid>}
    {videoProvider === Provider.YOUTUBE && <><Grid item xs={12}>
      <TextField
        fullWidth
        value={data.video_provider_config.channelId}
        name="dailymotion_channel_id"
        label="Channel Id"
        variant="outlined"
        onChange={({ target: { value }}) => {
           setData({
              ...data,
              video_provider_config: {
                ...data.video_provider_config,
                channelId: value
              }
            });
        }}
      />

    </Grid>
      { data.video_provider_config.channelId && <Grid item xs={12}>
        <Button onClick={scrapeYoutube}>Scrape now</Button>
      </Grid>}
    </>}
  </Grid>);
};