import { Dialog, DialogContent, Grid, IconButton, Tab } from '@mui/material';
import * as React from 'react';
import ReactJWPlayer from 'react-jw-player';
import ReactPlayer from 'react-player';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Moment from 'react-moment';
import ProducerHeading from '../ProducerHeading';
import FcPlayer from '../FcPlayer';
import TabPanel from '@mui/lab/TabPanel';
import { TabList } from '@mui/lab';
import { useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import { TranscriptPanel } from './TranscriptPanel';
import { PixelsVideoPlayer } from './PixelsVideoPlayer';

const CloseBtnRoot = styled('div')`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const Root = styled('div')`
  padding: 20px 40px 0;
  position: relative;
`;

const ReactJWPlayerRoot = styled(ReactJWPlayer)`
  min-height: 265px;
`;

const VideoRoot = ({ video, format, playerKey }) => (
  <>
    {video.video_type === 'youtube' && (
      <iframe
        title="Youtube preview"
        style={{ aspectRatio: '16/9' }}
        height={270}
        src={`https://www.youtube.com/embed/${video.media_id}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    )}
    {video.video_type === 'jwplayer' && (
      <ReactJWPlayerRoot
        playerId={video.media_id}
        playerScript="https://content.jwplatform.com/libraries/4i84SKoN.js"
        playlist={`https://cdn.jwplayer.com/v2/media/${video.media_id}`}
      />
    )}
    {video.video_type === 'dailymotion' && (
      <ReactPlayer
        url={`https://www.dailymotion.com/video/${video.media_id}`}
        controls
        width={'100%'}
        height={'265px'}
      />
    )}
    {video.video_type === 'fcplayer' && <FcPlayer mediaId={video.media_id} />}
    {video.video_type === 'pixels' && <PixelsVideoPlayer video={video} playerKey={playerKey} format={format} />}
  </>
);

const Video = ({ video, format, transcript, playerKey }) => {
  format = format ?? video.format;
  const [tabValue, setTabValue] = useState('1');
  return (
    <>
      <Grid key={video.media_id} sx={{ display: 'flex' }} container spacing={1}>
        <Grid item xs={12}>
          <Grid container alignItems={'center'}>
            <ProducerHeading row={video} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {transcript && (
            <>
              <TabContext value={tabValue}>
                <TabList onChange={(_, nextVal) => setTabValue(nextVal)}>
                  <Tab value={'1'} label={'Video'} />
                  <Tab value={'2'} label={'Transcript'} />
                </TabList>
                <TabPanel value="1">
                  <VideoRoot video={video} format={format} playerKey={playerKey} />
                </TabPanel>
                <TabPanel value="2" style={{ aspectRatio: '9 / 16' }}>
                  <fieldset>{transcript}</fieldset>
                </TabPanel>
              </TabContext>
            </>
          )}
          {!transcript && <VideoRoot video={video} format={format} playerKey={playerKey} />}
        </Grid>
        <Grid item xs={9}>
          {video.title}
        </Grid>
        <Grid item xs={3}>
          <Moment format="Do MMM YYYY">{video.publish_date}</Moment>
        </Grid>
      </Grid>
    </>
  );
};

export const VideoSinglePreview = ({
  open,
  handleClose,
  video,
  transcript,
  format = 'horizontal',
  showTranscriptPanel = false,
}) => {
  const [playerKey, setPlayerKey] = useState(0);
  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={showTranscriptPanel ? 'xl' : 'sm'}>
      <Root>
        <CloseBtnRoot>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </CloseBtnRoot>
        <DialogContent>
          <Grid container spacing={1}>
            {showTranscriptPanel ? (
              <Grid item xs={6}>
                <TranscriptPanel
                  mediaId={video.video_id}
                  rightsholder={video.rightsholder}
                  setPlayerKey={setPlayerKey}
                />
              </Grid>
            ) : (
              ''
            )}
            <Grid item xs={showTranscriptPanel ? 6 : 12}>
              <Video playerKey={playerKey} video={video} format={format} transcript={transcript} />
            </Grid>
          </Grid>
        </DialogContent>
      </Root>
    </Dialog>
  );
};
