import { styled } from '@mui/material/styles';


const PlaylistDate = styled('span')`
   &.warning {
        color: orangered;
    }

    &.info {
      color: green;
    }
`
const PlaylistRefreshedDate = ({ lastRefreshed, refreshRateInMins }: { lastRefreshed: string, refreshRateInMins: number }) => {
  if (!lastRefreshed) return <span>Never</span>
  const lastRefreshDate= new Date(lastRefreshed);
  const nextRefresh = new Date(lastRefreshDate.getTime() + refreshRateInMins * 60 * 1000);
  const overdue = nextRefresh.getTime() < Date.now();
  return <PlaylistDate className={ overdue ? 'warning' : 'info' } title={`next scheduled refresh approximately at ${nextRefresh.toUTCString()}`}>{lastRefreshDate.toUTCString()}</PlaylistDate>
}

export default PlaylistRefreshedDate