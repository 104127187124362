import * as React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { GlobalStyles, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import PublicLayout2 from './PublicLayout2';
import Calculator from '../Calculator';
import { TextHighlighted } from '../common/PublicTypography';
import ContactForm from '../contact/ContactForm';
import { useContext, useEffect, useState } from 'react';
import { CalculatorContext } from '../../context/calculator/CalculatorProvider';
import {
  numberWithoutComma
} from '../calculator/utils';
import Header from './component/Header';
import MobileDrawer from './component/Drawer';
import ContentWrap from './component/ContentWrap';

const CalcRoot = styled(Box)`
  border-radius: 9px;
  background: #EBF1FF;
  color: #112671;
  ${props => props.theme.breakpoints.down('md')} {
    margin-top: 38px;
  }
`;

const ContactFormCalculator = styled(ContactForm)`
  margin-bottom: 75px;
`;

const ContentWrapSecondary = styled(ContentWrap)`
  background: white;
`;

const ContentWrapTertiary = styled(ContentWrap)`
  padding-left: 20px;
  padding-right: 20px;
  width: auto;
`

const Headline = styled(Typography)`
  font-size: 38px;
  line-height: 1.14;
  max-width: 280px;
  ${props => props.theme.breakpoints.up('md')} {
    font-size: 64px;
    text-align: center;
    max-width: none;
  }
`

const Headline2 = styled(Typography)`
  font-size: 32px;
  line-height: 1.14;
  ${props => props.theme.breakpoints.up('md')} {
    font-size: 42px;
    text-align: center;
    max-width: none;
  }
`

const Tagline = styled(Typography)`
  font-size: 20px;
  line-height: 1.2;
  margin-top: 8px;
  ${props => props.theme.breakpoints.up('md')} {
    font-size: 28px;
    text-align: center;
  }
`
const TaglineSecondary = styled(Tagline)`
  font-size: 16px;
  line-height: 1.5;
  margin-top: 16px;
  ${props => props.theme.breakpoints.up('md')} {
    font-size: 22px;
  }
`

export const Paragraph = styled(Typography)`
  font-size: 20px;
  line-height: 1.3;
  max-width: 280px;
  margin-top: 20px;
  ${props => props.theme.breakpoints.up('md')} {
    text-align: center;
    max-width: none;
  }
`;

const ContactFormHead = ({ eligibleForFreeTrial }) => {
  if (eligibleForFreeTrial === true) {
    return (<>
      <Headline2 variant={'h2'}>Congratulations!</Headline2>
      <Tagline variant={'body1'}>You are eligible for a <TextHighlighted>free 3 month trial</TextHighlighted> with us.</Tagline>
      <TaglineSecondary variant={'body1'}>Enter your details below and we'll be back in touch within 24h.</TaglineSecondary>
    </>)
  }
  if (eligibleForFreeTrial === false) {
    return (<>
      <Headline2 variant={'h2'}>Sorry!</Headline2>
      <Tagline variant={'body1'}>You’re not eligible for a free trial with us just yet.</Tagline>
      <TaglineSecondary variant={'body1'}>However, if you want to find out more fill out the form below and we’ll be in touch soon.</TaglineSecondary>
    </>)
  }
  return (<>
    <Headline2 variant={'h1'}>Contact us now</Headline2>
    <Paragraph variant={'body1'}>Enter your details below and we'll be back in touch soon</Paragraph>
  </>)
}

function RevenueLossCalculator({ className }) {
  const { calc } = useContext(CalculatorContext);
  const [drawerState, setDrawerState] = useState(false);
  const [eligbleForFreeTrial, setEligibleForFreeTrial] = useState<boolean | null>(false);

  useEffect(() => {
    setEligibleForFreeTrial(calc.monthlyPageviews === '' ? null : numberWithoutComma(calc.monthlyPageviews) >= 10000000)
  }, [setEligibleForFreeTrial, calc.monthlyPageviews]);
  return (
    <PublicLayout2 className={className}>
      <GlobalStyles styles={{
        body: {
            backgroundImage: 'url("/v2/pixels-bg.svg")',
            backgroundPosition: 'center 0',
            backgroundRepeat: 'repeat-x'
        }
      }}/>
      <Helmet title="Pixels AI - Check Eligibility for a Free 3-Month Trial">
        <meta name="description"
              content="Google is changing its policy around instream video classification. Want to know how much revenue you could generate with Pixels AI's instream video solution? Fill out the form to access a personalised revenue estimate and check if you qualify for a free 3-month trial. Get access to insights to optimise your instream video and maximise revenue streams."/>
        <script defer src={'/observeBox.js'}></script>
      </Helmet>
      <ContentWrap maxWidth={1440}>
        <Header setDrawerState={setDrawerState}/>
      </ContentWrap>
      <Grid container>
        <Grid item xs={12} display={'flex'} flexDirection={'column'} justifyContent={'center'}
              sx={{
                minHeight: {
                  // lg: '364px',
                  md: '260px'
                },
                marginBottom: '174px', pl: '20px', pr: '20px'
              }}>
          <Headline variant={'h1'}>
            Check eligibility for <TextHighlighted>free trial</TextHighlighted>
          </Headline>
          <Paragraph color={'white'} variant={'body1'}>Fill out the section below to check if you are eligible
            for <strong>a free 3 month trial.</strong></Paragraph>
        </Grid>
      </Grid>
      <ContentWrapSecondary>
        <ContentWrapTertiary sx={{ mt: '-174px'}} maxWidth={920}>
          <CalcRoot>
            <Calculator />
          </CalcRoot>
          <ContactFormCalculator
            contactFormHead={<ContactFormHead eligibleForFreeTrial={eligbleForFreeTrial} /> as any} />
        </ContentWrapTertiary>
      </ContentWrapSecondary>

      <MobileDrawer setDrawerState={setDrawerState} drawerState={drawerState}/>
    </PublicLayout2>
  );
}

RevenueLossCalculator.propTypes = {
  className: PropTypes.string
};

export default styled(RevenueLossCalculator)`

`;
