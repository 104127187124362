import { ReactComponent as GreenDotIcon } from '../../icons/green-dot.svg';
import { ReactComponent as AmberIcon } from '../../icons/amber-dot.svg';
import { ReactComponent as KeyIcon } from '../../icons/key.svg';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { BoldTypographyLarge } from './TextLabel';

const KeyWordMatch = styled('div')`
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
  }
`;

const MatchPercentileRoot = styled('span')`
  padding-left: 6px;
`;

const TextNoWrap = styled(BoldTypographyLarge)`
  white-space: nowrap;
`;


const TextWithIcon = styled(TextNoWrap)`
  display: flex;
  align-items: center;
  
  svg {
    display: inline-block;
    margin-top: -2px;
  }
`

const editorial_delta = data => {
  //@TODO: replace this hacky hack to add something to the UI to show video match uplift...
  if (data.editorial_delta !== null) {
    if (data.editorial_delta !== 0) {
      let sign = '';
      if (data.editorial_delta > 0) {
        //need to add + if positive delta...
        sign = '+';
      }
      return ` (${sign}${data.editorial_delta}%)`;
    }
    return ` (+0%)`;
  }
};

export const MatchType = ({ data }) => {
  if (/Databricks/.test(data.match_type)) {
    return (
      <TextWithIcon title={'Match score'}>
        <>
          {data.match_sentiment === 0 &&<AmberIcon />}
          {data.match_sentiment === 1 &&<GreenDotIcon />}
          {data.match_sentiment === -1 &&<CrossIcon style={{ fill: '#FF512D', width: 16, height: 16}} />}
          {' '}
          <MatchPercentileRoot>
            {data.score}%{editorial_delta(data)}
          </MatchPercentileRoot>
        </>
      </TextWithIcon>
    );
  }

  if (/keyword/i.test(data.match_type)) {
    return (
      <KeyWordMatch>
        <KeyIcon title="keyword match" />
      </KeyWordMatch>
    );
  }
  return null;
};
