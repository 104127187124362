import * as React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';

import { PublicationSelector } from './PublicationSelector';
import DateRangeFilter from './common/DateRangeFilter';
import ButtonGroup from './common/ButtonGroup';
import MultiSearchInput from './common/MultiSearchInput';
import { styled } from '@mui/material/styles';

const InCludeExternalToggle = styled('div')`
  position: absolute;
  top: 100%;
  right: 0;
`;

export default function PageToolbar({
  setSearchTye,
  setVideoStatus,
  videoStatus,
  setDateRange,
  filterByDate,
  includeExternal,
  setIncludeExternal,
}) {
  return (
    <Toolbar style={{ paddingBottom: '45px', paddingLeft: '0', paddingRight: '0' }}>
      <Grid item sx={{ flex: 1, marginRight: 1, position: 'relative' }}>
        <MultiSearchInput setSearchTye={setSearchTye} />
        <InCludeExternalToggle>
          <FormControlLabel
            sx={{ marginRight: 0 }}
            control={<Checkbox value={includeExternal} onChange={e => setIncludeExternal(e.target.checked)} />}
            label="Include External"
          />
        </InCludeExternalToggle>
      </Grid>
      <Grid item>
        <ButtonGroup
          videoStatus={videoStatus}
          setVideoStatus={setVideoStatus}
          selected={'all'}
          buttons={{ published: 'Published', matched: 'Matched', draft: 'Draft' }}
        />
      </Grid>
      <Grid item>
        <DateRangeFilter setDateRange={setDateRange} filterByDate={filterByDate} />
      </Grid>
      <Grid item>
        <PublicationSelector />
      </Grid>
    </Toolbar>
  );
}
