import TablePagination from '@mui/material/TablePagination';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

type PaginationTableProps = {
  count: number,
  onChange: (page: number) => void,
  updateRowPerPage: (page: number) => void,
  startPage: number,
  busy?: boolean,
  defaultSize: number,
}

const Root = styled('div')`
  position: relative;
`;

const Overlay = styled('div')`
  top: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.3;
`;

export default function PaginationTable(props: PaginationTableProps) {
  const itemPerPageOption = [20, 50, 100];
  const [selectedPage, setSelectedPage] = useState(props.startPage || 0);
  const [rowPerPage, setRowPerPage] = useState(itemPerPageOption.find(x => x === props.defaultSize) || 20);

  useEffect(() => {
    if (props.startPage !== selectedPage) {
      setSelectedPage(props.startPage);
    }
  }, [props.startPage, selectedPage]);

  return <Root>
    <TablePagination
      SelectProps={{
        disabled: props.busy
      }}
      component="div"
      count={props.count}
      page={selectedPage}
      onPageChange={(_, x) => {
        setSelectedPage(x);
        props.onChange(x);
      }}
      rowsPerPage={rowPerPage}
      rowsPerPageOptions={itemPerPageOption}
      onRowsPerPageChange={(event) => {
        const total = parseInt(event.target.value);
        setRowPerPage(total);
        setSelectedPage(0); // always reset the start number
        props.updateRowPerPage(total);
      }}
    />
    { props.busy && <Overlay /> }
  </Root>;
}
