import ReactPlayer from 'react-player';

export const PixelsVideoPlayer = ({ video, playerKey, format }) => {
  const width = format === 'vertical' ? '75%' : '100%';
  const height = format === 'vertical' ? '629.33px' : '265px';
  return (
    <ReactPlayer
      key={playerKey}
      url={`https://stream.mux.com/${video.media_id ?? video.video_id}.m3u8`}
      controls
      width={width}
      height={height}
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
    />
  );
};
