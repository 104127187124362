import { ListItemButton, ListItemIcon } from '@mui/material';
import { SidebarLink } from '../common/TextLabel';

export default function MenuItem({ children = <></>, open, path, end = false, label }) {
  return (
    <SidebarLink to={path} end={end} className={navData => (navData.isActive ? 'active' : 'inactive')}>
      <ListItemButton>
        <ListItemIcon>{children}</ListItemIcon>
        {open && label}
      </ListItemButton>
    </SidebarLink>
  );
}
