import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel, MenuItem, OutlinedInput,
  Radio,
  RadioGroup, Select,
  TextField
} from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import { getApiBaseUrl } from '../../utils/env';
import { useEffect, useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import { TableCellBody, TableCellHead, TableRow } from '../common/Table';
import TableBody from '@mui/material/TableBody';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      maxWidth: 250,
    },
  },
};

function getStyles(name: string, codes: string[], theme: Theme) {
  return {
    fontWeight:
      codes.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : 'bold',
  };
}

export const PublisherDetailTab3 = ({ data, onChageData, orgSlug, setData }) => {
  const theme = useTheme();

  const [organisations, setOrganisations] = useState([]);

  useEffect(() => {
     axios.get(`${getApiBaseUrl()}/organisation/list?isDemo=false`).then(({data}) => setOrganisations(data.filter(({ domain }) => domain !== orgSlug)));
  }, [orgSlug]);

  const selectedIds = data.rightsholders.map(({ id }) => id)

  return (<Grid container spacing={2}>
    <Grid item xs={12}>
      <h3>Rights holders</h3>
    </Grid>
    <Grid item xs={12}>
      <FormControl fullWidth>
        <InputLabel id="multiple-country-inclusion">Assign rights holders</InputLabel>
        <Select
          labelId="multiple-country-inclusion"
          id="multiple-country-inclusion-selection"
          label={"Assign rights holders"}
          multiple
          value={selectedIds}
          onChange={({ target: { value }}) => {
            setData({
              ...data,
              rightsholders: organisations.filter(({ id }) => value.includes(id)).map(({ domain, id}) => ({
                domain, id, priority: 0
              }))
            })
          }}
          input={<OutlinedInput label=">Assign rights holders"/>}
          MenuProps={MenuProps}
        >
          {organisations.map(({ id, name }) => (
            <MenuItem
              key={id}
              value={id}
              style={getStyles(id, selectedIds, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      { data.rightsholders.length > 0 && <Table sx={{ marginTop: 2}}>
        <TableHead>
          <TableRow>
            <TableCellHead>Rights holder</TableCellHead>
            <TableCellHead>Priority</TableCellHead>
            <TableCellHead>Action</TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.rightsholders
            .filter(({ domain }) => domain !== orgSlug)
            .map(({ domain, priority }) => (<TableRow key={domain}>
            <TableCellBody>{domain}</TableCellBody>
            <TableCellBody><Select value={priority} onChange={({ target: { value }}) => {
              setData({
                ...data,
                rightsholders: data.rightsholders.map((selectedOrg) => {
                  if (selectedOrg.domain === domain) {
                    return {
                      ...selectedOrg,
                      priority: value
                    }
                  }
                  return selectedOrg
                })
              })
            }}>
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
            </Select></TableCellBody>
              <TableCellBody><Button onClick={() => {
                setData({
                ...data,
                rightsholders: data.rightsholders.filter((selectedOrg) => selectedOrg.domain !== domain)
              })
              }}>Remove</Button></TableCellBody>
          </TableRow>))}
        </TableBody>
      </Table>}
    </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent={'space-between'} flexDirection={'row-reverse'}>
          <Grid item>
            <Button color="primary" variant="contained" type={'submit'}>Submit</Button>
          </Grid>
        </Grid>
      </Grid>
    <Grid item xs={12}>
      <h3>Scrape</h3>
    </Grid>
    {orgSlug && <Grid item xs={12}>
      <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group"
                  row>
        <FormControlLabel
          value={'sitemap'}
          control={<Radio checked={data.scrape_type === 'sitemap'}
                          onChange={onChageData('scrape_type')}/>}
          label="Scrap by sitemap"
        />
        <FormControlLabel
          value={'section'}
          control={<Radio checked={data.scrape_type === 'section'}
                          onChange={onChageData('scrape_type')}/>}
          label="Scrap by section"
        />
      </RadioGroup>
    </Grid>}
    {data.scrape_type === 'sitemap' && <>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            fullWidth
            name="rss_feed_url"
            value={data.rss_feed_url}
            label="RSS Feed URL"
            variant="outlined"
            placeholder={'eg. https://www.examplenews.co.uk/sitemap/rss'}
            onChange={onChageData('rss_feed_url')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            type={'number'}
            fullWidth
            value={data.crawl_article_total}
            name="crawl_article_total"
            label="Total article to crawl"
            variant="outlined"
            onChange={onChageData('crawl_article_total')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            type={'number'}
            fullWidth
            value={data.crawl_article_size_per_minute}
            name="crawl_article_size_per_minute"
            label="Total article to crawl per minute "
            variant="outlined"
            onChange={onChageData('crawl_article_size_per_minute')}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            fullWidth
            name="video_tag_patterns"
            value={data.video_tag_patterns}
            label="Hero video selector"
            placeholder={'Comma separated css selector'}
            variant="outlined"
            onChange={onChageData('video_tag_patterns')}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            fullWidth
            name="image_tag_pattern"
            value={data.image_tag_pattern}
            label="Hero image selector"
            placeholder={'Comma separated css selector'}
            variant="outlined"
            onChange={onChageData('image_tag_pattern')}
          />
        </FormControl>
      </Grid>

      {orgSlug && <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            type={'number'}
            fullWidth
            name="crawl_current_article_count"
            value={data.crawl_current_article_count}
            label="Current article count"
            placeholder={'Reset the total number of article to be scrapped'}
            variant="outlined"
            onChange={onChageData('crawl_current_article_count')}
          />
        </FormControl>
      </Grid>}
    </>}

    {orgSlug && data.scrape_type === 'section' && <>
      <Grid item xs={12}>
        <p>This feature will scrap the whole article by given section(s) in one go. There is no
          concept of throttling like the scrap by sitemap.</p>
        <p>The article that has been scrapped, can not be scrapped again for at least in the next 48
          hrs.</p>
        <FormControl fullWidth>
          <TextField
            fullWidth
            onChange={onChageData('scrape_sections')}
            label="section (separate by comma)"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            fullWidth
            name="video_tag_patterns"
            value={data.video_tag_patterns}
            label="Hero video selector"
            placeholder={'Comma separated css selector'}
            variant="outlined"
            onChange={onChageData('video_tag_patterns')}
          />
        </FormControl>
      </Grid>
    </>
    }
  </Grid>);
};