import { styled } from '@mui/material/styles';
import { Paper, TableRow } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { TableCellBody, TableCellHead } from '../common/Table';
import Box from '@mui/material/Box';
import * as React from 'react';
import PlaylistRefreshedDate from '../PlaylistRefreshedDate';

const PropertyRoot = styled(Paper)(({ theme }) => ({
  width: 600,
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
  ...theme.typography.body2,
}));

export default function PlaylistProperties({ currentPlaylist}) {
  return <PropertyRoot variant={'outlined'}>
    {currentPlaylist && <Table size="small">
      <TableBody>
        <TableRow>
          <TableCellHead>
            Playlist type
          </TableCellHead>
          <TableCellBody>
            {currentPlaylist.playlist_type_name}
          </TableCellBody>
        </TableRow>
        { currentPlaylist.playlist_type_id === 1 && <>
        <TableRow>
          <TableCellHead>
            Last refresh
          </TableCellHead>
          <TableCellBody>
            <PlaylistRefreshedDate lastRefreshed={currentPlaylist.last_refreshed} refreshRateInMins={currentPlaylist.refresh_rate_in_mins} />
          </TableCellBody>
        </TableRow>
        <TableRow>
          <TableCellHead>
            Refresh rate
          </TableCellHead>
          <TableCellBody>
            {Math.floor(currentPlaylist.refresh_rate_in_mins / 60)} hour{currentPlaylist.refresh_rate_in_mins > 60 ? 's' : ''}
          </TableCellBody>
        </TableRow>
        <TableRow>
          <TableCellHead>
            Format
          </TableCellHead>
          <TableCellBody>
            {currentPlaylist.format}
          </TableCellBody>
        </TableRow>
        <TableRow>
          <TableCellHead>
            Article source
          </TableCellHead>
          <TableCellBody>
            {currentPlaylist.playlist_publisher.length > 0 &&
              <Box>{currentPlaylist.playlist_publisher.join(', ')} ({currentPlaylist.playlist_publisher_exclude ? 'Excluded' : 'Included'})</Box>}
            {currentPlaylist.playlist_publisher.length === 0 && <Box>None</Box>}
          </TableCellBody>
        </TableRow>
        <TableRow>
          <TableCellHead>
            IAB category
          </TableCellHead>
          <TableCellBody>
            {currentPlaylist.iab_categories.length > 0 && <Box>
              {currentPlaylist.iab_categories.join(', ')} ({currentPlaylist.iab_categories_exclude ? 'Excluded' : 'Included'})
            </Box>}
            {currentPlaylist.iab_categories.length === 0 && <Box>None</Box>}
          </TableCellBody>
        </TableRow>
        <TableRow>
          <TableCellHead>
            Geo country
          </TableCellHead>
          <TableCellBody>
            {currentPlaylist.playlist_geo_country.length > 0 &&
              <Box> {currentPlaylist.playlist_geo_country.join(', ')} ({currentPlaylist.playlist_geo_country_exclude ? 'Excluded' : 'Included'})</Box>}
            {currentPlaylist.playlist_geo_country.length === 0 && <Box>None</Box>}
          </TableCellBody>
        </TableRow>
        </>}
        <TableRow>
          <TableCellHead>
            Rightsholders
          </TableCellHead>
          <TableCellBody>
            {currentPlaylist.rightsholders.length > 0 ? <Box>{currentPlaylist.rightsholders.join(', ')}</Box> : <Box>None</Box>}
          </TableCellBody>
        </TableRow>
      </TableBody>
    </Table>}
  </PropertyRoot>;
}