import { Button, ButtonGroup } from '@mui/material';
import Box from "@mui/material/Box";
import { styled } from '@mui/material/styles';

const CustomButton = styled(Button)`
 font-family: 'ProximaNovaSemiBold';
 text-transform: capitalize;
 &.MuiButton-outlined {
 }
 &.MuiButton-contained {
  color: #fff;
 }
`;

const CustomButtonGroup = ({ buttons, videoStatus, setVideoStatus }: { setVideoStatus: (x: string) => void, videoStatus: string, selected: string, buttons: Record<string, string>}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
          m: 1,
          height: 41,

        },
      }}
    >
      <ButtonGroup color={'primary'} size="large" aria-label="large button group">
        {Object.entries(buttons).map(([k, v]) => (<CustomButton
          variant={videoStatus === k ? 'contained' : 'outlined'}
          sx={{ boxShadow: 0 }}
          size={'large'}
          key={k}
          onClick={() => setVideoStatus(videoStatus === k ? 'all' : k)}
        >
          {v}
        </CustomButton>))}
      </ButtonGroup>
    </Box>
  );
}

export default CustomButtonGroup;
