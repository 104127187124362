import MenuItem from './MenuItem';
import { ReactComponent as EngageIcon } from '../../icons/ico-image_swap.svg';
import { ReactComponent as PerformanceIcon } from '../../icons/performance.svg';

export default function DemoMenu({ open, isAdmin }) {
  return (
    <>
      {isAdmin && (
        <MenuItem open={open} path="/video-swap" label="Fast Match (demo)">
          <EngageIcon />
        </MenuItem>
      )}
      <MenuItem open={open} path="/calculator" label="Calculator">
        <PerformanceIcon />
      </MenuItem>
    </>
  );
}

export function DefaultSection() {
  return '/calculator';
}
