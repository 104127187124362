import {
  Checkbox,
  FormControl,
  Grid,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextInputWithSideLabel, {
  BaseButton,
  InputLabel,
  InputText,
} from '../common/Form';
import { useContext, useEffect, useState } from 'react';
import { CalculatorContext } from '../../context/calculator/CalculatorProvider';
import { formatNumberWithComma, numberWithCommas, numberWithoutComma, updateState } from './utils';
import { BlueGradientText } from '../common/PublicTypography';

const Root = styled('div')`
`;

const BoxRoot = styled(Box)`
  ${props => props.theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(2.5)};
  }

  font-size: 18px;
`;

const BoxInner = styled(Box)`
  padding: ${({ theme }) => theme.spacing(2.5)};
`;

const BlueBox = styled(BoxRoot)`
`;

export const TextSizeXXL = styled(BlueGradientText)`
  font-size: 66px;
  line-height: 1;
  word-break: break-word;
  color: #214BDE;

  &.inactive {
    color: #D5DAE3;
    background: none;
    background-clip: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
  }

  &.long-txt {
    font-size: 56px;
  }

  &.super-long-txt {
    font-size: 46px;
  }

  ${props => props.theme.breakpoints.up('md')} {
    font-size: 90px;

    &.long-txt, &.super-long-txt {
      font-size: 76px;
    }
  }
`;

export const TextSizeXL = styled(TextSizeXXL)`
  font-size: 28px;
  line-height: 1.4;
  margin-top: 10px;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: 28px;
  }
`;

const TextRegular = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
`;

const ButtonGroupItem = ({ children, defaultValue, selected, onSelect, className }) => (
  <BaseButton
    className={className}
    onClick={() => onSelect(defaultValue)}
    variant={selected === defaultValue ? 'contained-x' : 'outlined-x'}
    fullWidth>{children}</BaseButton>
);

const StyledButtonGroupItem = styled(ButtonGroupItem)`
  padding: 3.5px 0;
  line-height: 43px;
  box-sizing: border-box;
`;

ButtonGroupItem.propTypes = {
  defaultValue: PropTypes.number,
  selected: PropTypes.number,
  onSelect: PropTypes.func,
  className: PropTypes.string,
};

const AdvancedControlBox = styled(Box)`
  padding: 20px;
  background-color: #F7F7F7;
  border-radius: 6px;
`;

const InputRootGrey = styled(Box)`
  padding: ${({ theme }) => theme.spacing(1)};
  background-color: #F7F7F7;
  border-radius: 6px;
  display: flex;
  flex: 1;
`;

const ToggleBtn = styled('span')`
  text-decoration-line: underline;
  cursor: pointer;
  display: inline-block;
`;

const InputTextWhite = styled(InputText)`
  background-color: white;
`;

const LabelInputRow = styled(InputLabel)`
  margin: 0;
`;
const ConfettiRoot = styled(Box)`
  position: relative;

  img {
    position: absolute;
    bottom: -10px;
    transform: translateX(-50%);
    left: 50%;
    width: 300px;
    height: 300px;
  }
`;

const GridPaper = styled(Box)`
  border-radius: 9px;
  background: white;
`;

const PublicCalculator = ({
  formatCurrency,
}) => {
  const {
    calc: {
      monthlyPageviews, lostRevenue, imageToVideoRatioPercentage, fillRatePercentage,
      autoCpm, isProgrammatic, includeHeaderbidding, potentialVideoMatches,
    }, dispatch
  } = useContext(CalculatorContext);
  const update = updateState(dispatch);
  const [showAdvancedControl, setShowAdvancedControl] = useState(false);
  const [freeTrialEligible, setFreeTrialEligible] = useState(false);
  const [confettiCanBeShown, setConfettiCanBeShown] = useState(true);
  const lostRevenueText = numberWithCommas(formatCurrency(lostRevenue * 30));

  const classTextDecrease = (length) => {
    if (length >= 10) {
      return 'super-long-txt';
    }

    if (length >= 7) {
      return 'long-txt';
    }

    return '';
  };

  useEffect(() => {
    setFreeTrialEligible(numberWithoutComma(monthlyPageviews) >= 10000000);
  }, [monthlyPageviews]);

    useEffect(() => {
      setConfettiCanBeShown(freeTrialEligible);
      setTimeout(() => setConfettiCanBeShown(false), 2000)
  }, [freeTrialEligible]);

  return (<React.Fragment>
    <Root sx={{ mb: 4 }}>
      <BoxRoot>
        <BoxInner sx={{ pb: 0 }}>
          <Grid container spacing={2.5}>
            <Grid item xs={12} sm={6}>
              <TextInputWithSideLabel
                type={'text'}
                placeholder="10,000,000"
                value={monthlyPageviews}
                onChange={(val) => update('monthlyPageviews', formatNumberWithComma(val))}
                label={'Monthly page views'}
                sideLabel={'Views'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>% of pages missing video</InputLabel>
                <InputRootGrey>
                  <Grid container spacing={2}>
                    <Grid item xs={4}><StyledButtonGroupItem
                      onSelect={update('imageToVideoRatioPercentage')}
                      selected={imageToVideoRatioPercentage}
                      defaultValue={80}>80%</StyledButtonGroupItem></Grid>
                    <Grid item xs={4}><StyledButtonGroupItem
                      onSelect={update('imageToVideoRatioPercentage')}
                      selected={imageToVideoRatioPercentage}
                      defaultValue={60}>60%</StyledButtonGroupItem></Grid>
                    <Grid item xs={4}><StyledButtonGroupItem
                      onSelect={update('imageToVideoRatioPercentage')}
                      selected={imageToVideoRatioPercentage}
                      defaultValue={40}>40%</StyledButtonGroupItem></Grid>
                  </Grid>
                </InputRootGrey>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <AdvancedControlBox>
                <ToggleBtn
                  onClick={() => setShowAdvancedControl(!showAdvancedControl)}>{showAdvancedControl ? 'Hide' : 'Show'} advanced
                  control</ToggleBtn>
                <Box sx={{ display: showAdvancedControl ? 'block' : 'none', marginTop: 2 }}>
                  <span>Refine your calculations</span>
                  <Grid container alignItems={'center'} sx={{ paddingTop: 1 }}>
                    <Grid item sx={{ paddingRight: 1 }}>
                      <InputTextWhite type={'number'}
                                      onChange={({ target: { value } }) => update('fillRatePercentage', value)}
                                      value={fillRatePercentage} style={{
                        backgroundColor: 'white',
                        width: '70px',
                        textAlign: 'center'
                      }}/>
                    </Grid>
                    <Grid item sx={{ paddingRight: 2 }}>
                      <LabelInputRow>% Fill</LabelInputRow>
                    </Grid>
                    <Grid item sx={{ paddingRight: 2 }}>
                      <Checkbox id={'headerbidding'} checked={includeHeaderbidding}
                                onClick={() => update('includeHeaderbidding', !includeHeaderbidding)}/>
                      <LabelInputRow htmlFor={'headerbidding'}>Header-bidding</LabelInputRow>
                    </Grid>
                    <Grid item>
                      <Checkbox id={'directsales'} checked={!isProgrammatic}
                                onClick={() => update('isProgrammatic', !isProgrammatic)}/>
                      <LabelInputRow htmlFor={'directsales'}>Direct sales</LabelInputRow>
                    </Grid>
                  </Grid>
                </Box>
              </AdvancedControlBox>
            </Grid>
          </Grid>
        </BoxInner>
      </BoxRoot>
      <BlueBox>
        <BoxInner>
          <Grid container rowSpacing={2} columnSpacing={4}>
            <Grid id={'revenueBox'} item xs={12} alignItems={'center'}>
              <GridPaper sx={{ pt: 5, pb: 4 }}>
                <TextSizeXXL
                  className={[classTextDecrease(String(lostRevenueText).length), monthlyPageviews ? 'active' : 'inactive'].join(' ')}
                  textAlign={'center'} variant={'body1'}>
                  {!isNaN(potentialVideoMatches) && numberWithCommas(Math.floor(potentialVideoMatches))}
                </TextSizeXXL>
                <TextSizeXL textAlign={'center'} variant={'body1'}>Extra Plays per
                  Month</TextSizeXL>
              </GridPaper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <GridPaper sx={{ pt: 2, pb: 2 }}>
                <TextSizeXL className={monthlyPageviews ? 'active' : 'inactive'}
                            textAlign={'center'}>{lostRevenueText}</TextSizeXL>
                <TextRegular textAlign={'center'}>Estimated Monthly Revenue</TextRegular>
              </GridPaper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <GridPaper sx={{ pt: 2, pb: 2 }}>
                <TextSizeXL className={monthlyPageviews ? 'active' : 'inactive'}
                            textAlign={'center'}
                            variant={'body1'}>${autoCpm}</TextSizeXL>
                <TextRegular textAlign={'center'} variant={'body1'}>Average CPM</TextRegular>
              </GridPaper>
            </Grid>
          </Grid>
          {confettiCanBeShown && <ConfettiRoot>
            <img src={'/v2/confetti.gif'} alt={'congratulation, you are eligible for free trial'}/>
          </ConfettiRoot>}
        </BoxInner>
      </BlueBox>
    </Root>
  </React.Fragment>);
};

PublicCalculator.propTypes = {
  formatCurrency: PropTypes.func,
  selectedPublisher: PropTypes.string,
  publishers: PropTypes.array,
  setPublisher: PropTypes.func
};

export default PublicCalculator;
