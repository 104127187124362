import { GlobalStyles } from '@mui/material';
import Helmet from 'react-helmet';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import PublicLayout from './PublicLayout';
import ContactForm from '../contact/ContactForm';

const ContactUs = styled(ContactForm)`
  margin-bottom: 75px;
`

function Contact({ className }) {
  return (
    <PublicLayout className={className}>
      <GlobalStyles styles={{
        body: {
          backgroundColor: '#274bde',
          backgroundImage: 'url("/v2/pixels-bg.svg")',
          backgroundPosition: 'top left',
          backgroundRepeat: 'repeat-x',
        }
      }} />
      <Helmet title="Pixels AI - Contact Us">
        <meta name="description" content="Unlock the full potential of instream video with our expert guidance. Contact us today and let our team help you maximise your video revenue, optimise your instream video strategy, and grow your audience. Get the support you need to take your video business to the next level - contact us now!"/>
      </Helmet>
      <ContactUs legacy />
    </PublicLayout>
  );
}

Contact.propTypes = {
  className: PropTypes.string
}

export default styled(Contact)`
  header {
    background-color: transparent;
  }
`;
