import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const Menu = ({ onClick, className }) => (
  <svg
    onClick={onClick}
    className={className}
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1.70264H19M1 7.70264H19M1 13.7026H19"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Menu.propTypes = {
  className: PropTypes.string,
};

export default styled(Menu)`
  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
`;
