import { useDispatch } from 'react-redux';
import { Grid, SvgIcon } from '@mui/material';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { PublicationSelector } from './PublicationSelector';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

const IABList = styled('h2')`
margin: 0;
    font-weight: bold;
`


function ToolBarPlaylist({
  currentPlaylist
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: 'SET_FILTER_FORMAT', payload: 'vertical' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Toolbar style={{ paddingBottom: '45px', paddingLeft: '15px', flexDirection: 'column', alignItems: 'flex-start' }}>
      <Grid container alignItems="center" justifyContent={currentPlaylist ? 'space-between' : 'flex-end'}>
        { currentPlaylist && <>
        <Grid item>
          <Grid container>
            <Grid item marginRight={'20px'}><SvgIcon titleAccess="Go back" sx={{ cursor: 'pointer'}} onClick={() => navigate('/dashboard/playlist')}><ArrowBackIos /></SvgIcon></Grid>
            <Grid item>{currentPlaylist.name}</Grid>
          </Grid>
        </Grid>

        </>}
        <Grid item>
          <PublicationSelector
          />
        </Grid>
      </Grid>

       {currentPlaylist && currentPlaylist.playlist_type_id === 1 && currentPlaylist.iab_categories.length > 0 && <Box>
         { !currentPlaylist.iab_categories_exclude && <IABList>{currentPlaylist.iab_categories.join(', ')}</IABList>}
      </Box>}
    </Toolbar>
  );
}

export default styled(ToolBarPlaylist)`
    margin-bottom: 40px;
`;
