import { Box, GlobalStyles } from '@mui/material';
import Helmet from 'react-helmet';

import '../../App2.css';
import './PublicLayout.css';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import Footer2 from './component/Footer2';

const Root = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: initial;
  height: 100%;
  margin: 0 auto;
  min-height: 100vh;
`;

function PublicLayout2({ children, className, page }) {
  return (
    <Root className={className}>
      <GlobalStyles
        styles={theme => {
          return {
            body: {
              backgroundColor: '#0D2B7C',
              color: '#fff',
            },
          };

          // if (page === 'home') {
          //   return {
          //     body: {
          //       // backgroundColor: '#2655E3',
          //       backgroundColor: '#0D2B7C',
          //       color: '#fff',
          //       [theme.breakpoints.down('lg')]: {
          //         // backgroundImage: 'url("/v2/pixels-bg.svg")',
          //         // backgroundPosition: 'top center',
          //         // backgroundRepeat: 'repeat-x',
          //       },
          //     },
          //   };
          // }

          // return {
          //   body: {
          //     // backgroundColor: '#2655E3',
          //     backgroundColor: '#0D2B7C',
          //     color: '#fff',
          //     // backgroundImage: 'url("/v2/pixels-bg.svg")',
          //     // backgroundPosition: 'top center',
          //     // backgroundRepeat: 'no-repeat'
          //   },
          // };
        }}
      />
      <Helmet>
        <meta charSet="UTF-8" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-1BYPDLFT5S" />
        <script type="text/javascript">
          {`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-1BYPDLFT5S');`}
        </script>
      </Helmet>
      {children}
      <Footer2 />
    </Root>
  );
}

PublicLayout2.propTypes = {
  className: PropTypes.string,
  page: PropTypes.string,
};

export default styled(PublicLayout2)``;
