import ImageSwapDemo from '../ImageSwapDemo';
import PublisherDetail from '../PublisherDetail';
import Publishers from '../Publishers';
import PublishersDemoArticles from '../PublishersDemoArticles';
import PublishersDemoFMResult from '../PublishersDemoFMResult';
import PublishersDemoVideos from '../PublishersDemoVideos';
import UserAdd from '../UserAdd';
import UserEdit from '../UserEdit';
import Users from '../Users';

export default function AdminSections({ section, isDemo }) {
  return (
    <>
      {isDemo && section === 'video-swap' && (
        <ImageSwapDemo  />
      )}
      {section === 'publishers' && (
        <Publishers />
      )}
      {/publishers-(add|edit)/.test(section) && <PublisherDetail />}
      {section === 'publishers-articles' && <PublishersDemoArticles />}
      {section === 'publishers-videos' && <PublishersDemoVideos />}
      {section === 'publishers-fast-match-result' && <PublishersDemoFMResult />}
      {section === 'user-add' && <UserAdd />}
      {section === 'user-edit' && <UserEdit />}
      {section === 'users' && <Users />}
    </>
  );
}
