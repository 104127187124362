import { AxiosInstance } from "axios";
import { IArticleData } from "./articleInterface";
import { useAsync, UseAsyncOptions } from "react-async-hook";
import { getApiBaseUrl } from '../utils/env';

export interface ArticleClient {
  getArticleData: () => Promise<IArticleData>;
  sendVideoMatch: (
    riskObject: unknown,
    tobesState: string
  ) => Promise<any>;
}

export const createArticleClient = (
  getInstance: () => Promise<AxiosInstance>
): ArticleClient => ({
  getArticleData: async () => {
    const instance = await getInstance();
    const response = await instance.get<IArticleData>(
      "standard/article-match/"
    );
    try {
      return response.data;
    } catch (err) {
      console.log(err);
      throw new Error("An error occured while fetching article match Data");
    }
  },
  sendVideoMatch: async (risk) => {
    const instance = await getInstance();
    const response = await instance.post<{ data: any }>(
      `${getApiBaseUrl()}/{publisher slug}/article-match/{id of article}/`,
      {
        risk,
      },
      {
        params: {
          confirm: true,
        },
      }
    );
    return response.data.data;
  },
});

export function useErrorHandlingAsync<R = unknown, Args extends any[] = any[]>(
  asyncFunction: (...args: Args) => Promise<R>,
  params: Args,
  options?: UseAsyncOptions<R>
) {
  return useAsync(asyncFunction, params, options);
}
