import { Box, Grid, Typography, styled } from "@mui/material";
import { Logo, LogoAnchor, Menu, MenuItem } from "./common";

const Footer = styled('footer')`
  position: relative;
  background-color: #fff;
  color: #21316C;

  &:before {
    content: ' ';
    position: absolute;
    width: calc(100%);
    background-color: #F0F6FF;
  }
`;

const Copyright = styled(Typography)`
  font-size: 14px;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: 14px;
  }
`;

const FooterGrid = styled(Grid)`
  background-image: url("/v2/footer-pixels-mobile-left.svg"), url("/v2/footer-pixels-mobile-right.svg");
  background-position: top left, top right;
  background-repeat: no-repeat, no-repeat;
  background-color: #ebeffa;

  ${props => props.theme.breakpoints.up('lg')} {
    background-image: url("/v2/footer-pixels-desktop-left.svg"), url("/v2/footer-pixels-desktop-right.svg");
    background-position: top -68px left,top -68px right;
  }
`;

const FooterLogoContainer = styled(Box)`
  display: flex;
  justify-content: center;
  height: 88px;
  border-bottom: 1px solid #8b97be;

  ${props => props.theme.breakpoints.up('md')} {
    height: 62px;
    margin-bottom: 0;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    height: 100px;
  }
`;

const FooterMenu = styled(Menu)`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${props => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    gap: initial;
    justify-content: center;
  }
`;

const FooterMenuItem = styled(MenuItem)`
  position: relative;
  font-size: 14px;
  margin-left: 0;
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: 14px;
  }

  a {
    color: #21316C;
    padding-top: 0;
    padding-bottom: 0;

    ${props => props.theme.breakpoints.up('md')} {
      &:visited, &:active, &:hover {
        color: #21316C;
      }
    }
  }
`;

const Footer2 = () => {
  return (
    <Footer>
        <FooterGrid p={2} sx={theme => ({
          [theme.breakpoints.up('md')]: {
            pt: 0,
            pb: 5,
          }
        })} container flexDirection={'row'} alignItems={'center'}>
          <Grid item xs={12} marginBottom="20px">
            <FooterLogoContainer id='FooterLogoContainer'>
              <LogoAnchor href={'/'}>
                <Logo>Pixels AI</Logo>
              </LogoAnchor>
            </FooterLogoContainer>
          </Grid>
          <Grid item flex={1} display="flex" gap="20px" flexDirection={{ xs: "column", md: "row-reverse" }} alignItems="center" justifyContent={{md: 'space-between'}}>
            <Grid item>

              <FooterMenu>
                <FooterMenuItem><a href={'/contact/'}>Contact</a></FooterMenuItem>
                <FooterMenuItem><a href={'/privacy-policy/'}>Privacy Policy</a></FooterMenuItem>
              </FooterMenu>
            </Grid>
            <Grid item>
              <Copyright>&copy; {new Date().getFullYear()} Pixels AI Ltd. All rights reserved</Copyright>
            </Grid>
          </Grid>
        </FooterGrid>
      </Footer>
  )
};

export default Footer2;