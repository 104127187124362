import { styled } from '@mui/material/styles';
import { InputBase, Paper, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

export const InputLabel = styled('label')`
  margin-bottom: 10px;
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
`;

export const InputText = (props) => (<TextField
  inputProps={{
   style: {
     paddingLeft: 12,
     paddingRight: 12,
     paddingTop: 8,
     paddingBottom: 8,
     background: 'white'
   }
  }}
  {...props}
 />)

export const BaseButton = styled(Button)`
  font-size: 18px;
  text-transform: initial;
  ${props => props.theme.breakpoints.up('md')} {
    font-size: 22px;
  }
`;

const InputSideLabel = styled(Box)`
  font-size: 22px;
  padding: 8px 16px;
`;

const InputRoot = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Input = styled(InputBase)`
  font-size: 22px;
  flex: 1;
`


export default function TextInputWithSideLabel({ label, sideLabel, value, onChange, type, placeholder }) {
  return (
    <InputRoot>
      <InputLabel>{label}</InputLabel>
      <Paper
        elevation={0}
        component="div"
        sx={{ display: 'flex', alignItems: 'center', flex: 1, border: '1px solid #E0E0E0' }}
      >
      <Input
        type={type || 'text'}
        placeholder={placeholder}
        value={value}
        sx={({ breakpoints }) => ({
          ml: 1,
          [breakpoints.down('md')]: {
            height: '52px'
          }
        })}
        onChange={({ target: { value }}) => onChange(value)}
        inputProps={{ 'aria-label': '' }}
      />
      <Divider sx={{ height: '100%', m: 0.5 }} orientation="vertical" />
      <InputSideLabel>{sideLabel}</InputSideLabel>
    </Paper>
    </InputRoot>
  );
}