import { Box, Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { TextHighlighted } from "../common/PublicTypography";
import { ReactComponent as PlusIcon } from '../../icons/plus2.svg';
import { faqsData } from './faqs-data';

const HeaderTwo = styled(Typography)`
  color: #112671;
  font-size: 32px;

  ${props => props.theme.breakpoints.up('md')} {
    text-align: center;
  }
`;

const Question = styled(Typography)`
  color: #112671;
  font-size: 20px;
  line-height: 1.2;
`;

const TextHighlightedWhiteText = styled(TextHighlighted)`
  color: #fff;
`;

const AccordionSetWrapper = styled(Box)`
`;

const AccordionDetailsCustom = styled(AccordionDetails)`
  background-color: #ebf1ff;
`;


const Faqs = () => {
    return (
        <Grid display="flex" flexDirection="column" gap="40px">
          <HeaderTwo variant={'h2'}>Frequently Asked <TextHighlightedWhiteText>Questions</TextHighlightedWhiteText></HeaderTwo>
          <AccordionSetWrapper>
            {faqsData.map((faq, index) => (
              <Accordion key={faq.question} sx={{ '&:before': { display: 'none' } }}>
                <AccordionSummary aria-controls={`panel-${index}-content`} expandIcon={<PlusIcon />}>
                  <Question>{faq.question}</Question>
                </AccordionSummary>
                <AccordionDetailsCustom>
                  <Typography>{faq.answer}</Typography>
                </AccordionDetailsCustom>
              </Accordion>
            ))}
          </AccordionSetWrapper>
        </Grid>
    );
}

export default Faqs;