import {
  FormControl,
  MenuItem,
  Select,
  SvgIcon
} from '@mui/material';
import { ReactComponent as CalendarIcon } from '../../icons/calendar-blue.svg';
import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const options = {
  '1day': 'last 24 hours',
  '3days': '3 day trend',
  '7days': '7 day trend',
  '30days': '30 day trend'
};

const RootText = styled(Box)`
  display: flex;
  gap: 1; 
  min-height: 24px;
  font-family: 'ProximaNovaSemiBold';
  color: rgba(0, 0, 0, 0.87);
  align-items: center;
  min-width: 120px;
  > svg {
    padding-right: 6px;
  }
`

const DateRangeFilter = ({ filterByDate, setDateRange }) => {
  return (
    <FormControl sx={{ m: 1, minHeight: 42, minWidth: 140 }} size="small">
      <Select
        margin={'dense'}
        value={filterByDate}
        onChange={(e) => setDateRange(e.target.value)}
        displayEmpty
        renderValue={(value) => {
          return (
            <RootText>
              <SvgIcon fontSize={'small'} color="primary">
                <CalendarIcon/>
              </SvgIcon>
              {options[value]}
            </RootText>
          );
        }}
      >
        {Object.entries(options).map(([k, v]) => <MenuItem key={k} value={k}>{v}</MenuItem>)}
      </Select>
    </FormControl>
  );
}

export default DateRangeFilter;
