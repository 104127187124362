import * as React from 'react';
import TableHead from '@mui/material/TableHead';
import { TableCellBody, TableCellHead, TableRow } from '../common/Table';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import { PlaylistCampaignDeliveryArticle } from '../types/playlist';
import { Grid, MenuItem, Select } from '@mui/material';
import { Link } from '../common/Link';

export const PlaylistCampaignDeliveryList = ({
  articles, onDateRangeChange, dateRange
}: {
  articles: PlaylistCampaignDeliveryArticle[],
  onDateRangeChange: (x: string) => void,
  dateRange: string
}) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCellHead sx={{ whiteSpace: 'nowrap' }}>PAGE IMPRESSION</TableCellHead>
          <TableCellHead>THUMBNAIL</TableCellHead>
          <TableCellHead sx={{ minWidth: '280px' }}><Grid container alignItems={'center'} justifyContent={'space-between'}>
            <Grid item>URL</Grid>
            <Grid item>
              <Select
              id="demo-simple-select"
              value={dateRange}
              // defaultValue={'1day'}
              onChange={({ target: { value }}) => onDateRangeChange(value)}
              sx={{
                fontFamily: 'ProximaNovaBold',
                textTransform: 'none',
                color: '#000',
                height: '43px',
                width: '200px',
                marginRight: '30px',
              }}
            >
              <MenuItem key={'1'} value={'1day'}>
                last 24 hours
              </MenuItem>
              <MenuItem key={'2'} value={'yesterday'}>
                yesterday
              </MenuItem>
              <MenuItem key={'3'} value={'7days'}>
                previous 7 days
              </MenuItem>
            </Select>
            </Grid>
          </Grid>
          </TableCellHead>
        </TableRow>
      </TableHead>
      <TableBody>
        {articles.length === 0 && (
          <TableRow>
            <TableCellBody colSpan={9} width={'100%'}>
              <div style={{ textAlign: 'center', padding: 20 }}>This playlist contains no delivery
                yet
              </div>
            </TableCellBody>
          </TableRow>
        )}
        {articles.length > 0 &&
          articles.map(row => (
            <TableRow
              sx={{
                height: '60px',
              }}
              key={row.page_url}
            >
              <TableCellBody align="center">{row.count.toLocaleString()}</TableCellBody>
              <TableCellBody sx={{ width: '104px' }}>
                <div
                  style={{
                    backgroundImage: `url('/bg-grey.png')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '5%',
                    aspectRatio: '4 / 3',
                    position: 'relative'
                  }}
                ></div>
              </TableCellBody>
              <TableCellBody>
                <Link href={row.page_url} target="_blank">{row.page_url}</Link>
              </TableCellBody>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};
