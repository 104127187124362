import {
  FormControl,
  InputBase, Menu,
  MenuItem,
  Select,
  SvgIcon
} from '@mui/material';
import { ReactComponent as PlayIcon } from '../../icons/play.svg';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';

const options = {
  'videos': {
    label: 'Videos',
    placeholder: 'Search videos..'
  },
  'section': {
    label: 'Section',
    placeholder: 'Search section..'
  },
  'topic_source': {
    label: 'Topic Source',
    placeholder: 'Search by topic source..'
  },
  'keywords': {
    label: 'Topics',
    placeholder: 'Search by topic..'
  },
  'ranking': {
    label: 'Ranking',
    placeholder: 'Search by ranking status..'
  }
};

const RootText = styled(Box)`
  display: flex;
  gap: 1; 
  min-height: 24px;
  font-family: 'ProximaNovaSemiBold';
  color: rgba(0, 0, 0, 0.87);
  align-items: center;
  min-width: 120px;
  > svg {
    padding-right: 6px;
  }
`;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: '4px 0 0 4px',
    backgroundColor: 'rgba(51, 85, 209, .2)',
    border: '0',
    fontSize: 16,
    padding: '10px 26px 8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
    },
  },
}));

const InputText = styled(InputBase)(({ theme }) => ({
  'label + &': {
  },
  '& .MuiInputBase-input': {
    fontSize: 16,
    padding: 0,
  },
}));

const DateRangeFilter = ({ setSearchTye }) => {
  const [typeOfSearch, setTypeOfSearch] = useState('videos');
  const [selectedText, setSelectedText] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    switch (typeOfSearch) {
      case 'ranking':
        return setAnchorEl(event.currentTarget);
      case 'topic_source':
        return setAnchorEl(event.currentTarget);
      default:
        return;
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setSelectedText('')
  }, [typeOfSearch]);

  return (
    <Box
      sx={{
        p: 0, display: 'flex', alignItems: 'center',
        border: '1px solid #ced4da',
        borderRadius: '4px'
    }}
    >
      <FormControl sx={{ minHeight: 42 }} size={'small'}>
        <Select
          sx={{
            border: 0,
            borderRadius: '0'
          }}
          margin={'dense'}
          value={typeOfSearch}
          size={'small'}
          onChange={(e) => setTypeOfSearch(e.target.value)}
          displayEmpty
          input={<BootstrapInput />}
          renderValue={(value) => {
            return (
              <RootText>
                <SvgIcon fontSize={'small'} color="primary">
                  <PlayIcon />
                </SvgIcon>
                {options[value].label}
              </RootText>
            );
          }}
        >
          {Object.entries(options).map(([k, { label }]) => <MenuItem key={k} value={k}>{label}</MenuItem>)}
        </Select>
      </FormControl>
      <Divider orientation={'vertical'} />
      <FormControl sx={{ flex: 1, minHeight: 42 }} size={'small'}>
        <InputText
          sx={{ ml: 1, flex: 1, fontSize: 16 }}
          placeholder={options[typeOfSearch].placeholder}
          inputProps={{ 'aria-label': options[typeOfSearch].placeholder }}
          onClick={handleClick}
          onChange={(e) => setSelectedText(e.target.value)}
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.keyCode === 13 && typeOfSearch === 'keywords') {
              setSearchTye([typeOfSearch, selectedText])
            }
          }}
          value={selectedText}
        />
      </FormControl>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={{ width: 400 }}
      >
        { typeOfSearch === 'ranking' && [
          'Ranking', 'Lost Ranking', 'Not ranking', 'Has ranked'
        ].map((item) => (<MenuItem onClick={() => {
          setSelectedText(item)
          handleClose();
        }}>{item}</MenuItem>))}

        { typeOfSearch === 'topic_source' && [
          'Trends', 'News', 'Others'
        ].map((item) => (<MenuItem onClick={() => {
          setSelectedText(item.toLowerCase())
          handleClose();
        }}>{item}</MenuItem>))}
      </Menu>
    </Box>
  );
};

export default DateRangeFilter;
