import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, LinearProgress } from '@mui/material';
import PaginationTable from './Pagination';
import { Fragment, useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { apiVideoLibraryProvider } from '../store/videoLibrarySlice';
import { VideoSinglePreview } from './modal/VideoSinglePreview';
import ToolBarVideoLibrary from './ToolBarVideoLibrary';
import { VideoLibraryTable } from './VideoLibraryTable';
import { OrganisationContext } from '../App';

export default function VideoLibrary() {
  const dispatch = useDispatch();
  const { selectedOrganisation } = useContext(OrganisationContext);

  const { searchText, videoLibraryFilter, videos, pagination, busy } = useSelector((state: any) => state.videoLibrary);

  const [videoPreview, setVideoPreview] = useState<any>(false);
  const [searchInputText, setSearchInputText] = useState({
    searchText: '',
  });
  const handleClose = () => setVideoPreview(false);

  useEffect(() => {
    if (!selectedOrganisation) return;

    dispatch<any>(
      apiVideoLibraryProvider({
        publisher: selectedOrganisation,
        limit: pagination.itemsPerPage,
        page: pagination.currentPage,
        filterByFormat: videoLibraryFilter,
        search: searchText,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, pagination.itemsPerPage, pagination.currentPage, videoLibraryFilter]);

  useEffect(() => {
    if (!selectedOrganisation) return;

    setSearchInputText({ searchText: '' });
    dispatch<any>(
      apiVideoLibraryProvider({
        publisher: selectedOrganisation,
        limit: pagination.itemsPerPage,
        page: pagination.currentPage,
        filterByFormat: videoLibraryFilter,
        search: '',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisation]);

  return videos? (
    <Fragment>
      {videoPreview && (
        <VideoSinglePreview
          open={Boolean(videoPreview)}
          handleClose={handleClose}
          video={videoPreview}
          format={videoPreview.format}
          transcript={videoPreview.transcript}
        />
      )}

      <ToolBarVideoLibrary
        setSearchInputText={setSearchInputText}
        searchInputText={searchInputText}
      />

      <Box sx={{ position: 'relative' }}>
        {busy && <LinearProgress sx={{ position: 'absolute', top: 0, width: '100%' }} color="success" />}
        <VideoLibraryTable videos={videos} onSetVideoPreview={setVideoPreview} />
      </Box>
      {busy && <LinearProgress color="success" />}
      <PaginationTable
        defaultSize={20}
        busy={busy}
        startPage={pagination.currentPage - 1}
        count={pagination.totalItems}
        onChange={page =>
          dispatch({
            type: 'SET_PAGINATION',
            payload: {
              currentPage: page + 1,
            },
          })
        }
        updateRowPerPage={totalPerPage => {
          dispatch({
            type: 'SET_PAGINATION',
            payload: {
              currentPage: 1,
              itemsPerPage: totalPerPage,
            },
          });
        }}
      />
    </Fragment>
  ) : (
    <CircularProgress style={{ padding: '5px' }} />
  );
}
