import TableHead from '@mui/material/TableHead';
import { TableCellBody, TableCellHead, TableRow } from '../common/Table';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import { decode } from 'he';
import removeButton from '../../icons/remove.png';
import { ReactComponent as PencilIcon } from '../../icons/pencil.svg';
import { Grid, SvgIcon } from '@mui/material';
import { PlaylistAPIData } from '../types/playlist';
import { ReactComponent as CopyContentIcon } from '../../icons/copy-content.svg';
import copyToClipboard from '../utils/copyToClipboard';
import { NavLink } from 'react-router-dom';
import PlaylistRefreshedDate from '../PlaylistRefreshedDate';


export const PlaylistTable = ({
  playlists,
  onEditPlaylist,
  onRemovePlaylist,
}: {
  playlists: PlaylistAPIData[],
  onEditPlaylist: (data: PlaylistAPIData) => void
  onRemovePlaylist: (data: PlaylistAPIData) => void
}) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCellHead>TOTAL PLAYS</TableCellHead>
          <TableCellHead>NAME</TableCellHead>
          <TableCellHead>TOTAL VIDEOS</TableCellHead>
          <TableCellHead>FORMAT</TableCellHead>
          <TableCellHead>TYPE</TableCellHead>
          <TableCellHead>IAB</TableCellHead>
          <TableCellHead>PUBLISHER</TableCellHead>
          <TableCellHead>GEO COUNTRY</TableCellHead>
          <TableCellHead>ENABLED</TableCellHead>
          <TableCellHead>LAST REFRESHED</TableCellHead>
          <TableCellHead sx={{ minWidth: '100px'}}>TOOLS</TableCellHead>
        </TableRow>
      </TableHead>
      <TableBody>
        {playlists &&
          playlists.map(row => (
            <TableRow
              sx={{
                height: '60px',
              }}
              key={row.uuid}
            >
              <TableCellBody>{row.play_count.toLocaleString()}</TableCellBody>
              <TableCellBody sx={{ minWidth: '287px' }}><NavLink to={`/dashboard/playlist/${row.uuid as string}`}>{decode(row.name)}</NavLink>
                <div title={row.uuid as string} style={{ cursor: 'pointer', marginTop: '10px' }}>
                  <SvgIcon onClick={copyToClipboard(row.uuid as string)}><CopyContentIcon/></SvgIcon>
                </div>
              </TableCellBody>
              <TableCellBody>{row.video_count}</TableCellBody>
              <TableCellBody>{row.format}</TableCellBody>
              <TableCellBody>{row.playlist_type_name}</TableCellBody>
              <TableCellBody>{row.iab_category_names.join(', ')} {row.iab_category_names.length > 0 && (row.iab_categories_exclude ? '(exclude)' : '(include)')}</TableCellBody>
              <TableCellBody>{row.playlist_publisher_name.join(', ')} {row.playlist_publisher_name.length > 0 && (row.playlist_publisher_exclude ? '(exclude)' : '(include)')}</TableCellBody>
              <TableCellBody>{row.playlist_geo_country_name.join(', ')} {row.playlist_geo_country_name.length > 0 && (row.playlist_geo_country_exclude ? '(exclude)' : '(include)')}</TableCellBody>
              <TableCellBody>{row.is_active ? 'YES' : 'NO'}</TableCellBody>
              <TableCellBody><PlaylistRefreshedDate lastRefreshed={row.last_refreshed} refreshRateInMins={row.refresh_rate_in_mins as number} /></TableCellBody>
              <TableCellBody>
                <Grid container alignItems={'center'} spacing={2}>
                  <Grid item>
                    <PencilIcon style={{ cursor: 'pointer' }} onClick={() => onEditPlaylist(row)} />
                  </Grid>
                  <Grid item>
                    <span onClick={() => onRemovePlaylist(row)} style={{ cursor: 'pointer' }}>
                      <img width="28" src={removeButton} alt="remove button" />
                    </span>
                  </Grid>
                </Grid>
              </TableCellBody>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};
