import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Box,
  Typography,
  Link,
  useTheme,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ReactJWPlayer from 'react-jw-player';
import ReactPlayer from 'react-player';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';
import { ReactComponent as BrandSafeIcon } from '../../icons/brand_safe.svg';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { grey } from '@mui/material/colors';
import { MatchType } from '../common/MatchType';
import ProducerHeading from '../ProducerHeading';
import FcPlayer from '../FcPlayer';
import { TranscriptPanel } from './TranscriptPanel';
import { PixelsVideoPlayer } from './PixelsVideoPlayer';

const CloseBtnRoot = styled('div')`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const Root = styled('div')`
  padding: 20px 40px 0;
  position: relative;
`;

const Left = styled(Avatar)`
  position: absolute;
  left: 10px;
  top: 50%;
`;

const Right = styled(Avatar)`
  position: absolute;
  right: 10px;
  top: 50%;
`;

const ReactJWPlayerRoot = styled(ReactJWPlayer)`
  min-height: 265px;
`;

const TitleDialog = styled(DialogTitle)`
  position: relative;
  padding-top: 0;

  &:after {
    content: ' ';
    background-color: #ccc;
    width: calc(100% + 80px);
    height: 1px;
    position: absolute;
    left: -40px;
    bottom: 10px;
  }
`;

const Title = styled('div')`
  text-transform: capitalize;
  font-weight: bold;
  margin: 24px 0 0 0;
`;

const BrandSafeRoot = styled(BrandSafeIcon)`
  margin: 0 16px;
`;

const BusyOverlay = styled('div')`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.66;
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Video = ({ article, activeIndex, playerKey, format = 'horizontal' }) => {
  return article.videos?.map((video, i) => (
    <Grid key={video.video_url} sx={{ display: activeIndex === i ? 'flex' : 'none' }} container spacing={1}>
      <Grid item xs={10}>
        <Grid container alignItems={'center'}>
          <ProducerHeading row={video} />
          <BrandSafeRoot title={'Brand safe'} />
          <MatchType data={video} />
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Typography textAlign={'right'}>
          {activeIndex + 1} / {article.videos?.length}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {video.video_type === 'youtube' && (
          <iframe
            title="Youtube preview"
            style={{ aspectRatio: '16/9' }}
            height={270}
            src={`https://www.youtube.com/embed/${video.media_id}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        )}
        {video.video_type === 'jwplayer' && (
          <ReactJWPlayerRoot
            playerId={video.video_url}
            playerScript="https://content.jwplatform.com/libraries/4i84SKoN.js"
            playlist={video.video_url}
          />
        )}
        {video.video_type === 'dailymotion' && (
          <ReactPlayer
            url={`https://www.dailymotion.com/video/${video.media_id}`}
            controls
            width={'100%'}
            height={'265px'}
          />
        )}
        {video.video_type === 'fcplayer' && <FcPlayer mediaId={video.media_id} />}
        {video.video_type === 'pixels' && <PixelsVideoPlayer video={video} playerKey={playerKey} format={format} />}
      </Grid>
      <Grid item xs={9}>
        {video.video_title}
      </Grid>
      <Grid item xs={3}>
        <Moment format="Do MMM YYYY">{video.video_published_date}</Moment>
      </Grid>
    </Grid>
  ));
};

const PreviewVideoContent = ({ article, activeIndex, playerKey, format }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { editorialVideo } = article;

  if (!editorialVideo) {
    // no tabs
    return <Video article={article} activeIndex={activeIndex} playerKey={playerKey} format={format} />;
  }

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={(_, newValue) => {
          setSelectedTab(newValue);
        }}
      >
        <Tab label="Suggested Video" />
        <Tab label="Existing Video" />
      </Tabs>
      <CustomTabPanel value={selectedTab} index={0}>
        <Video article={article} activeIndex={activeIndex} playerKey={playerKey} format={format} />
      </CustomTabPanel>
      {editorialVideo && (
        <CustomTabPanel value={selectedTab} index={1}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {editorialVideo.video_type === 'youtube' && (
                <iframe
                  title="youtube preview"
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${editorialVideo.media_id}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              )}
              {editorialVideo.video_type === 'jwplayer' && (
                <ReactJWPlayerRoot
                  playerId={editorialVideo.video_url}
                  playerScript="https://content.jwplatform.com/libraries/4i84SKoN.js"
                  playlist={editorialVideo.video_url}
                />
              )}
              {editorialVideo.video_type === 'dailymotion' && (
                <ReactPlayer
                  url={`https://www.dailymotion.com/video/${editorialVideo.media_id}`}
                  controls
                  width={'100%'}
                  height={'265px'}
                />
              )}
            </Grid>
            <Grid item xs={9}>
              {editorialVideo.video_title}
            </Grid>
            <Grid item xs={3}>
              <Moment format="Do MMM YYYY">{editorialVideo.video_published_date}</Moment>
            </Grid>
          </Grid>
        </CustomTabPanel>
      )}
    </>
  );
};

export const VideoImageSwapDialog = ({
  open,
  handleClose,
  article,
  onAddVideo,
  onRemoveVideo,
  selectedPublisher,
  dialogText = '',
  ctaText = 'Add video',
  type = 'review',
  format = 'horizontal',
  showTranscriptPanel = false,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  useEffect(() => {
    setActiveIndex(0);
  }, []);
  const [playerKey, setPlayerKey] = useState(0);
  const {
    palette: {
      primary: { main: primaryColor },
    },
  } = useTheme();

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={showTranscriptPanel ? 'xl' : 'sm'}>
      <Root style={{ position: 'relative' }}>
        <CloseBtnRoot>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </CloseBtnRoot>
        {!dialogText && (
          <TitleDialog>
            <Grid container alignItems={'center'}>
              <Grid item xs={3}>
                <img src={`${article.thumbnail}?width=100`} alt={''} style={{ maxWidth: '100px' }} />
              </Grid>
              <Grid item xs={9}>
                <Typography>
                  <Link href={article.article_url} target={'_blank'}>
                    {article.article_title}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </TitleDialog>
        )}
        {dialogText && (
          <TitleDialog>
            <Title>{dialogText}</Title>
          </TitleDialog>
        )}
        <DialogContent>
          <Grid container spacing={1}>
            {showTranscriptPanel ? (
              <Grid item xs={6}>
                <TranscriptPanel
                  mediaId={article.videos[activeIndex].media_id}
                  rightsholder={article.videos[activeIndex].video_rightsholder}
                  setPlayerKey={setPlayerKey}
                />
              </Grid>
            ) : (
              ''
            )}
            <Grid item xs={showTranscriptPanel ? 6 : 12}>
              <PreviewVideoContent article={article} activeIndex={activeIndex} playerKey={playerKey} format={format} />
              {type === 'review' && (
                <Grid sx={{ marginTop: 3 }} container justifyContent={'space-between'} flexDirection={'row'}>
                  {onRemoveVideo && (
                    <Button
                      variant="contained"
                      color={'warning'}
                      onClick={() => {
                        onRemoveVideo(article.videos[activeIndex].video_id);
                      }}
                    >
                      Remove
                    </Button>
                  )}
                  {onAddVideo && (
                    <Button variant="contained" onClick={() => onAddVideo(article.videos[activeIndex])}>
                      {ctaText}
                    </Button>
                  )}
                </Grid>
              )}
              {article.videos?.length > 1 && (
                <Left
                  sx={{ bgcolor: activeIndex === 0 ? grey[400] : primaryColor }}
                  onClick={() => {
                    if (activeIndex !== 0) {
                      setActiveIndex(activeIndex - 1);
                    }
                  }}
                >
                  <ChevronLeft />
                </Left>
              )}
              {article.videos?.length > 1 && (
                <Right
                  sx={{ bgcolor: activeIndex === article.videos?.length - 1 ? grey[400] : primaryColor }}
                  onClick={() => {
                    if (activeIndex !== article.videos?.length - 1) {
                      setActiveIndex(activeIndex + 1);
                    }
                  }}
                >
                  <ChevronRight />
                </Right>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <BusyOverlay />
      </Root>
    </Dialog>
  );
};
