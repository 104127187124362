import { curry } from 'lodash';
import { CalculatorState } from '../../context/calculator/reducer';

export const updateState = curry((fn, key, value) => {
  return fn({
    type: 'UPDATE',
    payload: [key, value]
  });
});

export const numberWithoutComma = (str) => Number(String(str).replace(/,/g, ''));

export const formatNumberWithComma = (str) => String(str)
  .replace(/[^0-9.]/g, '')
  .replace(/,/g, '')
  .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatCurrency = (amount: number, currencySymbol, currencyRate) => {
  return new Intl.NumberFormat(`en-GB`, {
    currency: `${currencySymbol}`,
    style: 'currency',
    minimumFractionDigits: 0,
    currencyDisplay: 'narrowSymbol',
  }).format(amount * currencyRate);
};

export const getCurrencyRate = (state: CalculatorState) => {
  switch (state.currency) {
    case 'pound':
      return state.d2p;
    case 'euro':
      return state.d2e;
    default:
      return 1;
  }
};
export const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case 'pound':
      return 'GBP';
    case 'euro':
      return 'EUR';
    default:
      return 'USD';
  }
};