import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const Heading = styled(Typography)`
  font-family: ProximaNovaBold;
`;

export const ToolbarHeading = styled('span')`
  margin-right: 20px;
  min-width: 150px;
`

export const KeywordHeading = styled(Heading)`
  font-family: ProximaNovaSemiBold;
  font-size: 38px;
  margin: 24px 12px;
`