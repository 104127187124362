export const getApiBaseUrl = (): string => {
  const apiEnv = process.env.REACT_APP_API_ENV;
  if (apiEnv === 'api-dev') {
    return `https://api-dev.pixels.ai`;
  } else if (apiEnv === 'api-dev-v3') {
    return `https://api-dev-v3.pixels.ai`;
  } else if (apiEnv === 'local') {
    return `http://localhost:3000`;
  }
  return `https://api.pixels.ai`;
};
