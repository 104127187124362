import React from 'react';
import { hydrate, render } from 'react-dom';

import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import imageSwap from './store/imageSwapSlice';
import keyword from './store/keywordSlice';
import report from './store/reportSlice';
import pixelsSearch from './store/pixelsSearchSlice';
import playlist from './store/playlistSlice';
import videoLibrary from './store/videoLibrarySlice';

import './index.css';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CalculatorProvider } from './context/calculator/CalculatorProvider';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    'contained-x': true;
    'outlined-x': true;
  }
}

const middleware = [thunk];

const appReducers = combineReducers({
  imageSwap,
  keyword,
  report,
  pixelsSearch,
  playlist,
  videoLibrary,
});

const store = createStore(appReducers, composeWithDevTools(applyMiddleware(...middleware)));
export type RootState = ReturnType<typeof store.getState>

const theme = createTheme({
  typography: {
    fontFamily: ['Helvetica Neue', 'Arial', 'sans-serif'].join(','),
    h1: { color: '#fff', fontWeight: '500' },
    h2: { color: '#fff', fontWeight: '500' },
    h3: { color: '#fff', fontWeight: '500' },
    h4: { color: '#fff', fontWeight: '500' },
    h5: { color: '#fff', fontWeight: '500' },
    h6: { color: '#fff', fontWeight: '500' },
    body1: {},
  },
  components: {
    MuiTypography: {
      defaultProps: {},
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained-x' },
          style: {
            background: '#214BDE',
            backgroundBlendMode: 'overlay, normal',
            color: 'white',
            border: '1px solid #3A54CA',
            '&:hover': {
              background: '#214BDE',
            },
            '&.Mui-disabled': {
              background: 'rgb(42, 131, 226, .1)',
              color: '#3355D1',
              borderColor: 'transparent',
            },
          },
        },
        {
          props: { variant: 'outlined-x' },
          style: {
            border: '1px solid transparent',
            background: 'white',
            borderRadius: 6,
            color: '#214BDE',
            padding: '16px 35px',
            '&:hover': {
              background: 'white',
            },
          },
        },
      ],
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 63,
          height: 36.5,
          padding: 0,
        },
        switchBase: {
          color: '#3A54CA',
          padding: 0,
          margin: 3,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(27px)',
          },
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: '#3A54CA',
          },
        },
        thumb: {
          width: 30,
          height: 30,
        },
        track: {
          opacity: 1,
          backgroundColor: '#fff',
          border: 0,
          borderRadius: 17,
          '.Mui-checked.Mui-checked + &': {
            opacity: 1,
            backgroundColor: '#fff',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#1976d2',
    },
    text: {
      primary: '#212D5A',
    },
  },
});

const rootElement = document.getElementById('root');

function useHasMounted() {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted;
}

const Root = () => {
  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <CalculatorProvider>
          <App />
        </CalculatorProvider>
      </Provider>
    </ThemeProvider>
  );
};

if (rootElement?.hasChildNodes()) {
  hydrate(<Root />, rootElement);
} else {
  render(<Root />, rootElement);
}
