import React, { useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { apiReportUrlProvider } from '../store/reportSlice';
import { LinearProgress } from '@mui/material';
import ToolBarGapAnalysisReport from './ToolBarGapAnalysisReport';
import { OrganisationContext } from '../App';

const ReportFrame = styled('iframe')`
  width: 100%;
  height: 85vh;
  border: none;
  background-color: transparent;
`;

export default function GapAnalysisReport() {
  const dispatch = useDispatch();
  const { selectedOrganisation } = useContext(OrganisationContext);

  const { playlistFilter, url, busy, formatFilter } = useSelector((state: any) => state.report);

  useEffect(() => {
    if (!selectedOrganisation) return;
    dispatch<any>(
      apiReportUrlProvider({
        publisher: selectedOrganisation,
        params: {
          dashboardId: 67,
          organisation: selectedOrganisation,
          format: formatFilter,
          exclude_status: 'out of scope',
          match_by_path: playlistFilter,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisation, playlistFilter, formatFilter]);

  return (
    <React.Fragment>
      <ToolBarGapAnalysisReport />
      {busy && <LinearProgress sx={{ position: 'absolute', top: 0, width: '100%' }} color="success" />}
      <ReportFrame src={url}></ReportFrame>
    </React.Fragment>
  );
}
