import axios from "axios";
import { createUserClient, UserClient } from "./userClient";
import { createArticleClient, ArticleClient } from "./articleClient";
import { createBrandedContentClient } from './brandedContentClient';

export type ApiClient = UserClient & ArticleClient;

export const createApiClient = (
  baseURL: string,
): ApiClient => {
  const instance = axios.create({ baseURL });

  instance.defaults.headers.common.Accept = "application/json";
  instance.defaults.headers.post["Content-Type"] = "application/json";

  const getInstance = async () => {
    return instance;
  };

  const userClient = createUserClient(getInstance);
  const articleClient = createArticleClient(getInstance);
  const brandedContentClient = createBrandedContentClient(getInstance)

  return {
    ...userClient,
    ...articleClient,
    ...brandedContentClient,
  };
};
