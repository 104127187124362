import { useState } from "react";
import PublicLayout2 from "./PublicLayout2";
import { Box, GlobalStyles, Grid, Typography, useMediaQuery } from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import ContentWrap from './component/ContentWrap';
import Header from './component/Header';
import { TextHighlighted } from "../common/PublicTypography";
import MobileDrawer from "./component/Drawer";
import { ReactComponent as ChartUptrendIcon } from '../../icons/chart-uptrend.svg';
import { ReactComponent as ChartDowntrendIcon } from '../../icons/chart-downtrend.svg';
import { ReactComponent as EyeIcon } from '../../icons/eye.svg';
import { ReactComponent as MediaPlayerIcon } from '../../icons/media-player.svg';
import { ReactComponent as GlobalLeafIcon } from '../../icons/globe-leaf.svg';
import Faqs from "../faqs/Faqs";

const Headline = styled(Typography)`
  font-size: 38px;
  line-height: 1.14;
  max-width: 280px;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: 64px;
    text-align: center;
    max-width: none;
  }
`

const HeadlineParagraph = styled(Typography)`
  font-size: 20px;
  line-height: 1.3;
  margin-top: 20px;
  ${props => props.theme.breakpoints.up('md')} {
    text-align: center;
    max-width: none;
  }
`;

const Paragraph = styled(Typography)`
  font-size: 20px;
  line-height: 1.4;
  color: #112671;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: 22px;
    line-height: 1.5;
  }
`;

const ParagraphNote = styled(Typography)`
  font-size: 20px;
  line-height: 1.25;
  color: #112671;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: 22px;
    line-height: 1.19;
  }
`;

const ParagraphSmall = styled(Typography)`
  font-size: 18px;
  line-height: 1.25;
  color: #112671;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: 16px;
  line-height: 1.2;
  }
`;

const ParagraphWhite = styled(ParagraphSmall)`
  color: #fff;
`;

const TopContentSection = styled(Grid)`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 40px 10px 60px;
  background: #fff;

  ${props => props.theme.breakpoints.up('md')} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    padding: 60px;
  }
`;

const BoxText = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${props => props.theme.breakpoints.up('md')} {
    justify-content: center;
  }
`;

const BoxImage = styled(Box)`
  ${props => props.theme.breakpoints.up('md')} {

  }
`;

const HeaderTwo = styled(Typography)`
  color: #112671;
  font-size: 28px;
  line-height: 1.1;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: 42px;
  }
`;

const HeaderTwoWhite = styled(HeaderTwo)`
  color: #fff;
`;

const HeaderThree = styled(Typography)`
  font-size: 22px;
  color: #112671;
`;

const HeaderThreeWhite = styled(HeaderThree)`
  color: #fff;
`;

const TextHighlightedWhiteText = styled(TextHighlighted)`
  color: #fff;
`;

const GridBoxImageEverclime = styled('div')`
  background-image: url('/proudly-partnered-with-everclime.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 384px;
`;

// Currently unused styled component `GridBoxImageHybridBackgroundColor`, but may be used in the future, if requirement is to display an image instead of a video.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GridBoxImageHybridBackgroundColor = styled('div')`
  background: url('/laptop-woman-drink.png'),
              linear-gradient(to bottom, #0083e1 0%, #0083e1 200px, transparent 200px);
  background-repeat: no-repeat, no-repeat;
  background-size: 340px, 100%;
  background-position: bottom center;
  min-height: 260px;

  ${props => props.theme.breakpoints.up('md')} {
    background: url('/laptop-woman-drink.png'),
              linear-gradient(to bottom, #0083e1 0%, #0083e1 300px, transparent 300px);
    background-size: 500px, 100%;
    background-repeat: no-repeat, no-repeat;
    background-position: bottom center;
    min-height: 384px;
  }
`;

const MiddleContentSection = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 10px 60px;
  background: #EBEFFA;
  background: linear-gradient(to bottom, #EBEFFA 0%, #EBEFFA 55%, #fff 55%);


  ${props => props.theme.breakpoints.up('md')} {
    gap: 40px;
    padding: 60px;
  }
`;

const CardsHowItWorks = styled(Grid)`
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;

  ${props => props.theme.breakpoints.up('md')} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const CardsHowItWorksItem = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CardHowItWorksItemImageWrapper = styled(Box)`
  height: 160px;
  background-color: #112671;
  padding: 20px;
`;

const CardHowItWorksItemImage = styled(Box)`
  height: 100%;
  width: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

const WhiteNoteBox = styled(Box)`
  display: flex;
  gap: 10px;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  margin-top: 20px;
`;

const BenefitsForPublishersWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #274bde;
  padding: 20px;
  border-radius: 4px;
  /*margin-top: 20px;*/

  ${props => props.theme.breakpoints.up('md')} {
    gap: 20px;
    padding: 40px;
  }
`;

const BenefitsForPublishersGrid = styled(Grid)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 10px;

  ${props => props.theme.breakpoints.up('md')} {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

const BenefitsForPublishersGridItem = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #0083E1;
  padding: 30px;
`;

const BottomContentSection = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px 40px;
  background: #fff;

  ${props => props.theme.breakpoints.up('md')} {
    padding: 60px;
  }
`;

const VideoContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${props => props.theme.breakpoints.up('md')} {

  }
`;

const Video = styled('video')`
  border: 1px solid #fff;
  width: 100%;
  height: 100%;
`;

const Sustainability = ({ className }) => {
  const [drawerState, setDrawerState] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PublicLayout2 className={className}>
      <GlobalStyles styles={{
        body: {
            backgroundImage: 'url("/v2/pixels-bg.svg")',
            backgroundPosition: 'center 0',
            backgroundRepeat: 'repeat-x'
        }
      }}/>
      <Helmet title="Pixels AI - Redefining Ad Engagement with Sustainable Impact">
        <meta name="description"
              content="Discover how Pixels AI redefines ad engagement with a focus on purpose and environmental impact. Explore our partnership with Everclime, enabling ethical advertising that leaves a positive imprint on the planet. Learn about our sustainable Pixels Player and its benefits for advertisers, brands, and consumers."/>
        <script defer src={'/observeBox.js'}></script>
      </Helmet>
      <ContentWrap maxWidth={1440}>
        <Header setDrawerState={setDrawerState}/>
      </ContentWrap>
      <Grid container>
        <Grid item xs={12} display={'flex'} flexDirection={'column'} justifyContent={'center'}
          sx={{
                minHeight: {
                  md: '260px'
                },
                padding: {
                  xs: '0 20px 20px',
                  md: '60px'
                }
              }}>
          <Headline variant={'h1'}>
            Injecting {isSmallScreen ? <br /> : ' '}<TextHighlighted>Purpose</TextHighlighted> into Every Pixel
          </Headline>
          <HeadlineParagraph color={'white'} variant={'body1'}>Redefining ad engagement with sustainability at its core.</HeadlineParagraph>
        </Grid>
      </Grid>
      <TopContentSection>
        <BoxText>
          <HeaderTwo variant={'h2'}>Partnered for {isSmallScreen ? <br /> : ' '}<TextHighlightedWhiteText>Good</TextHighlightedWhiteText></HeaderTwo>
          <Paragraph>Our vision is a tech future that not only serves publishers and advertisers but leaves a positive imprint on the planet.</Paragraph>
          <Paragraph>We partnered with Everclime to enable brands to deliver an ethical ad unit where user engagement translates into tangible environmental impact.</Paragraph>
        </BoxText>
        <BoxImage>
          <GridBoxImageEverclime />
        </BoxImage>
        <BoxImage sx={{
          display: 'flex',
          order: {
            xs: 1,
            md: 0,
          }
        }}>
          {/* <GridBoxImageHybridBackgroundColor /> */} { /** if decide to use image then uncomment this styled component and change the CSS background-image reference, and also comment out the <VideoContainer> component below. */ }
          <VideoContainer>
            <Video autoPlay loop muted playsInline poster={'/v2/desktop-export-poster-min.png'}>
              <source src="/v2/marketing-video-lady-gaga.mp4" type="video/mp4"/>
            </Video>
          </VideoContainer>
        </BoxImage>
        <BoxText>
          <HeaderTwo variant={'h2'}>Meet our <br/><TextHighlightedWhiteText>Sustainable</TextHighlightedWhiteText> Player</HeaderTwo>
          <Paragraph>When users choose to watch an ad, they unlock an Impact Action funded by the advertiser and receive an Impact Certificate provided by Everclime. This provides them with a transparent view of the positive impact they've made.</Paragraph>
          <Paragraph>Our sustainable Pixels Player connects advertisers, brands, and consumers, while delivering ad engagement, accountability and impact.</Paragraph>
        </BoxText>
      </TopContentSection>
      <MiddleContentSection>
        <HeaderTwo variant={'h2'} textAlign="center"><TextHighlightedWhiteText>How</TextHighlightedWhiteText> it works</HeaderTwo>
        <CardsHowItWorks>
          <CardsHowItWorksItem>
            <CardHowItWorksItemImageWrapper>
              <CardHowItWorksItemImage style={{ backgroundImage: 'url("/laptop-woman-drink.png")' }} />
            </CardHowItWorksItemImageWrapper>
            <HeaderThree variant={'h3'}>Watch Ad</HeaderThree>
            <ParagraphSmall>We <strong>wrap existing video ads</strong> in our unique sustainable formats and distribute them across the open web.</ParagraphSmall>
          </CardsHowItWorksItem>
          <CardsHowItWorksItem>
            <CardHowItWorksItemImageWrapper>
              <CardHowItWorksItemImage style={{ backgroundImage: 'url("/laptop-woman-drink.png")' }} />
            </CardHowItWorksItemImageWrapper>
            <HeaderThree variant={'h3'}>Unlock Impact Certificate</HeaderThree>
            <ParagraphSmall>When users choose to <strong>watch at least 20 seconds</strong> of the ad, they unlock an Impact Action funcded by the advertiser and receive a Digital Impact Certificate that shows their tangible contribution.</ParagraphSmall>
          </CardsHowItWorksItem>
          <CardsHowItWorksItem>
            <CardHowItWorksItemImageWrapper>
              <CardHowItWorksItemImage style={{ backgroundImage: 'url("/laptop-woman-drink.png")' }} />
            </CardHowItWorksItemImageWrapper>
            <HeaderThree variant={'h3'}>Follow Impact Campaign</HeaderThree>
            <ParagraphSmall>Users can download their Digital Impact Certificate and receive updates about the progress of the campaign. Advertisers get increased ad attention and engagement, and establish brand trust.</ParagraphSmall>
          </CardsHowItWorksItem>
        </CardsHowItWorks>
        <WhiteNoteBox>
          <ChartUptrendIcon />
          <ParagraphNote><strong>In return,</strong> advertisers gain increased attention, engage audiences in a more meaningful way, and drive higher ad recall.</ParagraphNote>
        </WhiteNoteBox>
        <BenefitsForPublishersWrapper>
          <HeaderTwoWhite variant={'h2'}>Benefits for <TextHighlighted>Publishers</TextHighlighted></HeaderTwoWhite>
          <BenefitsForPublishersGrid>
            <BenefitsForPublishersGridItem>
              <EyeIcon />
              <HeaderThreeWhite>Enhanced User Engagement</HeaderThreeWhite>
              <ParagraphWhite>The Pixels Player uniquely offers a second play slot with significantly <strong>higher average watch times:</strong> 68s vs 8s for traditional mid-article pre-roll ads</ParagraphWhite>
            </BenefitsForPublishersGridItem>
            <BenefitsForPublishersGridItem>
              <ChartDowntrendIcon />
              <HeaderThreeWhite>Reduced Supply Chain Losses</HeaderThreeWhite>
              <ParagraphWhite>Unlike tradiitonal ad units that may incur losses through the supply chain (e.g payment to DSPs and SSPs), the Pixel Player doesn't. This results in more efficient spending and a <strong>higher return on investment</strong> for advertisers</ParagraphWhite>
            </BenefitsForPublishersGridItem>
            <BenefitsForPublishersGridItem>
              <MediaPlayerIcon />
              <HeaderThreeWhite>Superior Player Experience</HeaderThreeWhite>
              <ParagraphWhite>The Pixels Player's <strong>native controls</strong> allows for the integration of hotspots and interactive elements within the video content. This offers a more dynamic and engaging ad format that captures and maintains user attention.</ParagraphWhite>
            </BenefitsForPublishersGridItem>
            <BenefitsForPublishersGridItem>
              <GlobalLeafIcon />
              <HeaderThreeWhite>Sustainability and Accountability</HeaderThreeWhite>
              <ParagraphWhite>The Pixels Player is specifically designed to support advertisers' <strong>sustainability goals,</strong> offering consumers a <strong>tangible</strong> way to contribute to environmental causes. Everclime adds an accountability layer and provides traceability through Impact Certificates.</ParagraphWhite>
            </BenefitsForPublishersGridItem>
          </BenefitsForPublishersGrid>
        </BenefitsForPublishersWrapper>
      </MiddleContentSection>
      <BottomContentSection>
        <Faqs/>
      </BottomContentSection>
      <MobileDrawer setDrawerState={setDrawerState} drawerState={drawerState}/>
    </PublicLayout2>
  )
}

Sustainability.propTypes = {
  className: PropTypes.string
};

export default Sustainability;