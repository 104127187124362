import { useEffect } from 'react';

const FCPLAYER_EMBED_TAG_PATTERN =
  'https://cdn.fcp.codes/embed-code-template/embed-code-template.js?publisher=4f11dd1d-50ac-4d01-9d05-602b4d6cb9e5#PIXELS_';

export default function FcPlayer({ mediaId }) {
  useEffect(() => {
    const fcplayer_script = document.createElement('script');
    fcplayer_script.defer = true;
    fcplayer_script.src = `${FCPLAYER_EMBED_TAG_PATTERN}${mediaId}`;
    const el = document.getElementById('fcplayer_preview_video');
    el?.appendChild(fcplayer_script);
    return () => {
      el?.removeChild(fcplayer_script);
    };
  }, [mediaId]);

  return <div id="fcplayer_preview_video"></div>;
}
