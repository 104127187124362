import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const CloseBtnRoot = styled('div')`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const Root = styled('div')`
  padding: 20px 40px 0;
  position: relative;
`;

const TitleDialog = styled(DialogTitle)`
  position: relative;
  padding-top: 0;

  &:after {
    content: ' ';
    background-color: #ccc;
    width: calc(100% + 80px);
    height: 1px;
    position: absolute;
    left: -40px;
    bottom: 10px;
  }
`;

const Title = styled('div')`
  font-weight: bold;
  margin: 24px 0 0px 0;
  color: black;
`;

const videoKeys = ['videoIdToAdd', 'pos'];

type Video = {
  videoIdToAdd: string;
  pos: number;
};

const stateChange =
  (key, setValue, state: Video) =>
  ({ target: { type, value } }) => {
    setValue({
      ...state,
      [key]: /datetime-local|number/.test(type) && value ? Number(value) : value,
    });
  };

const removeNonDtoProps = (data): Video =>
  Object.keys(data)
    .filter(key => videoKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {} as Video);

export const AddVideoDialog = ({ onSave, open, handleClose, video }) => {
  video.videoIdToAdd = '';
  video.pos = parseInt(video.pos) + 1;
  const [updatedData, setUpdatedData] = useState<Video>(removeNonDtoProps(video));
  const [valid, setValid] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [vIdHasBeenTouch] = useState(false);

  useEffect(() => {
    const invalidFields: (string[] | false)[] = Object.entries(updatedData)
      .map<string[] | false>(([key, value]) => {
        const errorWithKey = (
          k => err =>
            [k, err]
        )(key);
        switch (key) {
          case 'videoIdToAdd': {
            return value ? false : errorWithKey('Video ID can not be empty');
          }
          default:
            return false;
        }
      })
      .filter(x => x);

    setValid(invalidFields.length === 0);
    setErrors(Object.fromEntries(invalidFields as string[][]));
  }, [updatedData]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <Root>
        <CloseBtnRoot>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </CloseBtnRoot>
        <TitleDialog>
          <Title>{`Add a new video${video.title ? ` above "${video.title}"` : ''}`}</Title>
        </TitleDialog>
        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: 0 }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  id="videoIdToAdd"
                  label="Video ID"
                  variant="outlined"
                  type={'text'}
                  onChange={stateChange('videoIdToAdd', setUpdatedData, updatedData)}
                  value={updatedData.videoIdToAdd}
                  error={vIdHasBeenTouch && Boolean(errors.videoIdToAdd)}
                  helperText={vIdHasBeenTouch && errors.videoIdToAdd}
                />
              </FormControl>
              <TextField
                id="pos"
                type={'hidden'}
                value={updatedData.pos}
                style={{
                  display: 'none',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent={'flex-end'}>
                <Button color="primary" variant="contained" disabled={!valid} onClick={() => onSave(updatedData)}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Root>
    </Dialog>
  );
};
