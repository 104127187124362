import { Box, Drawer } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DrawerRowAnchor, DrawerText, ListMobileAnchor, LogoDrawer } from './common';
import ArrowRight from '@mui/icons-material/KeyboardArrowRight';

const MobileDrawer = ({ setDrawerState, drawerState }) => (
  <Drawer
    PaperProps={{
      sx: {
        width: '100%',
        backgroundColor: '#274bde',
        color: '#fff',
        fontSize: 18,
      },
    }}
    anchor="right"
    open={drawerState}
    onClose={() => setDrawerState(false)}
  >
    <Box
      sx={{
        p: 2,
        height: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 1,
        }}
      >
        <IconButton onClick={() => setDrawerState(false)}>
          <CloseIcon htmlColor={'#fff'} />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
          }}
        >
          <a href={'/'}>
            <LogoDrawer>Pixels.AI</LogoDrawer>
          </a>
        </Box>
      </Box>
      <DrawerRowAnchor className={'initial'}>
        <ListMobileAnchor href={'/instream-revenue-uplift-calculator/'}>
          <DrawerText
            primary="Get Started"
            primaryTypographyProps={{
              fontFamily: 'Helvetica Neue,Arial,sans-serif',
            }}
          />
          <ArrowRight />
        </ListMobileAnchor>
      </DrawerRowAnchor>
      <DrawerRowAnchor>
        <ListMobileAnchor
          href={'https://pixelsai.notion.site/b65d2273ad9e41c9a66d2236a3e34fca?v=c648b2ab3d7346f581954fb04b1b5003/'}
        >
          <DrawerText
            primary="Product Centre"
            primaryTypographyProps={{
              fontFamily: 'ProximaNovaSemiBold',
            }}
          />
          <ArrowRight />
        </ListMobileAnchor>
      </DrawerRowAnchor>
      <DrawerRowAnchor>
        <ListMobileAnchor href={'/sustainability/'}>
          <DrawerText
            primary="Sustainability"
            primaryTypographyProps={{
              fontFamily: 'ProximaNovaSemiBold',
            }}
          />
          <ArrowRight />
        </ListMobileAnchor>
      </DrawerRowAnchor>
      <DrawerRowAnchor>
        <ListMobileAnchor href={'/contact/'}>
          <DrawerText
            primary="Contact"
            primaryTypographyProps={{
              fontFamily: 'ProximaNovaSemiBold',
            }}
          />
          <ArrowRight />
        </ListMobileAnchor>
      </DrawerRowAnchor>
      <DrawerRowAnchor>
        <ListMobileAnchor href={'/login/'}>
          <DrawerText
            primary="Login"
            primaryTypographyProps={{
              fontFamily: 'ProximaNovaSemiBold',
            }}
          />
        </ListMobileAnchor>
      </DrawerRowAnchor>
    </Box>
  </Drawer>
);

export default MobileDrawer;
