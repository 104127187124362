import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const GridCenterAligned = styled(Grid)`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  ${props => props.theme.breakpoints.up('md')} {
    padding-left: 0;
    padding-right: 0;
    flex-wrap: nowrap;
    gap: 18px;
  }
`

export const GridContentItem = styled(Grid)`
  background-color: transparent;
  position: relative;
  z-index: 1;
`;

export const GridCenterAligned2 = styled(Grid)`
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  ${props => props.theme.breakpoints.up('md')} {
    padding-left: 0;
    padding-right: 0;
  }
`

export const GridContentItem2 = styled(Grid)`
  position: relative;
  z-index: 1;
`;

export const ContentWrap = styled(Box)`
  ${props => props.theme.breakpoints.up('md')} {
    padding-left: 16px;
    padding-top: 60px;
  }
`;