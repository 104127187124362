import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const Paragraph = styled(Typography)`
  font-size: 18px;
  line-height: 1.2;
  ${props => props.theme.breakpoints.up('md')} {
    line-height: 1.636;
    font-size: 22px;
  }
`;

export const BlueGradientText = styled(Typography)`
  font-weight: bold;
`;


export const TextHighlighted = styled('span')`
  position: relative;
  z-index: 0;
  &:after {
    background-color: #28A5FF;
    position: absolute;
    width: 100%;
    height: 90%;
    content: " ";
    z-index: -1;
    left: 0;
    top: 5%;
    padding: 0 1px;
  }
`;
export const BaseTypography = styled(Typography)`
  font-size: 18px;
  ${props => props.theme.breakpoints.up('md')} {
    font-size: 22px;
  }
`;

export const Headline = styled(BaseTypography)`
  font-size: 34px;
  margin: 16px 0;
  ${props => props.theme.breakpoints.up('md')} {
    font-size: 40px;
  }
`;

export const Emphasize = styled('span')`
  color: #fff
`