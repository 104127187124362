import { AxiosInstance } from "axios";
import { IUserData } from "./userInterface";
import {
  useAsync,
  UseAsyncOptions,
} from "react-async-hook";


export interface UserClient {
  getUserData: () => Promise<IUserData>;
}

export const createUserClient = (
  getInstance: () => Promise<AxiosInstance>
): UserClient => ({
  getUserData: async () => {
    const instance = await getInstance();
    const response = await instance.get<IUserData>("users/");

    try {
      return response.data;
    } catch (err) {
      console.log(err);
      throw new Error("An error occured while fetching user Data");
    }
  },
});

export function useErrorHandlingAsync<R = unknown, Args extends any[] = any[]>(
  asyncFunction: (...args: Args) => Promise<R>,
  params: Args,
  options?: UseAsyncOptions<R>
) {
  return useAsync(asyncFunction, params, options);
}

