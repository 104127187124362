import '../App.css';
import { Box, Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import logo from '../pixels-ai-logo.svg';

const H2 = styled(Typography)`
  font-size: 1.6rem;
  margin: 1rem 0;
`;

function PrivacyPolicy() {

  return (<div className="App">
      <Box px={5} mb={5} m={0} sx={{ flexGrow: 1 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <header className="App-header">
            <img src={logo} alt="logo"/>
          </header>
        </Grid>
        <Grid container flexDirection={'column'} rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <H2 color={'primary'} variant="h2">Important information</H2>
          <Typography component="h3">Who collects the information</Typography>
          <p className="c3"><span className="c0">When we refer to "Pixels AI," "we," or "us" in this policy, we mean Play Inc Publishing Ltd, which controls the information Pixels AI collected when you use the pixels.ai website (the ”Website”).</span>
          </p><p className="c3"><span className="c0">Pixels AI offers video recommendations via our dashboard and our Video Wrapper. We also offer related services such as support, consultancy and implementation. We refer to our products, together with our other services as "Services" in this policy.</span>
        </p>
          <Typography component="h3">Who this Privacy Policy concerns</Typography>
          <p className="c3"><span className="c0">As a general rule, there are two categories of users Pixels AI collects information from. "Customers" are operators or publishers of websites that integrate our products on their website, and “Visitors” are individual visitors of a site or application operated by the Customer, and which makes use of the Pixels AI Video Wrapper. We only collect anonymous information from Visitors, meaning that none of it is used or intended to be used to personally identify an individual. For more details on what kind of information we collect from Visitors, please refer to the “Tracking data for our algorithm” section of this Privacy Policy.</span>
          </p>
          <Typography component="h3">Changes to this Privacy Policy</Typography>
          <p className="c3"><span className="c0">This Policy became effective starting 1st January 2022. We may update this Privacy Policy from time to time by posting a new version online. You should check this page occasionally to review any changes. This helps you to always be aware of what information we collect, how we use it and under what circumstances, if any, it is disclosed. Your continued use of the Website, of our Services, and/or continued provision of Personal Information to us will be subject to the terms of the then-current Privacy Policy.</span>
          </p>
          <Typography component="h3">Contact us</Typography>
          <p className="c3"><span>If you have any questions about this Privacy Policy or our treatment of the information you provide us, please get in touch at </span><span
            className="c9"><a className="c11"
                              href="mailto:info@pixels.ai">info@pixels.ai</a></span><span
            className="c0">&nbsp;or by mail at Play Inc Publishing Ltd, 85 Great Portland St, London W1W 7LT, United Kingdom.</span>
          </p>
          <H2 color={'primary'} variant="h2">What information we collect</H2>
          <p className="c3"><span className="c0">Our product mainly operates on anonymous data, but we do need some Personal Information. “Personal Information” means contact information (e.g., your name, address and/or email address) and any other non-public information that is used or intended to be used to personally identify an individual, and any other non-public information that is associated with that information. The different types of information we collect are listed below.</span>
          </p>
          <Typography component="h3">Information you give us</Typography>
          <p className="c3"><span className="c0">We collect Personal Information about you the Customer when you provide it to us as part of using our Services, or otherwise provide it directly to us.</span>
          </p>
          <ul className="c1 lst-kix_g6cjh6f8tbgp-0 start">
            <li className="c2 li-bullet-0"><span
              className="c4">Account and Profile Information</span><span className="c0">: We receive information about you when you register for an account, create or modify your profile, set preferences, change to another plan tier through the Services. For example, you provide your contact information when you register for the Services. We keep track of your preferences when you select settings within the Services.</span>
            </li>
            <li className="c2 li-bullet-0"><span
              className="c4">Content you provide through our products</span><span
              className="c0">: In order to use Pixels AI’s recommendation module, you will have to upload content items - via an automated feed or manually - on the Pixels AI platform. These include any videos on articles you want to appear as recommendations on your website.</span>
            </li>
            <li className="c2 li-bullet-0"><span
              className="c4">Content you provide through our Website</span><span
              className="c0">: We collect information about you when you fill in a form on our site, for example when you request a demo, when you subscribe to our newsletter, when you ask us a question or when you ask us to calculate your ROI for you.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c4">Information you provide through our support channels</span><span
              className="c0">: When you submit a request for assistance, we may ask you for a contact email address, User ID and name in order to process that request.</span>
            </li>
          </ul>
          <Typography component="h3">Information we collect automatically when you use our
            Services</Typography>
          <ul className="c1 lst-kix_ee8mhof4a8lt-0 start">
            <li className="c2 li-bullet-0"><span
              className="c4">Your use of the Services</span><span className="c0">: We keep track of certain information about you when you visit and interact with any of our Services. For example, we will track the number of pages of the website you visited as well as your activity when interacting with and playing a video. We do this in order to assist you with your use of the Services (for example, if we notice that you have stopped at one stage of the video, we may infer that you are not enjoying the video you are watching).</span>
            </li>
            <li className="c2 li-bullet-0"><span
              className="c4">Cookies and other tracking technologies</span><span
              className="c0">: Pixels AI and our third-party partners, such as our advertising and analytics partners, use cookies and other tracking technologies (e.g., web beacons, device identifiers and pixels) to provide functionality and to recognize you across different Services and devices. You can find more information on how to control or opt out of these cookies and tracking technologies by visiting the Cookie section.</span>
            </li>
            <li className="c2 li-bullet-0"><span
              className="c4">Tracking data for our algorithm</span><span className="c0">: Once the Video Wrapper is live on a Customer page, it automatically tracks whether articles and videos are being viewed on the page. We do not process any identifiable information about the Visitors (ex: IP, demographics data, additional information about a visitor navigation history) and we do not drop cookies on the Customer’s page. IP addresses are logged into our HTTPS request log for safety reason like fraud, but are not used for our algorithm.</span>
            </li>
          </ul>
          <H2 color={'primary'} variant="h2">How we use the information we collect</H2>
          <ul className="c1 lst-kix_o2ow6stueuqu-0 start">
            <li className="c2 li-bullet-0"><span className="c4">To provide the Services and personalize your experience</span><span
              className="c0">: We use information about you to provide the Services to you, authenticate you when you log in, provide customer support, and operate and maintain the Services. We use the Content you send us to generate metadata for our product. The metadata created from your content items is anonymized and cannot be used to identify you personally.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c4">To communicate with you about the Services</span><span
              className="c0">: We use your contact information to send transactional communications via email and within the Services. These include: notifications on video matches via Slack or email, sending you technical notices, updates, security alerts, and administrative messages. We also provide tailored communications based on your activity and interactions with us, for example to provide customer support, responding to your comments, questions, and requests. We may also ask you to give us a review if you liked our product but will never withhold access to the Services if you do not wish to do so. These communications are part of the Services and in most cases you cannot opt out of them. If an opt out is available, you will find that option within the communication itself or in your account settings.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c4">To market, promote, and drive engagement with the Services</span><span
              className="c0">: We use your contact information and information about how you use the Services to send promotional communications that may be of specific interest to you, including by email and by displaying Pixels AI ads on other companies' websites and applications, as well as on platforms like Facebook and Google. These communications are aimed at driving engagement and maximizing what you get out of the Services, including information about new features, webinars, newsletters, and events we think may be of interest to you. We also communicate with you about new product offers. You can control whether you receive these communications as described below under "Opt-out of communications".</span>
            </li>
            <li className="c2 li-bullet-0"><span
              className="c4">For safety and security</span><span className="c0">: We use information about you and your Service use to verify accounts and activity, to monitor suspicious or fraudulent activity and to identify violations of Service policies.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c4">To protect our legitimate business interests and legal rights</span><span
              className="c0">: Where required by law or where we believe it is necessary to protect our legal rights, interests and the interests of others, we use information about you in connection with legal claims, compliance, regulatory, and audit functions, and disclosures in connection with the acquisition, merger or sale of a business.</span>
            </li>
            <li className="c2 li-bullet-0"><span
              className="c4">With your consent</span><span className="c0">: We use information about you where you have given us consent to do so for a specific purpose not listed above. For example, we may publish case studies, reports on events organized by Pixels AI or write blog posts to promote the Services that may include information about you and/or your company, with your permission.</span>
            </li>
            <li className="c2 li-bullet-0"><span
              className="c4">For research and development</span><span className="c0">: We are always looking for new ways to make our product better, and customer feedback is a big part of that. For example, feature requests from our customers can be incorporated into product updates. We also test and analyze certain new features with some users before rolling the feature out to all users.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c4">Legal bases for processing (for EEA users)</span><span
              className="c0">: If you are an individual in the European Economic Area (EEA), we collect and process information about you only where we have legal bases for doing so under applicable EU laws. The legal bases depend on the Services you use and how you use them. This means we collect and use your information only where:</span>
            </li>
          </ul>

          <ul className="c1 lst-kix_ook8u41vpzvw-0 start">
            <li className="c3 c6 li-bullet-0"><span className="c0">We need it to provide you the Services, including to operate the Services, provide customer support and personalized features and to protect the safety and security of the Services;</span>
            </li>
            <li className="c3 c6 li-bullet-0"><span className="c0">It satisfies a legitimate interest (which is not overridden by your data protection interests), such as for research and development, to market and promote the Services and to protect our legal rights and interests; </span>
            </li>
            <li className="c3 c6 li-bullet-0"><span className="c0">You give us consent to do so for a specific purpose; or</span>
            </li>
            <li className="c3 c6 li-bullet-0"><span className="c0">We need to process your data to comply with a legal obligation.</span>
            </li>
          </ul>
          <p className="c3"><span
            className="c0">If you have consented to our use of information about you for a specific purpose, you have the right to change your mind at any time, but this will not affect any processing that has already taken place. Where we are using your information because we or a third party (e.g. your employer) have a legitimate interest to do so, you have the right to object to that use though, in some cases, this may mean no longer using the Services.</span>
          </p>
          <H2 color={'primary'} variant="h2">How we share information we collect</H2>
          <p className="c3"><span className="c0">We will never sell your Personal Information to advertisers or any other third party. We may share information through the Services and with certain third parties in the ways discussed below.</span>
          </p>
          <Typography component="h3">Sharing with third parties</Typography>
          <ul className="c1 lst-kix_mbwyreevryz0-0 start">
            <li className="c2 li-bullet-0"><span className="c4">Service Providers</span><span
              className="c0">: We work with third party service providers to provide website and application development, hosting, maintenance, backup, storage, virtual infrastructure, payment processing, analysis and other services for us, which may require them to access or use information about you. If a service provider needs to access information about you to perform services on our behalf, they do so under instruction from us, including abiding by policies and procedures designed to protect your information.</span>
            </li>
            <li className="c2 li-bullet-0"><span
              className="c4">Pixels AI Partners</span><span className="c0">: We work with third parties who provide consulting, sales, support and technical services to deliver and implement customer solutions around the Services. With your consent, we may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, we do not transfer your unique Personal Information to the third party.</span>
            </li>
            <li className="c2 li-bullet-0"><span
              className="c4">Links to Third-Party Sites</span><span
              className="c0">: The Services may include links that direct you to other websites or services whose privacy practices may differ from ours. Your use of and any information you submit to any of those third-party sites is governed by their privacy policies, not this one. For example, if you use the Flow plugin you should refer to the Flow Privacy Policy.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c4">With your consent</span><span
              className="c0">: We share information about you with third parties when you give us consent to do so. For example, we often display personal testimonials of satisfied customers on our websites. With your consent, we may post your name alongside the testimonial.</span>
            </li>
          </ul>
          <ul className="c1 lst-kix_wfaxpspenswo-0 start">
            <li className="c2 li-bullet-0"><span className="c4">Compliance with enforcement requests and applicable laws; enforcement of our rights</span><span
              className="c0">: In exceptional circumstances, we may share information about you with a third party if we believe that sharing is reasonably necessary to:</span>
            </li>
          </ul>
          <ul className="c1 lst-kix_wzeh84hx13v4-0 start">
            <li className="c3 c6 li-bullet-0"><span className="c0">Comply with any applicable law, regulation, legal process or governmental request, including to meet national security requirements, </span>
            </li>
            <li className="c3 c6 li-bullet-0"><span className="c0">enforce our agreements, policies and terms of service, </span>
            </li>
            <li className="c3 c6 li-bullet-0"><span className="c0">protect the security or integrity of our products and services, </span>
            </li>
            <li className="c3 c6 li-bullet-0"><span className="c0">protect Play Inc Publishing Ltd, our customers or the public from harm or illegal activities, or </span>
            </li>
            <li className="c3 c6 li-bullet-0"><span className="c0">respond to an emergency which we believe in good faith requires us to disclose information to assist in preventing the death or serious bodily injury of any person.</span>
            </li>
          </ul>
          <H2 color={'primary'} variant="h2">How we store and secure information we collect</H2>
          <Typography component="h3">Information storage and security</Typography>
          <p className="c3"><span className="c0">We use GDPR-compliant service providers for data hosting. This means that 1) they will only collect Personal Information when absolutely necessary to provide their service and 2) they are required to report any data breach within 72 hours. For example, we use data hosting service providers to handle our API interactions, to run our database that stores usage data, and for our machine learning architecture. The service providers we use that are located in the US are in compliance with the Privacy Shield certification.</span>
          </p>
          <p className="c3"><span className="c0">We use a variety of security technologies and procedures to help protect your Personal Information from unauthorized access, use or disclosure. We secure the Personal Information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use or disclosure. When sensitive Personal Information (such as geo-location data) is collected on our Website and/or transmitted to other websites, it is protected through the use of encryption, such as the Secure Socket Layer (SSL) protocol.</span>
          </p>
          <p className="c3"><span className="c0">While we implement safeguards designed to protect your information, no security system is impenetrable and due to the inherent nature of the Internet, we cannot guarantee that data, during transmission through the Internet or while stored on our systems or otherwise in our care, is absolutely safe from intrusion by others. If you have any questions about the security of your Personal Information, you can contact us at info@pixels.ai. We will respond to requests about this within a reasonable timeframe.</span>
          </p>
          <Typography component="h3">How long we keep information</Typography>
          <p className="c3"><span className="c0">How long we keep information we collect about you depends on the type of information, as described in further detail below. After such time, we will either delete or anonymize your information or, if this is not possible (for example, because the information has been stored in backup archives), then we will securely store your information and isolate it from any further use until deletion is possible.</span>
          </p>
          <ul className="c1 lst-kix_nq52bli9ccw4-0 start">
            <li className="c2 li-bullet-0"><span
              className="c4">Account information</span><span className="c0">: We retain your account information until you delete your account. We also retain some of your information as necessary to comply with our legal obligations, to resolve disputes, to enforce our agreements, to support business operations and to continue to develop and improve our Services. Where we retain information for Service improvement and development, we take steps to eliminate information that directly identifies you, and we only use the information to uncover collective insights about the use of our Services, not to specifically analyze personal characteristics about you.</span>
            </li>
            <li className="c2 li-bullet-0"><span
              className="c4">Managed accounts</span><span className="c0">: If the Services are made available to you through an organization (e.g., your employer), we retain your information as long as required by the administrator of your account.</span>
            </li>
            <li className="c2 li-bullet-0"><span
              className="c4">Content items and tracking data</span><span
              className="c0">: We keep the content items as long as you are a registered customer. If you cancel your account, those content items and everything you requested we scrape from your website will be deleted. We stop collecting tracking data on Visitors’ interactions with our recommendations as soon as you remove the Video Wrapper from your page and stop using any related plugins. Any tracking data obtained previously cannot be removed, as it has been processed by our algorithm.</span>
            </li>
            <li className="c2 li-bullet-0"><span
              className="c4">Marketing information</span><span
              className="c0">: If you have elected to receive marketing emails from us, we retain information about your marketing preferences unless you specifically ask us to delete such information. We retain information derived from cookies and other tracking technologies for a reasonable period of time from the date such information was created.</span>
            </li>
          </ul>
          <Typography component="h3">How to access and control your information</Typography>
          <p className="c3"><span className="c0">You have certain choices available to you when it comes to your information. Below is a summary of those choices, how to exercise them and any limitations. We will respond to requests about this within a reasonable timeframe.</span>
          </p>
          <Typography component="h3">Your Choices</Typography>
          <p className="c3"><span className="c0">You have the right to request a copy of your information, to object to our use of your information (including for marketing purposes), to request the deletion or restriction of your information, or to request your information in a structured, electronic format at any time. </span>
          </p>
          <p className="c3"><span className="c0">Below, we describe the tools and processes for making these requests. You can exercise some of the choices by logging into the Services and using settings available within the Services or your account. Where the Services are administered for you by an administrator, you may need to contact your administrator to assist with your requests first. For all other requests, you can contact us at info@pixels.ai.</span>
          </p>
          <ul className="c1 lst-kix_mzs4uoa3hm1w-0 start">
            <li className="c2 li-bullet-0"><span className="c4">Your request and choices may be limited in certain cases</span><span
              className="c0">: for example, if fulfilling your request would reveal information about another person, or if you ask to delete information which we or your administrator are permitted by law or have compelling legitimate interests to keep. Where you have asked us to share data with third parties, for example, in the case of an event, you will need to contact those third-party service providers directly to have your information deleted or otherwise restricted. If you have unresolved concerns, you may have the right to complain to a data protection authority in the country where you live, where you work or where you feel your rights were infringed.</span>
            </li>
            <li className="c2 li-bullet-0"><span
              className="c4">Access and update your information</span><span>: Our Services and related documentation give you the ability to access and update certain information about you from within the Service. For example, you can access and modify your company’s profile information by logging to your account at </span><span
              className="c9"><a className="c11"
                                href="https://www.google.com/url?q=https://www.pixels.ai/dashboard/account&amp;sa=D&amp;source=editors&amp;ust=1645863758594332&amp;usg=AOvVaw1Lzq-ILtfrNI3fySLNskNV">https://www.pixels.ai/dashboard/account</a></span><span
              className="c0">.</span></li>
            <li className="c2 li-bullet-0"><span className="c4">Deactivate membership/Remove Pixels AI from your page</span><span
              className="c0">: If you cancel your subscription, we will delete all content items you have provided us as well as all data from your website you may have asked us to scrape. If you remove the Video Wrapper from your site but do not cancel the subscription, all tracking data about your visitors’ interactions with the Video Wrapper will stop, but we will hold on to your content items. If you are having any issues removing our Video Wrapper or canceling your account please contact us at info@pixels.ai.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c4">Request that we stop using your information</span><span
              className="c0">: In some cases, you may ask us to stop accessing, storing, using and otherwise processing your information where you believe we don't have the appropriate rights to do so. For example, if you believe a Services account was created for you without your permission or you are no longer an active user, you can request that we delete your account as provided in this policy. Where you gave us consent to use your information for a limited purpose, you can contact us to withdraw that consent, but this will not affect any processing that has already taken place at the time. You can also opt-out of our use of your information for marketing purposes by contacting us at info@pixels.ai. When you make such requests, we may need time to investigate and facilitate your request. If there is a delay or dispute as to whether we have the right to continue using your information, we will restrict any further use of your information until the request is honored or the dispute is resolved, provided your administrator does not object (where applicable). If you object to information about you being shared with a third-party app, please disable the app or contact your administrator to do so.</span>
            </li>
            <li className="c2 li-bullet-0"><span
              className="c4">Opt out of communications</span><span
              className="c0">: You may opt out of receiving promotional communications from us by using the unsubscribe link within each email, updating your email preferences by clicking the link at the bottom of the email, removing Slack Connect or by contacting us to have your contact information removed from our promotional email list or registration database. If you are a Customer currently using our Services, you will continue to receive transactional messages and Slack messages from us regarding our Services even after you opt out from receiving promotional messages from us.</span>
            </li>
            <li className="c2 li-bullet-0"><span
              className="c4">Turn off Cookie Controls</span><span
              className="c0">: Relevant browser-based cookie controls are described in our Cookies &amp; Tracking Notice.</span>
            </li>
            <li className="c2 li-bullet-0"><span
              className="c4">Send "Do Not Track" Signals</span><span
              className="c0">: Some browsers have incorporated "Do Not Track" (DNT) features that can send a signal to the websites you visit indicating you do not wish to be tracked. Because there is not yet a common understanding of how to interpret the DNT signal, our Services do not currently respond to browser DNT signals. You can use the range of other tools we provide to control data collection and use, including the ability to opt out of receiving marketing from us as described above.</span>
            </li>
            <li className="c2 li-bullet-0"><span
              className="c4">Data portability</span><span className="c0">: Data portability is the ability to obtain some of your information in a format you can move from one service provider to another (for instance, when you transfer your mobile phone number to another carrier). Depending on the context, this applies to some of your information, but not to all of your information. Should you request it, we will provide you with an electronic file of your basic account information.</span>
            </li>
          </ul>

          <H2 color={'primary'} variant="h2">Cookies &amp; Tracking Notice</H2>
          <p className="c3"><span className="c4 c12">Effective Starting: 1st January 2022</span></p>
          <p className="c3">
            <span className="c0">&nbsp;</span></p>
          <Typography component="h3">Cookies and Other Tracking Technologies</Typography>
          <p className="c3"><span className="c0">Pixels AI and our third party partners, such as our advertising and analytics partners, use various technologies to collect information, such as cookies and web beacons.</span>
          </p>
          <p className="c3"><span className="c0">&nbsp;</span></p>
          <Typography component="h3">What types of technologies do we use?</Typography>
          <p className="c3"><span className="c0">We use cookies, web beacons and other technologies to improve and customize our products and websites ("the Services") and your experience; to allow you to access and use the Services without re-entering your username or password; to understand usage of our Services and the interests of our customers and to determine whether an email has been opened and acted upon.</span>
          </p>
          <p className="c3"><span className="c0">&nbsp;</span></p>
          <Typography component="h3">How do we use them?</Typography>
          <ul className="c1 lst-kix_j0aoalyfj9q5-0 start">
            <li className="c2 li-bullet-0"><span className="c0">Where strictly necessary. These cookies and other technologies are essential in order to enable the Services to provide the feature you have requested, such as remembering you have logged in. If you disable cookies, you will not be able to log into your Pixels AI account, but you may still browse the website.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c0">For functionality. We use these cookies to provide you with an experience more appropriate with your selections and to make your use of the Services more tailored.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c0">For performance and analytics. These cookies and similar technologies collect information on how users interact with the Services and enable us to improve how the Services operate. For example, we use Google Analytics cookies to help us understand how visitors arrive at and browse our products and website to identify areas for improvement such as navigation, user experience, and marketing campaigns.</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c0">Targeting Cookies or Advertising Cookies. These cookies collect information about your browsing habits in order to make advertising relevant to you and your interests. They remember the websites you have visited and that information is shared with other parties such as advertising technology service providers and advertisers.</span>
            </li>
          </ul>
          <Typography component="h3">How can you opt-out?</Typography>
          <p className="c3"><span className="c0">To opt-out of our use of cookies, you can instruct your browser, by changing its options, to stop accepting cookies or to prompt you before accepting a cookie from websites you visit. If you do not accept cookies, however, you may not be able to use all aspects of our Services. Pixels AI and our third party partners also collect information using web beacons (also known as "tracking pixels"). This enables us to understand how a user found our website and helps us gather demographic information about our user base as a whole.</span>
          </p>
          <p className="c3"><span className="c0">You will not be able to opt-out of any cookies or other technologies that are “strictly necessary” for the Services.</span>
          </p>
          <p className="c3"><span className="c0">&nbsp;</span></p>
          <Typography component="h3">Updates to this Notice</Typography>
          <p className="c3"><span className="c0">This Cookies &amp; Tracking Notice may be updated from time to time. If we make any changes, we will notify you by revising the "effective starting" date at the top of this notice. We are aware that the ePrivacy Regulation might affect the procedure around cookies. Once this comes into effect, we will update this Notice accordingly.</span>
          </p>
          <p className="c3"><span className="c0">If you have any questions or requests regarding your data, please contact us at info@pixels.ai.</span>
          </p>
          <Typography component="h3">Security queries</Typography>
          <p className="c3"><span className="c0">Should you have any security questions or concerns, or you wish to report a security flaw in Pixels AI's platform and services, please email security@pixels.ai. A member of our data team will get back to you as soon as is practicable.</span>
          </p>
        </Grid>
      </Box>
    </div>
  );
}

export default PrivacyPolicy;
