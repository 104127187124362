import { AxiosInstance } from "axios";
import { IArticleData } from "./articleInterface";

export interface BrandedContentClient {
  save: (data) => Promise<void>;
}

export const createBrandedContentClient = (
  getInstance: () => Promise<AxiosInstance>
): BrandedContentClient => ({
  save: async (data) => {
    const instance = await getInstance();
    await instance.patch<IArticleData>(
      "promoted-content", data
    );
  }
});

