import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const ContentWrap = styled(Box)`
  position: relative;
  margin: 0 auto;
  width: 100%;
`

export default ContentWrap;
