import * as React from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, LinearProgress } from '@mui/material';
import PaginationTable from '../Pagination';
import { useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import { apiPlaylistProvider, PlaylistState } from '../../store/playlistSlice';
import { PlaylistVideoTable } from './PlaylistVideoTable';
import { VideoSinglePreview } from '../modal/VideoSinglePreview';
import { getApiBaseUrl } from '../../utils/env';
import { AddVideoDialog } from '../modal/AddVideoDialog';
import { EditVideoDialog } from '../modal/EditVideoDialog';
import { OrganisationContext } from '../../App';
import { useParams } from 'react-router-dom';
import PlaylistProperties from './PlaylistProperties';
import { RootState } from '../../index';
import { PlaylistVideo } from '../types/playlist';
import { useLocation } from 'react-router';
import PlaylistRoot from './PlaylistRoot';

export default function PlaylistVideoList() {
  const { playlist } = useParams();
  const dispatch = useDispatch();
  const { selectedOrganisation } = useContext(OrganisationContext);
  const {
    formatFilter,
    videos,
    pagination,
    busy,
    currentPlaylist
  } = useSelector<RootState, PlaylistState>((state) => state.playlist);

  const { pathname } = useLocation();
  const [videoPreview, setVideoPreview] = React.useState<PlaylistVideo | null>();
  const handleClose = () => setVideoPreview(null);
  const [addVideoPreview, setAddVideoPreview] = React.useState<PlaylistVideo | { pos: number } | null>(null);
  const [editVideoPreview, setEditVideoPreview] = React.useState<PlaylistVideo | null>(null);
  const addHandleClose = () => setAddVideoPreview(null);
  const editHandleClose = () => setEditVideoPreview(null);

  const updateList = () => {
    dispatch<any>(
      apiPlaylistProvider({
        publisher: selectedOrganisation,
        limit: pagination.itemsPerPage,
        page: pagination.currentPage,
        selectedPlaylist: playlist,
        formatFilter,
      })
    );
  };

  const saveVideo = data => {
    addHandleClose();
    axios
      .post(`${getApiBaseUrl()}/playlist/${selectedOrganisation}/${playlist}`, {
        video_id: data.videoIdToAdd,
        pos: data.pos,
      })
      .then(response => {
        if (response.status === 201) {
          updateList();
        }
      })
      .catch(ex => {
        alert(ex);
      });
  };

  const editVideo = (data: { id: number; video_title: string }) => {
    editHandleClose();
    axios
      .put(`${getApiBaseUrl()}/playlist/${selectedOrganisation}/${playlist}/${data.id}`, {
        video_title: data.video_title,
      })
      .then(response => {
        if (response.status === 200) {
          updateList();
        }
      })
      .catch(ex => {
        alert(ex);
      });
  };

  const removeVideo = (id: number) => {
    if (!window.confirm('Are you sure you want to remove this playlist video?')) {
      return;
    }
    axios.delete(`${getApiBaseUrl()}/playlist/${selectedOrganisation}/${playlist}/${id}`).then(() => {
      updateList();
    });
  };

  useEffect(() => {
    if (!selectedOrganisation) return;
    dispatch<any>(
      apiPlaylistProvider({
        publisher: selectedOrganisation,
        limit: pagination.itemsPerPage,
        page: pagination.currentPage,
        selectedPlaylist: playlist,
        formatFilter,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.itemsPerPage, pagination.currentPage, formatFilter, playlist]);

  return videos ? (
    <PlaylistRoot>
      {videoPreview && (
        <VideoSinglePreview
          open={Boolean(videoPreview)}
          handleClose={handleClose}
          video={videoPreview}
          format={formatFilter}
          transcript={videoPreview?.transcript}
          showTranscriptPanel={true}
        />
      )}
      {addVideoPreview && (
        <AddVideoDialog
          onSave={saveVideo}
          open={Boolean(addVideoPreview)}
          handleClose={addHandleClose}
          video={addVideoPreview}
        />
      )}
      {editVideoPreview && (
        <EditVideoDialog
          onSave={editVideo}
          open={Boolean(editVideoPreview)}
          handleClose={editHandleClose}
          video={editVideoPreview}
        />
      )}

      {pathname === `/dashboard/playlist/${playlist}` && <>
        {videos && (
          <Box sx={{ position: 'relative' }}>
            {busy && <LinearProgress sx={{ position: 'absolute', top: 0, width: '100%' }}
                                     color="success"/>}
            <PlaylistVideoTable
              videos={videos}
              onSetVideoPreview={setVideoPreview}
              onSetAddVideoPreview={setAddVideoPreview}
              onSetEditVideoPreview={setEditVideoPreview}
              onRemoveVideo={removeVideo}
              format={currentPlaylist?.format as string}
            />
          </Box>
        )}
        {busy && <LinearProgress color="success"/>}
        <PaginationTable
          defaultSize={20}
          busy={busy}
          startPage={pagination.currentPage - 1}
          count={pagination.totalItems}
          onChange={page =>
            dispatch({
              type: 'SET_PAGINATION',
              payload: {
                currentPage: page + 1,
              },
            })
          }
          updateRowPerPage={totalPerPage => {
            dispatch({
              type: 'SET_PAGINATION',
              payload: {
                currentPage: 1,
                itemsPerPage: totalPerPage,
              },
            });
          }}
        />
      </>}
      {pathname === `/dashboard/playlist/properties/${playlist}` && <>
        <PlaylistProperties currentPlaylist={currentPlaylist} />
      </>}
    </PlaylistRoot>
  ) : (
    <CircularProgress style={{ padding: '5px' }}/>
  );
}
