import { createContext, useContext } from "react";
import { ApiClient } from "./rootClient";

const ApiClientContext = createContext<
  ApiClient | undefined
>(undefined);

const ApiClientProvider = ApiClientContext.Provider;

export const useApiClient = (): ApiClient => {
  const context = useContext(ApiClientContext);

  if (!context) {
    throw new Error("Cannot use ApiClientContext when undefined");
  }

  return context;
};

export default ApiClientProvider;
