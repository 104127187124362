import * as React from 'react';
import {
  Checkbox,
  FormControl,
  Grid, InputLabel, MenuItem, OutlinedInput, Select,
  TextField,
} from '@mui/material';
import { styled, Theme, useTheme } from '@mui/material/styles';
import { Heading } from './common/Heading';
import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { useParams } from 'react-router-dom';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      maxWidth: 250,
    },
  },
};

function getStyles(name: string, codes: string[], theme: Theme) {
  return {
    fontWeight:
      codes.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : 'bold',
  };
}

const Root = styled('div')`
  position: relative;
  .linear-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }
  .linear-top {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
  }
`;

export default function UserEdit() {
  const theme = useTheme();
  const { id } = useParams();
  const isAdmin = localStorage.getItem('is_admin') === 'true';
  if (!isAdmin || !id) {
    window.location.href = '/';
  }

  const [userId, setUserId] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [orgIds, setOrgIds] = useState<string[]>([]);
  const [organisations, setOrganisations] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name && email && password) {
      await axios.patch(`${getApiBaseUrl()}/users`, {
        name, email, password, org_ids: orgIds.join(','), id: userId
      })
      .then(() => {
        alert('User added successfully')
      })
      .catch((ex) => {
        console.error(ex);
        alert('Unable to add user.')
      });

    }
  };

  useEffect( () => {
    axios.get(`${getApiBaseUrl()}/organisation/list`).then(({data}) => setOrganisations(data));
  }, []);

  useEffect( () => {
    // make sure the list has loaded
    if (organisations.length === 0) return;

    axios.get(`${getApiBaseUrl()}/users/${id}`).then(({data}) => {
      setEmail(data.email);
      setName(data.name);
      setUserId(data.id);
      // filter out the selected id if it's not included on the list anymore
      setOrgIds(
        data.org_ids.split(',')
        .filter(
          id => organisations
            .map(({ id }) => String(id))
            .includes(id)
        )
      )
    });
  }, [id, organisations]);

  return (
    <React.Fragment>
      <Root>
        <Grid container style={{ paddingBottom: '15px', paddingLeft: '15px' }}>
          <Grid container alignItems="center" justifyContent={'space-between'}>
            <Grid item>
              <Heading>Edit user</Heading>
            </Grid>
          </Grid>
        </Grid>
      </Root>
      <Root>
        <form onSubmit={handleSubmit} style={{ width: 600 }} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  type={'text'}
                  autoComplete="off"
                  name="name"
                  value={name}
                  label="Full name"
                  variant="outlined"
                  placeholder={'eg. Full name'}
                  onChange={({ target: { value } }) => setName(value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  type={'email'}
                  autoComplete="off"
                  name="name"
                  value={email}
                  label="Email"
                  variant="outlined"
                  placeholder={'eg. Email address to login'}
                  onChange={({ target: { value } }) => setEmail(value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  type={'password'}
                  name="name"
                  value={password}
                  label="Password"
                  variant="outlined"
                  placeholder={'eg. Password for user'}
                  onChange={({ target: { value } }) => setPassword(value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="multiple-country-inclusion">Organisations</InputLabel>
                <Select
                  labelId="multiple-country-inclusion"
                  id="multiple-country-inclusion-selection"
                  multiple
                  value={orgIds}
                  onChange={(e: SelectChangeEvent<string[]>) => {
                    const value = e.target.value;
                    if (value[value.length - 1] === 'all') {
                      setOrgIds(
                        orgIds.length === organisations.length ? [] : organisations.map(({ id }) => id)
                      );
                      return;
                    }

                    setOrgIds(value as string[]);
                  }}
                  input={<OutlinedInput label="Name"/>}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value="all"
                  >
                    <Checkbox checked={organisations.length > 0 && orgIds.length === organisations.length} style={{ padding: 0 }}/>
                    Select all
                  </MenuItem>
                  {organisations.map(({ id, name }) => (
                    <MenuItem
                      key={id}
                      value={id}
                      style={getStyles(id, orgIds, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <Button href={'/users'} variant="outlined">Cancel</Button>
                </Grid>
                <Grid item>
                  <Button color="primary" variant="contained" type={'submit'}>Submit</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>

      </Root>
    </React.Fragment>
  );
}
