import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';

const fetchReportUrlSuccess = payload => ({
  type: 'FETCH_REPORT_URL_SUCCESS',
  payload,
});

const updateBusyIndicator = payload => ({
  type: 'FETCH_REPORT_URL_BUSY',
  payload,
});

export const apiReportUrlProvider =
  ({ publisher, params }) =>
  async dispatch => {
    dispatch(updateBusyIndicator(true));
    try {
      const { data } = await axios.get(`${getApiBaseUrl()}/${publisher}/metabase-report`, { params });
      dispatch(fetchReportUrlSuccess(data));
      dispatch(updateBusyIndicator(false));
    } catch (e) {
      dispatch(updateBusyIndicator(false));
      console.error(e);
    }
  };

export interface EngageState {
  url: string;
  playlistFilter: string;
  busy: boolean;
  formatFilter: string;
}

const initialState: EngageState = {
  url: '',
  playlistFilter: '',
  busy: false,
  formatFilter: 'vertical'
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_REPORT_URL_SUCCESS':
      let { url } = action.payload;
      return {
        ...state,
        url,
      };
    case 'FETCH_REPORT_URL_BUSY':
      return {
        ...state,
        busy: action.payload,
      };
    case 'SET_FILTER_FORMAT':
      return {
        ...state,
        formatFilter: action.payload,
      };
    case 'SET_FILTER_PLAYLISTS':
      return {
        ...state,
        playlistFilter: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
