import { useContext, useEffect, useRef, useState } from 'react';
import { getApiBaseUrl } from '../../utils/env';
import axios from 'axios';
import { Button, Grid, Typography } from '@mui/material';
import { OrganisationContext } from '../../App';

const TextArea = ({
  lang,
  transcript,
  handleTextChange,
  errored = false,
  saving = false,
  handleSave,
  buttonDisabled = true,
  readOnly = false,
}) => {
  return (
    <>
      <Grid item>
        <Typography>Transcript ({lang.toUpperCase()})</Typography>
      </Grid>
      <Grid item flexGrow={1}>
        <textarea
          style={{ padding: '1em', width: '100%', height: '100%' }}
          value={transcript}
          onChange={handleTextChange}
          disabled={readOnly || errored || saving}
        />
      </Grid>
      {!readOnly && (
        <Grid item>
          <Button variant="contained" onClick={handleSave} disabled={buttonDisabled || saving}>
            {saving ? 'Saving transcript changes...' : 'Save transcript changes'}
          </Button>
        </Grid>
      )}
    </>
  );
};

export const TranscriptPanel = ({ mediaId, rightsholder, setPlayerKey }) => {
  const { selectedOrganisation } = useContext(OrganisationContext);
  const [transcript, setTranscript] = useState('');
  const [lang, setLang] = useState('');
  const [loading, setLoading] = useState(true);
  const [errored, setErrored] = useState(false);
  const [saving, setSaving] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const originalTranscript = useRef('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setErrored(false);
      try {
        const { data } = await axios.get(`${getApiBaseUrl()}/${selectedOrganisation}/transcript-subtitles/${mediaId}`);
        originalTranscript.current = data.transcript;
        setTranscript(data.transcript);
        setLang(data.lang);
      } catch (error) {
        setErrored(true);
        setTranscript('Sorry, failed to retrieve the latest transcript data. Editing disabled.');
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaId, selectedOrganisation]);

  const handleTextChange = (event: { target: { value: string } }) => {
    const newTranscript = event.target.value;
    setTranscript(newTranscript);
    if (newTranscript !== originalTranscript.current) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const handleSave = async () => {
    setSaving(true);
    axios
      .put(`${getApiBaseUrl()}/${selectedOrganisation}/transcript-subtitles/${mediaId}`, {
        transcript: transcript,
        rightsHolder: rightsholder,
      })
      .then(response => {
        if (response.status === 200) {
          setPlayerKey((prevKey: number) => prevKey + 1);
          setSaving(false);
          setButtonDisabled(true);
          originalTranscript.current = transcript;
          alert('Transcript saved successfully');
        } else {
          throw new Error('Failed to save');
        }
      })
      .catch(ex => {
        console.error('Error saving data:', ex);
      });
  };

  return (
    <Grid container gap={1} style={{ height: '100%' }} direction={'column'} justifyContent={'space-between'}>
      {loading ? (
        <Grid item>
          <Typography>Retrieving latest transcript...</Typography>
        </Grid>
      ) : lang !== 'en' ? (
        <TextArea
          lang={lang}
          transcript={transcript}
          handleTextChange={handleTextChange}
          handleSave={handleSave}
          errored={errored}
          saving={saving}
          buttonDisabled={buttonDisabled}
        />
      ) : (
        <TextArea
          lang={lang}
          transcript={transcript}
          handleTextChange={handleTextChange}
          handleSave={handleSave}
          readOnly={true}
        />
      )}
    </Grid>
  );
};
