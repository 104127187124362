import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { Headline as BaseHeadline, TextHighlighted } from '../common/PublicTypography';

const MarketingImageGrid = styled(Grid)`
  background-color: #fff;
  padding: 10px 20px 20px;
  color: #112671;

  ${props => props.theme.breakpoints.up('md')} {
    padding: 30px 60px 60px;
  }
`;

const MarketingImageContainer = styled(Grid)`
  display: flex;
  flex-direction: column-reverse;
  background-color: #ebeffa;
  width: 100%;
  padding: 10px 20px 20px;

  ${props => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    padding: 50px 0 30px;
  }
`;

const MarketingImageTextsColumn = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;

  ${props => props.theme.breakpoints.up('md')} {
    padding: 0 60px;
  }
`;

const MarketingImageColumn = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TextBody = styled('p')`
  font-size: 20px;
  line-height: 1.485;
  margin-top: 0;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: 22px;
    line-height: 1.5;
    margin-bottom: 0;
  }
`;

const ImageContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;

  ${props => props.theme.breakpoints.up('md')} {
    padding: 0 60px;
  }
`;

const Image = styled('img')`
  width: 100%;
  height: 100%;
`;

const DesktopHeadline = styled(BaseHeadline)`
  display: none;

  ${props => props.theme.breakpoints.up('md')} {
    display: block;
    font-size: 42px;
    line-height: 1.1;
  }
`;

const MobileHeadline = styled(BaseHeadline)`
  display: none;
  ${props => props.theme.breakpoints.down('md')} {
    font-size: 28px;
    line-height: 1.2;
    display: block;
    margin-bottom: 30px;
    padding: 0 8px;
  }
`;

const Text = styled('span')`
  color: #fff;
`;

const MarketingImage = () => {
  return (
    <MarketingImageGrid container id="MarketingImage-Grid">
      <MarketingImageContainer id="MarketingImageContainer" container>
        <MarketingImageTextsColumn>
          <DesktopHeadline>
            Transform your website
            <br />
            with{' '}
            <TextHighlighted>
              <Text>Vertical Video</Text>
            </TextHighlighted>
          </DesktopHeadline>
          <TextBody>
            Automatically distribute video across your articles. Our in-article templates fit in existing ad slots, making
            it seamless to activate vertical video.
          </TextBody>
        </MarketingImageTextsColumn>
        <MarketingImageColumn>
          <MobileHeadline>
            Transform your website
            <br />
            with{' '}
            <TextHighlighted>
              <Text>Vertical Video</Text>
            </TextHighlighted>
          </MobileHeadline>
          <ImageContainer>
            <Image src={'/v3/watchshorts.png'} />
          </ImageContainer>
        </MarketingImageColumn>
      </MarketingImageContainer>
    </MarketingImageGrid>
  );
};

export default MarketingImage;
