export const localStorageToJSON = key => {
  const value = window?.localStorage?.getItem(key);
  try {
    return JSON.parse(value || '');
  } catch (ex) {
    return [];
  }
};

export const urlAppendQs = (originalURL: string, params: Record<string, any>) => {
  const url = new URL(originalURL);
  Object.entries(params).forEach(([k, v]) => url.searchParams.append(k, v));
  return url.toString();
};

export const capitalise = (str: string): string => {
  return str && str[0].toUpperCase() + str.slice(1);
};
