import { ButtonGroup, MenuItem, FormControl, Grid, Select, SelectChangeEvent, TextField, Button } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { PublicationSelector } from './PublicationSelector';
import { styled } from '@mui/material/styles';
import { Heading } from './common/Heading';
import { useDispatch } from 'react-redux';

function ToolBarVideoLibrary({ setSearchInputText, searchInputText }) {
  const dispatch = useDispatch();

  const handleChange = (event: SelectChangeEvent) => {
    dispatch({ type: 'SET_FILTER_VIDEO_LIBRARY', payload: event.target.value });
  };

  const changeData =
    key =>
    ({ target: { value } }) =>
      setSearchInputText({
        ...searchInputText,
        [key]: value,
      });

  const handleSubmit = e => {
    e.preventDefault();
    dispatch({ type: 'SET_VIDEO_LIBRARY_SEARCH_TEXT', payload: searchInputText.searchText });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Toolbar style={{ paddingBottom: '45px', paddingLeft: '15px' }}>
        <Grid container alignItems={'center'} justifyContent={'space-between'} gap={'8px'}>
          <Grid item>
            <Heading>Video Library</Heading>
          </Grid>

          <Grid item>
            <ButtonGroup>
              <FormControl
                variant="outlined"
                margin="dense"
                style={{
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              >
                <Select
                  id="demo-simple-select"
                  defaultValue={'vertical'}
                  onChange={handleChange}
                  sx={{
                    fontFamily: 'ProximaNovaBold',
                    textTransform: 'none',
                    color: '#000',
                    height: '43px',
                    width: '200px',
                    marginRight: '0',
                  }}
                >
                  <MenuItem value={'vertical'}>vertical only</MenuItem>
                  <MenuItem value={'horizontal'}>horizontal only</MenuItem>
                  <MenuItem value={'all'}>vertical & horizontal</MenuItem>
                </Select>
              </FormControl>
            </ButtonGroup>
          </Grid>

          <Grid item flex={1}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                type={'text'}
                value={searchInputText.searchText}
                label="Search text"
                variant="outlined"
                placeholder={'Search text'}
                onChange={changeData('searchText')}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <Button color="primary" variant="contained" type={'submit'}>
              Go
            </Button>
          </Grid>

          <Grid item>
            <PublicationSelector />
          </Grid>
        </Grid>
      </Toolbar>
    </form>
  );
}

export default styled(ToolBarVideoLibrary)`
  margin-bottom: 40px;
`;
