import MenuItem from './MenuItem';

import { ReactComponent as PerformanceIcon } from '../../icons/performance.svg';
import { ReactComponent as SearchIcon } from '../../icons/magnifyglass.svg';
import { ReactComponent as PlaylistIcon } from '../../icons/playlist.svg';

export default function Menu({ open }) {
  return (
    <>
      <MenuItem open={open} path="/dashboard" end label="Dashboard">
        <PerformanceIcon />
      </MenuItem>
      <MenuItem open={open} path={`/dashboard/playlist`} label="Playlist">
        <PlaylistIcon />
      </MenuItem>
      <MenuItem open={open} path="/dashboard/video-library" label="Video Library">
        <PlaylistIcon />
      </MenuItem>
      <MenuItem open={open} path="/dashboard/headline-search" label="Headline Search">
        <SearchIcon />
      </MenuItem>
    </>
  );
}

export function DefaultSection() {
  return '/dashboard';
}
