import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, LinearProgress } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  apiGetPlaylistCampaignDelivery,
  PlaylistState
} from '../../store/playlistSlice';
import { OrganisationContext } from '../../App';
import { useParams } from 'react-router-dom';
import { RootState } from '../../index';
import PlaylistRoot from './PlaylistRoot';
import { PlaylistCampaignDeliveryList } from './PlaylistCampaignDeliveryList';
import PaginationTable from '../Pagination';

export default function PlaylistCampaignDelivery() {
  const { playlist } = useParams();
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState<string>('1day');
  const { selectedOrganisation } = useContext(OrganisationContext);
  const {
    campaignDelivery,
    busy,
    pagination
  } = useSelector<RootState, PlaylistState>((state) => state.playlist);

  useEffect(() => {
    return () => {
      dispatch({ type: 'RESET_PLAYLIST_VIDEO' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedOrganisation) return;
    dispatch(apiGetPlaylistCampaignDelivery({
      publisher: selectedOrganisation,
      playlistId: playlist,
      dateRange,
      page: pagination.currentPage,
      limit: pagination.itemsPerPage
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.itemsPerPage, pagination.currentPage, dateRange]);


  return campaignDelivery ? (
    <PlaylistRoot>
      {campaignDelivery && (
        <Box sx={{ position: 'relative' }}>
          {busy && <LinearProgress sx={{ position: 'absolute', top: 0, width: '100%' }}
                                   color="success"/>}
          <PlaylistCampaignDeliveryList
            dateRange={dateRange}
            articles={campaignDelivery}
            onDateRangeChange={setDateRange}
          />
          <PaginationTable
            defaultSize={20}
            busy={busy}
            startPage={pagination.currentPage - 1}
            count={pagination.totalItems}
            onChange={page =>
              dispatch({
                type: 'SET_PAGINATION',
                payload: {
                  currentPage: page + 1,
                },
              })
            }
            updateRowPerPage={totalPerPage => {
              dispatch({
                type: 'SET_PAGINATION',
                payload: {
                  currentPage: 1,
                  itemsPerPage: totalPerPage,
                },
              });
            }}
          />
        </Box>
      )}
      {busy && <LinearProgress color="success"/>}

    </PlaylistRoot>
  ) : (
    <CircularProgress style={{ padding: '5px' }}/>
  );
}
