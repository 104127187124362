import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import ReactJWPlayer from 'react-jw-player';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export const JWPlayerPreview = ({ open, handleClose, videoUrl }) => (<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="jw-player-modal"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            JW Player
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <ReactJWPlayer
              playerId="jw-player"
              playerScript="https://content.jwplatform.com/libraries/rnDPXXLM.js"
              playlist={videoUrl}
            />
          </Typography>
        </Box>
      </Modal>)