import { styled } from '@mui/material/styles';
import logo from '../../../pixels-ai-logo.svg';
import { ListItemButton, ListItemText } from '@mui/material';
import logoWhite from '../../../pixels-ai-logo-white.svg';
import Box from '@mui/material/Box';

const Menu = styled('ul')`
  margin: 0;
  font-size: 14px;
  padding: 0;
`;

const MenuItem = styled('li')`
  list-style: none;
  margin-left: 40px;

  a {
    display: block;
    padding: 8px 6px;
    text-decoration: none;
    color: #21316C;
    &:visited, &:active, &:hover {
      color: #21316C;
    }
    ${props => props.theme.breakpoints.up('md')} {
      color: white;
      padding: 8px 12px;
      &:visited, &:active, &:hover {
        color: white;
      }
    }
  }
`;

const TopMenu = styled(Menu)`
  display: none;
  ${props => props.theme.breakpoints.up('md')} {
    display: flex;
  }
`

const Logo = styled('span')`
  background-image: url(${logo});
  width: 166px;
  height: 25px;
  display: inline-block;
  background-repeat: no-repeat;
  text-indent: -9999px;
`;

const LogoAnchor = styled('a')`
  display: flex;
  align-items: center;
`;

const LogoHeader = styled(Logo)`
    width: 106px;
    height: 17px;
    background-image: url("/v2/pixels-logo.svg");
    ${props => props.theme.breakpoints.up('md')} {
      width: 180px;
      height: 28px;
    }
`;

const DrawerText = styled(ListItemText)`
  font-size: 18px;
`

const DrawerRowAnchor = styled(Box)`
  position: relative;

  &.initial {
    &:before {
      content: ' ';
      position: absolute;
      width: calc(100% + 32px);
      height: 1px;
      left: -16px;
      top: 0;
      opacity: 0.1;
      background-color: #fff;
    }
  }
  &:after {
    content: ' ';
    position: absolute;
    width: calc(100% + 32px);
    height: 1px;
    left: -16px;
    bottom: 0;
    opacity: 0.1;
    background-color: #fff;
  }
`

const ListMobileAnchor = ({ children, ...rest }) => (<ListItemButton component={'a'} {...rest}>{children}</ListItemButton>)

const LogoDrawer = styled('span')`
  width: 145px;
  height: 22px;
  background-image: url(${logoWhite});
  display: inline-block;
  background-repeat: no-repeat;
  text-indent: -9999px;
`

export {
  Logo, LogoHeader, LogoAnchor, Menu, MenuItem,
  TopMenu, DrawerText, DrawerRowAnchor, LogoDrawer,
  ListMobileAnchor
}