import * as React from 'react';
import {
  Grid, Paper, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import { Heading } from './common/Heading';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';

const Root = styled('div')`
  position: relative;
  .linear-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }
  .linear-top {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
  }
`;

export default function Publishers() {
  const [orgs, setOrgs] = useState<{id: number, name: string, domain: string}[]>([]);

  const isAdmin = localStorage.getItem('is_admin') === 'true';
  if (!isAdmin) {
    window.location.href = '/';
  }

  useEffect(() => {
    axios.get(
      `${getApiBaseUrl()}/organisation/list?isDemo=true`,
    ).then(({ data: list }) => {
      if (list) {
        setOrgs(list);
      }
    }).catch(ex => {
      if (ex.message) {
        alert(ex.message);
      }
      console.error(ex);
    });
  }, [])

  return (
    <React.Fragment>
      <Root>
        <Grid container style={{ paddingBottom: '15px', paddingLeft: '15px' }}>
          <Grid container alignItems="center" justifyContent={'space-between'}>
            <Grid item>
              <Heading>Demo Publishers</Heading>
            </Grid>
          </Grid>
        </Grid>
      </Root>
      <Root>
        <TableContainer component={Paper} sx={{ maxWidth: 800 }} >
          <Grid container spacing={2} justifyContent={'flex-end'} sx={{ marginBottom: 2 }}>
            <Grid item>
              <Button href="/publishers/add" variant={'contained'}>Add publisher</Button>
            </Grid>
          </Grid>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orgs.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <NavLink to={`/publishers/${row.domain}/articles`}>
                      {row.name}
                    </NavLink>
                  </TableCell>
                  <TableCell align="right">
                    <NavLink to={`/publishers/edit/${row.domain}`}>
                      <SvgIcon>
                        <EditIcon />
                      </SvgIcon>
                    </NavLink>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Root>
    </React.Fragment>
  );
};
